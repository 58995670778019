import React, {useState, useEffect} from 'react';
import AppMenu from '../components/AppMenu';

const Footer = () => {
  return (
    <>
      <div className="bottom-navbar fixed-bottom">

        <AppMenu/>

      </div>
    </>
  );
};

export default Footer;
