import React, {useEffect, useState} from "react";
import {Navigate, Outlet} from "react-router-dom";
import {encryptData, decryptData} from "../utils/crypto";

import axios from "axios";
import {API_ENDPOINT, AUTH_USER, SUCCESS} from "../constant/";

const PrivateRoute = () => {
    const jwt_token = localStorage.getItem("token");
    const deviceType = localStorage.getItem('deviceType');
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    useEffect(() => {
        let domain = window.location.hostname;
        let data = encryptData({
            "parentUsername": process.env.REACT_APP_USERNAME,
            "checkUsername": process.env.REACT_APP_CHECK_USERNAME,
            "domain": domain,
        });
        data = JSON.stringify({data: data});
        axios
            .post(API_ENDPOINT + AUTH_USER, data, {
                headers: {
                    'Content-Type': 'application/json',
                    "deviceType": deviceType,
                    Authorization: jwt_token,
                },
            })
            .then((response) => {
                if (response.data.statusCode == SUCCESS) {
                    const rdata = decryptData(response.data.data)
                    localStorage.setItem(
                        "authenticated",
                        JSON.stringify(rdata)
                    );
                } else {
                    setIsLoggedIn(false);
                }
            })
            .catch((error) => {
                setIsLoggedIn(false);
                console.error("Error fetching data:", error);
            });
    }, []);
    return isLoggedIn ? (
        <Outlet/>
    ) : (
        // <Navigate exact to={`${process.env.PUBLIC_URL}/login`}/>
        <Navigate exact to={`login`}/>
    );
    /*
    return login || authenticated || jwt_token ? <Outlet /> : <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />;
    */
};

export default PrivateRoute;
