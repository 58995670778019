import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_SECRET_KEY; // Fetch from .env

// Encrypt Data
export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

// Decrypt Data
export const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
export const encryptUrlData = (data) => {
  return CryptoJS.AES.encrypt(data, secretKey).toString();
};

// Decrypt Data
export const decryptUrlData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedData), secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

