import React, {useState} from 'react';
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import {Row, Col, Card, Form, Button} from 'react-bootstrap';
import {useMediaQuery} from "react-responsive";
import {Link, useNavigate} from 'react-router-dom';

const TermsAndConditions = () => {
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    const navigate = useNavigate();
    const clickToBackReturn = () => {
        navigate('/profile', {replace: true}); // Navigate to the new page
    };
    return (
        <>
            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">

                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn" onClick={clickToBackReturn}>
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>

                    <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                        <h5 className="head-numbers mb-0">Rules and Regulations</h5>
                    </div>

                </div>
            </div>


            {isDesktop &&
                <LeftSidebar/>
            }
            <div className="main-container trans">
                <div className="center-main-content">
                    <div className="center-container trans">
                        <div className="home-container">

                            <div className="terms-page">
                                <div className='terms-block'>

                                    <div className="terms-items">
                                        <span className='list-number mr-2'>1.</span>
                                        MARKET TRADE TIMING OF NSE WILL BE FROM 09.17 AM & MCX FROM 09.02 AM.
                                    </div>

                                    <div className="terms-items">
                                        <span className='list-number mr-2'>2.</span>
                                        PENDING/LIMIT WILL BE PASSED BY LTP PRICE.
                                    </div>

                                    <div className="terms-items">
                                        <span className='list-number mr-2'>3.</span>
                                        THIS APPLICATION IS USED FOR EXCHANGING VIEWS ON MARKETS FOR INDIVIDUAL STUDENT
                                        FOR TRAINING PURPOSE ONLY.
                                    </div>

                                    <div className="terms-items">
                                        <span className='list-number mr-2'>4.</span>
                                        NO REAL MONEY INVOLVED.THIS IS VIRTUAL TRADING APPLICATION WHICH HAS ALL THE
                                        FEATURES TO TRADE.
                                    </div>
                                    <div className="terms-items">
                                        <span className='list-number mr-2'>5.</span>
                                        DEPOSIT AND WITHDRAW TIMEIND FOR ALL SEGMENTS IS 8.45 AM TO 11.45 PM SUNDAY
                                        CLOSE
                                    </div>

                                    <div className="terms-items">
                                        <span className='list-number mr-2'>6.</span>
                                        MARAKET TRADE TIMING OF GIFT NIFTY WILL BE 06:32 AM TO 2:45 AM ,US ININDEX 3.32
                                        AM TO 2:30 AM ,US STOCKS 7:02 PM TO 2:30 AM ,COMEX , CRYPOT , CURRENCY TIMING
                                        3.32 AM TO 2.30 AM
                                    </div>

                                    <div className="terms-items">
                                        <span className='list-number mr-2'>7.</span>
                                        DEPOSIT AND WITHDRAW TIMING FOR ALL SEGMENTS ARE MONDAY TO SATURDAY 08:40 AM TO
                                        11:40 PM
                                    </div>

                                    <div className="terms-items">
                                        <span className='list-number mr-2'>8.</span>
                                        ❗️❗️Time Limit Notice❗️❗️ Time limit for holding profit in FOREX , CRYPTO ,
                                        US.STOCKS IS 15 mins if any trade is cut before 15mins, the trade will be
                                        deleted as well as the profit gained in it⚠️
                                    </div>

                                    <div className="terms-items">
                                        <span className='list-number mr-2'>9.</span>
                                        BONUS , DIVIDEND , SPLIT AGAR KOI BHI SCRIPT ME HOGA TO AGLE DIN KE LAST
                                        QUOTATION SE CONTRACT SQUARE OFF HO JAYEGA.
                                    </div>

                                    <div className="terms-items">
                                        <span className='list-number mr-2'>10.</span>
                                        PLEASE DEPOSIT IN THE BANK MENTION ON APPLICATION OR CALL HELPLINE FOR DEPOSIT .
                                    </div>

                                    <div className="terms-items">
                                        <span className='list-number mr-2'>11.</span>
                                        TICK (CF) TO CARRY FORWARD YOUR POSITION TO THE NEXT DAY : OTHERWISE ,IT WILL
                                        SQUARED OFF AT THE QUOTATION PRICE . CF TIMING FOR NSE IS 3:15 PM AND FOR MCX IS
                                        11:15 PM
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {isMobile &&
                <Footer/>
            }
        </>
    );
};

export default TermsAndConditions;