import React, {useEffect, useState} from 'react';
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import {Row, Col, Card, Form, Button} from 'react-bootstrap';
import {useMediaQuery} from "react-responsive";
import {useLocation, useNavigate} from 'react-router-dom';
import {toast, Bounce} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
    API_ENDPOINT,
    DELETE_USER_TAB_SYMBOL,
    GET_USER_TAB_WISE_SYMBOLS_LIST,
    SUCCESS,
    SYMBOL_SEARCH_LIST,
    POST_USER_TAB_SYMBOL_CHANGE_SEQUENCE
} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import {toastSuccess, toastError} from "../utils/toastMsg";

const ReArrangeScriptsOld = () => {

    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    const [tabWiseSymbolData, setTabWiseSymbolData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const passedData = location.state; // Get the passed data

    const clickToBackReturn = () => {
        const valueToSend = {activeTabId: passedData.activeTabId}; // Data to pass
        navigate('/dashboard', {replace: true, state: valueToSend}); // Navigate to the new page
        // window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
    };
    const handleGetTabWiseSymbolList = async (userTabId) => {
        let data = encryptData({
            "userTabId": userTabId
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + GET_USER_TAB_WISE_SYMBOLS_LIST, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        setTabWiseSymbolData(resData)
                    } else {
                        // toastError(response.data.message)
                        console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const handleDragStart = (e, index) => {
        e.dataTransfer.setData("dragIndex", index);
    };

    const handleDrop = (e, dropIndex) => {
        const dragIndex = e.dataTransfer.getData("dragIndex");
        const updatedRows = Array.from(tabWiseSymbolData);
        const [movedRow] = updatedRows.splice(dragIndex, 1);
        updatedRows.splice(dropIndex, 0, movedRow);
        setTabWiseSymbolData(updatedRows);
        /*const dragIndex = e.dataTransfer.getData("dragIndex");
        const updatedRows = Array.from(rows);
        const [movedRow] = updatedRows.splice(dragIndex, 1);
        updatedRows.splice(dropIndex, 0, movedRow);
        setRows(updatedRows);*/
    };
    const handlePostUserTabSymbol = async () => {
        let tabSymbolData = [];
        await tabWiseSymbolData.map((item, index) => {
            tabSymbolData.push({
                'userTabSymbolId': item.userTabSymbolId,
                'symbolTitle': item.symbolTitle,
                'sequence': index + 1
            })
        });
        let data = encryptData({
            "userTabId": passedData.activeTabId,
            "tabSymbolData": tabSymbolData
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + POST_USER_TAB_SYMBOL_CHANGE_SEQUENCE, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        toastSuccess(response.data.meta.message)
                    } else {
                        toastError(response.data.message)
                        console.error("Loginsdfvdvdcv error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const handleDeleteUserTabSymbol = async (symbolId) => {
        let data = encryptData({
            "userTabId": passedData.activeTabId,
            "symbolId": symbolId
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + DELETE_USER_TAB_SYMBOL, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        handleGetTabWiseSymbolList(passedData.activeTabId)
                    } else {
                        // toastError(response.data.message)
                        console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    useEffect(() => {
        if (passedData?.activeTabId) {
            handleGetTabWiseSymbolList(passedData.activeTabId)
        } else {
            navigate('/dashboard'); // Navigate to the new page
        }
    }, []);
    return (
        <>
            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">
                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn" onClick={clickToBackReturn}>
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>
                    <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                        <h5 className="head-numbers mb-0">ReArrenge Scripts</h5>
                    </div>
                    <div className="page-head-flt d-flex v-center">
                        <button className="btn white-bdr small" onClick={(e) => handlePostUserTabSymbol(e)}>Save</button>
                    </div>
                </div>
            </div>
            {isDesktop &&
                <LeftSidebar/>
            }
            <div className="main-container trans">
                <div className="center-main-content">
                    <div className="center-container trans">
                        <div className="home-container">
                            <div className="rearrenge-scripts-page">
                                <div className='rearrenge-scripts-block'>
                                    <table className='rearrenge-table w-100'>
                                        <tbody>
                                        {tabWiseSymbolData.map((row, index) => (
                                            <tr
                                                key={row.userTabSymbolId}
                                                draggable
                                                onDragStart={(e) => handleDragStart(e, index)}
                                                onDragOver={(e) => e.preventDefault()}
                                                onDrop={(e) => handleDrop(e, index)}
                                            >
                                                <td>
                                                    <div className="rearrenge-items d-flex v-center">
                                                        <p>{row.symbolTitle}</p>
                                                        <div class="delete_icon text-right ml-auto"><h4
                                                            onClick={() => handleDeleteUserTabSymbol(row.symbolId)}
                                                            class="mb-0 text-danger"><i class="fa fa-minus-circle"></i>
                                                        </h4></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isMobile &&
                <Footer/>
            }
        </>
    );
};

export default ReArrangeScriptsOld;