import React, {useState, useEffect} from 'react';
import {Container, Tab, Tabs, Row, Col, Card, Form} from 'react-bootstrap';
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import {useMediaQuery} from "react-responsive";
import {formatSymbolExpiryDate, formatDateForTradeList} from "../utils/dateUtils";
import {toastSuccess, toastError} from "../utils/toastMsg";
import {Link, useNavigate} from 'react-router-dom';
import {
    API_ENDPOINT,
    TRADE_LIST,
    SUCCESS, BILL_GENERATE
} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import axios from "axios";
import {saveAs} from 'file-saver';

const DownloadBill = () => {
    const navigate = useNavigate();
    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    const [dateData, setDateData] = useState({
        'currentWeekStartDate': '',
        'currentWeekEndDate': '',
        'previousWeekStartDate': '',
        'previousWeekEndDate': '',
        'thirdWeekStartDate': '',
        'thirdWeekEndDate': '',
        'fourthWeekStartDate': '',
        'fourthWeekEndDate': '',
    });

    const handleBillDownload = async (startDate, endDate) => {
        try {
            let data = encryptData({
                "userId": authenticated.userId,
                "startDate": startDate,
                "endDate": endDate
            });
            data = JSON.stringify({data: data});
            axios
                .post(API_ENDPOINT + BILL_GENERATE, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                    responseType: 'blob', // Receive binary data (PDF file)
                })
                .then((response) => {
                    console.log(response.headers.get('Content-Disposition'))
                    console.log(response.headers)
                    saveAs(response.data, response.headers.get('Content-Disposition'));
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                    // console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const clickToBackReturn = () => {
        navigate('/profile', { replace: true }); // Navigate to the new page
    };
    useEffect(() => {
        const today = new Date();
        const currentWeekStartDate = new Date(today.setDate(today.getDate() - today.getDay() + 1)); // Monday
        const currentWeekEndDate = new Date(today.setDate(today.getDate() + 6)); // Sunday

        const todayn = new Date();
        const firstDayOfWeek = new Date(todayn.setDate(todayn.getDate() - todayn.getDay() + 1)); // Monday
        const previousWeekStart = new Date(firstDayOfWeek.setDate(firstDayOfWeek.getDate() - 7)); //previous week
        const previousWeekEnd = new Date(firstDayOfWeek.setDate(firstDayOfWeek.getDate() + 6)); // previous week

        const thirdLastWeekStart = new Date(firstDayOfWeek.setDate(firstDayOfWeek.getDate() - 13)); // 3 weeks back
        const thirdLastWeekEnd = new Date(firstDayOfWeek.setDate(firstDayOfWeek.getDate() + 6)); // Add 6 days for the end date

        const fourthLastWeekStart = new Date(firstDayOfWeek.setDate(firstDayOfWeek.getDate() - 14)); // 3 weeks back
        const fourthLastWeekEnd = new Date(firstDayOfWeek.setDate(firstDayOfWeek.getDate() + 7)); // Add 6 days for the end date

        setDateData({
            ...dateData,
            ['currentWeekStartDate']: currentWeekStartDate,
            ['currentWeekEndDate']: currentWeekEndDate,
            ['previousWeekStartDate']: previousWeekStart,
            ['previousWeekEndDate']: previousWeekEnd,
            ['thirdWeekStartDate']: thirdLastWeekStart,
            ['thirdWeekEndDate']: thirdLastWeekEnd,
            ['fourthWeekStartDate']: fourthLastWeekStart,
            ['fourthWeekEndDate']: fourthLastWeekEnd,
        });
    }, []);
    return (
        <>

            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">

                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn" onClick={clickToBackReturn}>
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>

                    <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                        <h5 className="head-numbers mb-0">Download Bill</h5>
                    </div>

                </div>
            </div>

            {isDesktop &&
                <LeftSidebar/>
            }

            <div className="main-container trans">
                <div className="center-main-content">
                    <div className="center-container trans">
                        <div className="home-container">

                            <div className="account-page">
                                <div className='dwn-list'>

                                    <div className="dwn-items notify-items">
                                        <Row
                                            onClick={() => handleBillDownload(dateData.currentWeekStartDate, dateData.currentWeekEndDate)}>
                                            <Col xs={7} md={9} lg={9} className='l-block'>
                                                <strong className="notify-text">CURRENT WEEK</strong>
                                            </Col>
                                            <Col xs={5} md={3} lg={3} className='r-block text-right'>
                                                <strong
                                                    className="notify-text ml-auto">{formatSymbolExpiryDate(dateData.currentWeekStartDate)} TO {formatSymbolExpiryDate(dateData.currentWeekEndDate)}</strong>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="dwn-items notify-items">
                                        <Row
                                            onClick={() => handleBillDownload(dateData.previousWeekStartDate, dateData.previousWeekEndDate)}>
                                            <Col xs={7} md={9} lg={9} className='l-block'>
                                                <strong className="notify-text">LAST WEEK</strong>
                                            </Col>
                                            <Col xs={5} md={3} lg={3} className='r-block text-right'>
                                                <strong
                                                    className="notify-text ml-auto">{formatSymbolExpiryDate(dateData.previousWeekStartDate)} TO {formatSymbolExpiryDate(dateData.previousWeekEndDate)}</strong>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="dwn-items notify-items">
                                        <Row
                                            onClick={() => handleBillDownload(dateData.thirdWeekStartDate, dateData.thirdWeekEndDate)}>
                                            <Col xs={7} md={9} lg={9} className='l-block'>
                                                <strong className="notify-text">WEEK 3</strong>
                                            </Col>
                                            <Col xs={5} md={3} lg={3} className='r-block text-right'>
                                                <strong
                                                    className="notify-text ml-auto">{formatSymbolExpiryDate(dateData.thirdWeekStartDate)} TO {formatSymbolExpiryDate(dateData.thirdWeekEndDate)}</strong>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="dwn-items notify-items">
                                        <Row
                                            onClick={() => handleBillDownload(dateData.fourthWeekStartDate, dateData.fourthWeekEndDate)}>
                                            <Col xs={7} md={9} lg={9} className='l-block'>
                                                <strong className="notify-text">WEEK 4</strong>
                                            </Col>
                                            <Col xs={5} md={3} lg={3} className='r-block text-right'>
                                                <strong
                                                    className="notify-text ml-auto">{formatSymbolExpiryDate(dateData.fourthWeekStartDate)} TO {formatSymbolExpiryDate(dateData.fourthWeekEndDate)}</strong>
                                            </Col>
                                        </Row>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {isMobile &&
                <Footer/>
            }
        </>
    );
};

export default DownloadBill;