import React, {useContext, useState, useEffect} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import {Container, Tab, Tabs, Row, Col, Card, Form} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import {
    API_ENDPOINT,
    TRADE_LIST,
    SUCCESS,
    PENDING,
    BUY,
    EXECUTED,
    CANCELLED,
    INTRADAY,
    TRADE_UPDATE, MCX, SELL, TRADE_CANCEL, STOPLOSS, LIMIT
} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import {useMediaQuery} from "react-responsive";
import {Link, useNavigate} from 'react-router-dom';
import axios from "axios";
import {formatSymbolExpiryDate, formatDateForTradeList} from "../utils/dateUtils";
import {WebSocketContext} from '../services/WebSocketContext';
import webSocketManager from '../services/WebSocketManager';
import {useSelector, useDispatch} from "react-redux";
import {addItem, removeItem} from "../services/arraySlice";
import {v4 as uuidv4} from 'uuid';
// import {initiateSocketConnection, disconnectSocket, getSocket} from "../services/socket";
import {useSocket} from "../services/SocketContext";
import {toastSuccess, toastError} from "../utils/toastMsg";

const SLTP = () => {
    const {tickData} = useContext(WebSocketContext);
    const {channelData, socket, disconnectSocket} = useSocket();
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    const [activePopup, setActivePopup] = useState(null);
    const [activeOrderData, setActiveOrderData] = useState({});
    const navigate = useNavigate();

    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    // const [allTData, setAllTData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [executedData, setExecutedData] = useState([]);
    const [pendingData, setPendingData] = useState([]);
    const [rejectedData, setRejectedData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = -1; // Number of items to display per page
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const array = useSelector((state) => state.array);
    const localArray = [...array]; // Make a local copy of the current state.
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        'quantity': 0,
        'totalQuantity': 0
    });
    const fetchDataFromAPI = async (startDate, endDate) => {
        try {
            let data = encryptData({
                "search": "",
                "userId": '',
                "symbolId": '',
                "exchangeId": '',
                "setFt": 1,
                "status": PENDING,
                "startDate": '',
                "endDate": '',
                "page": currentPage,
                "limit": itemsPerPage,
            });
            data = JSON.stringify({data: data});
            axios
                .post(API_ENDPOINT + TRADE_LIST, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const rdata = decryptData(response.data.data)
                        setTableData(rdata);
                        let exiT = [];
                        let rejectT = [];
                        let pendiT = [];
                        rdata.map(item => {
                            if (item.status === EXECUTED) {
                                exiT.push(item)
                            }
                            if (item.status === CANCELLED) {
                                rejectT.push(item)
                            }
                            if (item.status === PENDING) {
                                pendiT.push(item)
                            }
                        });
                        socketData(pendiT)
                        setExecutedData(exiT);
                        setRejectedData(rejectT);
                        setPendingData(pendiT);
                    } else {
                        toastError(response.data.message)
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchDataFromAPI();
    }, []);
    useEffect(() => {
        if (channelData) {
            if (channelData?.trade) {
                if (channelData.trade.status === PENDING || channelData.trade.status === EXECUTED) {
                    const idExists = tableData.some(item => item.tradeId === channelData.trade.tradeId);
                    if (!idExists) {
                        let updatedDatas = [channelData.trade, ...tableData];
                        setTableData(updatedDatas); // Use spread operator to prepend new channelData
                        setUpdateData(updatedDatas)
                    } else {
                        const newData = tableData.map((item) => {
                            return item.tradeId === channelData.trade.tradeId ? channelData.trade : item;
                        });
                        setTableData(newData);
                        /*let channelDatasssk = setTableData((prevDataArray) =>
                            prevDataArray.map((item) =>
                                item.tradeId === channelData.trade.tradeId ? channelData.trade : item
                            )
                        );*/
                        setUpdateData(newData)
                    }
                }
            }
            if (channelData?.userData) {
                localStorage.setItem(
                    "authenticated",
                    JSON.stringify(channelData.userData)
                );
            }
        }
    }, [channelData]);
    const addSymbolNameInArray = async (symbolName) => {
        if (!localArray.includes(symbolName)) {
            await dispatch(addItem(symbolName));
            localArray.push(symbolName); // Update the local copy manually.
        }
    };
    const socketData = async (symbolData) => {
        // await addSymbolNameInArray(symbolData.symbolName)
        for (var i = 0; i < symbolData.length; i++) {
            await addSymbolNameInArray(symbolData[i].symbolName)
        }
        webSocketManager.send(localArray);
    };
    const handleActivePe = (data) => {
        if (data.status === PENDING) {
            setActivePopup(true)
            setActiveOrderData(data)
            setFormData({
                ...formData,
                ['quantity']: data.quantity,
                ['totalQuantity']: data.isSymbolWise ? data.quantity : data.totalQuantity,
                ['price']: data.price,
            });
        }
    };
    const handleTrade = async () => {
        try {
            if (!formData.totalQuantity || formData.totalQuantity == 0) {
                toastError("Please fill quantity")
                return;
            }
            if (!formData.price || formData.price == 0) {
                toastError("Please fill price")
                return;
            }
            let fData = {
                quantity: 0,
                totalQuantity: 0,
                price: 0,
                referencePrice: 0,
            }
            if(activeOrderData.orderType === LIMIT) {
                if (activeOrderData.tradeType === SELL) {
                    if (activeOrderData.bid > formData.price) {
                        toastError('TRIGGER PRICE SHOULD BE GREATER THAN MARKET PRICE')
                        return;
                    }
                    fData.referencePrice = activeOrderData.bid;
                }
                if (activeOrderData.tradeType === BUY) {
                    if (activeOrderData.ask < formData.price) {
                        toastError('TRIGGER PRICE SHOULD BE LESS THAN MARKET PRICE')
                        return;
                    }
                    fData.referencePrice = activeOrderData.ask;
                }
            }
            if(activeOrderData.orderType === STOPLOSS) {
                if (activeOrderData.tradeType === SELL) {
                    if (activeOrderData.bid < formData.price) {
                        toastError('TRIGGER PRICE SHOULD BE LESS THAN MARKET PRICE')
                        return;
                    }
                    fData.referencePrice = activeOrderData.bid;
                }
                if (activeOrderData.tradeType === BUY) {
                    if (activeOrderData.ask > formData.price) {
                        toastError('TRIGGER PRICE SHOULD BE GREATER THAN MARKET PRICE')
                        return;
                    }
                    fData.referencePrice = activeOrderData.ask;
                }
            }
            fData.price = formData.price;

            if (activeOrderData.isSymbolWise) {
                fData.totalQuantity = formData.totalQuantity * activeOrderData.lotSize;
                fData.quantity = fData.totalQuantity / activeOrderData.lotSize;
            } else {
                fData.quantity = formData.totalQuantity / activeOrderData.lotSize;
                fData.totalQuantity = formData.totalQuantity;
            }
            if (activeOrderData.oddLotTrade != 1) {
                if (!Number.isInteger(fData.quantity)) {
                    toastError("Invalid Qty")
                    return;
                }
            }
            const {deviceType, browser, userAgent, newDeviceId, ip} = await getDeviceInfo();
            let data = encryptData({
                "tradeId": activeOrderData.tradeId,
                "userId": authenticated.userId,
                "parentId": authenticated.parentId,
                "symbolId": activeOrderData.symbolId,
                "quantity": parseFloat(fData.quantity),
                "totalQuantity": parseFloat(fData.totalQuantity),
                "price": parseFloat(fData.price),
                "referencePrice": parseFloat(fData.referencePrice),
                "lotSize": parseFloat(activeOrderData.lotSize),
                "tradeType": activeOrderData.tradeType,
                "exchangeId": activeOrderData.exchangeId,
                "orderType": activeOrderData.orderType,
                "productType": INTRADAY,
                "ipAddress": ip,
                "deviceId": newDeviceId,
                "userAgent": userAgent,
                "browser": browser,
                "deviceType": deviceType,
            });
            data = JSON.stringify({data: data});
            axios
                .post(API_ENDPOINT + TRADE_UPDATE, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        fetchDataFromAPI()
                        toastSuccess(response.data.meta.message)
                        setActivePopup(null)
                        setFormData({
                            ...formData,
                            ['totalQuantity']: 0,
                            ['quantity']: 0,
                            ['price']: 0
                        });
                    } else {
                        toastError(response.data.message)
                        // console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                    // console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const handleTradeCancel = async () => {
        try {
            let data = encryptData({
                "tradeId": activeOrderData.tradeId
            });
            data = JSON.stringify({data: data});
            axios
                .post(API_ENDPOINT + TRADE_CANCEL, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        fetchDataFromAPI()
                        toastSuccess(response.data.meta.message)
                        setActivePopup(null)
                        setFormData({
                            ...formData,
                            ['totalQuantity']: 0,
                            ['quantity']: 0,
                            ['price']: 0
                        });
                    } else {
                        toastError(response.data.message)
                        // console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                    // console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const getDeviceInfo = async () => {
        const userAgent = navigator.userAgent;
        let newDeviceType = "desktop";
        let browser = "Unknown";

        // Check if the device is mobile
        if (/Mobi|Android/i.test(userAgent)) {
            newDeviceType = "mobile";
        }

        // Browser detection (simplified)
        if (userAgent.includes("Chrome")) {
            browser = "Chrome";
        } else if (userAgent.includes("Firefox")) {
            browser = "Firefox";
        } else if (userAgent.includes("Safari")) {
            browser = "Safari";
        } else if (userAgent.includes("Edge")) {
            browser = "Edge";
        }
        let newDeviceId = localStorage.getItem('deviceId');
        if (!newDeviceId) {
            newDeviceId = uuidv4();
            localStorage.setItem('deviceId', newDeviceId);
        }
        let deviceType = localStorage.getItem('deviceType');
        if (!deviceType) {
            deviceType = newDeviceType;
            localStorage.setItem('deviceType', deviceType);
        }
        let ip = '';
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            ip = response.data.ip;
            return {deviceType, browser, userAgent, newDeviceId, ip};
        } catch (error) {
            return {deviceType, browser, userAgent, newDeviceId, ip};
        }
    };
    const setUpdateData = (newData) => {
        let exiT = [];
        let rejectT = [];
        let pendiT = [];
        newData.map(item => {
            if (item.status === EXECUTED) {
                exiT.push(item)
            }
            if (item.status === CANCELLED) {
                rejectT.push(item)
            }
            if (item.status === PENDING) {
                pendiT.push(item)
            }
        });
        socketData(pendiT)
        setExecutedData(exiT);
        setRejectedData(rejectT);
        setPendingData(pendiT);
    };
    useEffect(() => {
        if (tickData?.data) {
            if(tableData.length != 0) {
                const newData = tableData.map(userItem => {
                    if (userItem.symbolName === tickData.data.symbol) {
                        /* if (userItem.tradeType === SELL) {
                             userItem.cmp = tickData.data.bid
                         } else {
                             userItem.cmp = tickData.data.ask
                         }*/
                        userItem.ltp = tickData.data.ltp
                        userItem.ask = tickData.data.ask
                        userItem.bid = tickData.data.bid
                        userItem.ch = tickData.data.ch
                        userItem.chp = tickData.data.chp
                        return {
                            ...userItem
                        };
                    }
                    return userItem;
                });
                setTableData(tableData);
            }
            if(pendingData.length != 0) {
                const newData = pendingData.map(userItem => {
                    if (userItem.symbolName === tickData.data.symbol) {
                        /* if (userItem.tradeType === SELL) {
                             userItem.cmp = tickData.data.bid
                         } else {
                             userItem.cmp = tickData.data.ask
                         }*/
                        userItem.ltp = tickData.data.ltp
                        userItem.ask = tickData.data.ask
                        userItem.bid = tickData.data.bid
                        userItem.ch = tickData.data.ch
                        userItem.chp = tickData.data.chp
                        return {
                            ...userItem
                        };
                    }
                    return userItem;
                });
                setPendingData(newData);
            }
            if (activeOrderData) {
                if (activeOrderData.symbolName === tickData.data.symbol) {
                    setActiveOrderData({
                        ...activeOrderData,
                        ['lask']: activeOrderData.ask,
                        ['lbid']: activeOrderData.bid,
                        ['lltp']: activeOrderData.ltp,
                        ['ltp']: tickData.data.ltp,
                        ['ask']: tickData.data.ask,
                        ['bid']: tickData.data.bid,
                        ['ch']: tickData.data.ch,
                        ['chp']: tickData.data.chp,
                        ['close']: tickData.data.close,
                        ['high']: tickData.data.high,
                        ['lc']: tickData.data.lc,
                        ['uc']: tickData.data.uc,
                        ['low']: tickData.data.low,
                        ['ls']: tickData.data.ls,
                        ['ltt']: tickData.data.ltt,
                        ['open']: tickData.data.open,
                        ['ts']: tickData.data.ts,
                        ['tsq']: tickData.data.tsq,
                        ['volume']: tickData.data.volume
                    });
                }
            }
            // setSocketSymbolData(symbolData)
        }
        // console.log(tickData?.data)
    }, [tickData]);
    const clickToBackReturn = () => {
        navigate('/dashboard', { replace: true }); // Navigate to the new page
    };
    return (
        <>

            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">

                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn" onClick={clickToBackReturn}>
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>

                    <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                        <h5 className="head-numbers mb-0">SL/TP</h5>
                    </div>

                </div>
            </div>

            {isDesktop &&
                <LeftSidebar/>
            }

            <div className="main-container trans">
                <div className="center-main-content">
                    <div className="center-container trans">
                        <div className="home-container">

                            <div className="sltp-page">

                                <div className='profile-card pb-0'>
                                    <Row className="profile-box-main row-space-10 text-center">

                                        <Col xs={4} md={4} lg={4} className='items'>
                                            <div className='profile-card-inn text-center h-100'>
                                                <div className='acc-value'>
                                                    <span className='view-text'>
                                                        <strong>Pending</strong>
                                                    </span>
                                                    <span className='value-text'>
                                                        <strong>{pendingData.length}</strong>
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={4} md={4} lg={4} className='items'>
                                            <div className='profile-card-inn text-center h-100'>
                                                <div className='acc-value'>
                                                    <span className='view-text'>
                                                        <strong>Executed</strong>
                                                    </span>
                                                    <span className='value-text text-primary'>
                                                        <strong>{executedData.length}</strong>
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={4} md={4} lg={4} className='items'>
                                            <div className='profile-card-inn text-center h-100'>
                                                <div className='acc-value'>
                                                    <span className='view-text'>
                                                        <strong>Cancelled</strong>
                                                    </span>
                                                    <span className='value-text text-danger'>
                                                        <strong>{rejectedData.length}</strong>
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                </div>

                                <div className='other-block relative bg-white'>
                                    <Tabs id="uncontrolled-tab-example" className="tab-style">
                                        <Tab eventKey={1} title="All">
                                            <div className='other-list list-new-main p-0'>
                                                {tableData.length == 0 ? <p>Not Found</p> : ''}
                                                {tableData.map((item, index) => (
                                                    <div className='p-list-items bdr-top'
                                                        onClick={() => handleActivePe(item)} key={index}>
                                                        <div
                                                            className="list-inn-header d-flex justify-content-between align-items-center">
                                                            <div className="left-h d-flex align-items-center">
                                                                <div className='p-iconbox text-center'>
                                                                    <div
                                                                        className={"icon-circle-bdr " + ((item.tradeType === BUY) ? '' : 'text-danger')}>
                                                                        <i className='fa fa-arrow-right'></i>
                                                                    </div>
                                                                    <span
                                                                        className='number'>{item.isSymbolWise ? item.quantity : item.totalQuantity}</span>
                                                                </div>

                                                                <div className="user-h ml-2">
                                                                    <h6 className="mb-0">{item.symbolTitle} <small
                                                                        className='list-date ml-2'>{formatSymbolExpiryDate(item.expiryDate)}</small>
                                                                    </h6>
                                                                    <small
                                                                        className='list-date'><strong>{item.tradeType.toUpperCase()}</strong> &nbsp;
                                                                        <strong>
                                                                            <i className={"fa fa-arrow-circle-right " + ((item.tradeType === BUY) ? 'b-clr' : 'text-danger')}></i>
                                                                            {item.price}
                                                                        </strong>
                                                                    </small>
                                                                    {/*<div className='noti-tags'>
                                                                        <small className='list-date'>Successfully
                                                                            Removed
                                                                            Buy @ 7210 Qty : 10.0</small>
                                                                    </div>*/}
                                                                </div>
                                                            </div>
                                                            <div className="right-h text-right">
                                                                <h5 className="mb-0">
                                                                    <small
                                                                        className={'list-date ' + (item.status === EXECUTED ? 'text-primary' : '')}>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</small><br/>
                                                                    {(item.status === PENDING ?
                                                                        <small
                                                                            className='list-date'>CMP : {(item.tradeType === SELL) ? item.bid : item.ask}</small>
                                                                        : '')}
                                                                    {(item.status === PENDING ? <br/> : '')}
                                                                    <small
                                                                        className='list-date'>{formatDateForTradeList(item.executionDateTime)}</small>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Tab>
                                        <Tab eventKey={2} title="Pending">
                                            <div className='other-list list-new-main p-0'>
                                                {pendingData.length == 0 ? <p>Not Found</p> : ''}
                                                {pendingData.map((item, index) => (
                                                    <div className='p-list-items bdr-top'
                                                        onClick={() => handleActivePe(item)} key={index}>
                                                        <div
                                                            className="list-inn-header d-flex justify-content-between align-items-center">
                                                            <div className="left-h d-flex align-items-center">
                                                                <div className='p-iconbox text-center'>
                                                                    <div
                                                                        className={"icon-circle-bdr " + ((item.tradeType === BUY) ? '' : 'text-danger')}>
                                                                        <i className='fa fa-arrow-right'></i>
                                                                    </div>
                                                                    <span
                                                                        className='number'>{item.isSymbolWise ? item.quantity : item.totalQuantity}</span>
                                                                </div>

                                                                <div className="user-h ml-2">
                                                                    <h6 className="mb-0">{item.symbolTitle} <small
                                                                        className='list-date ml-2'>{formatSymbolExpiryDate(item.expiryDate)}</small>
                                                                    </h6>
                                                                    <small
                                                                        className='list-date'><strong>{item.tradeType.toUpperCase()}</strong> &nbsp;
                                                                        <strong>
                                                                            <i className={"fa fa-arrow-circle-right " + ((item.tradeType === BUY) ? 'b-clr' : 'text-danger')}></i>
                                                                            {item.price}
                                                                        </strong>
                                                                    </small>
                                                                </div>
                                                            </div>
                                                            <div className="right-h text-right">
                                                                <h5 className="mb-0">
                                                                    <small className='list-date'>Pending</small><br/>
                                                                    {(item.status === PENDING ?
                                                                        <small
                                                                            className='list-date'>CMP : {(item.tradeType === SELL) ? item.bid : item.ask}</small>
                                                                        : '')}
                                                                    {(item.status === PENDING ? <br/> : '')}
                                                                    <small
                                                                        className='list-date'>{formatDateForTradeList(item.executionDateTime)}</small>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Tab>

                                        <Tab eventKey={3} title="Executed">
                                            <div className='other-list list-new-main p-0'>
                                                {executedData.length == 0 ? <p>Not Found</p> : ''}
                                                {executedData.map((item, index) => (
                                                    <div className='p-list-items bdr-top' key={index}>
                                                        <div
                                                            className="list-inn-header d-flex justify-content-between align-items-center">
                                                            <div className="left-h d-flex align-items-center">
                                                                <div className='p-iconbox text-center'>
                                                                    <div
                                                                        className={"icon-circle-bdr " + ((item.tradeType === BUY) ? '' : 'text-danger')}>
                                                                        <i className='fa fa-arrow-right'></i>
                                                                    </div>
                                                                    <span
                                                                        className='number'>{item.isSymbolWise ? item.quantity : item.totalQuantity}</span>
                                                                </div>

                                                                <div className="user-h ml-2">
                                                                    <h6 className="mb-0">{item.symbolTitle} <small
                                                                        className='list-date ml-2'>{formatSymbolExpiryDate(item.expiryDate)}</small>
                                                                    </h6>
                                                                    <small
                                                                        className='list-date'><strong>{item.tradeType.toUpperCase()}</strong> &nbsp;
                                                                        <strong>
                                                                            <i className={"fa fa-arrow-circle-right " + ((item.tradeType === BUY) ? 'b-clr' : 'text-danger')}></i>
                                                                            {item.price}
                                                                        </strong>
                                                                    </small>
                                                                    {/*<div className='noti-tags'>
                                                                        <small className='list-date'>Successfully
                                                                            Removed
                                                                            Buy @ 7210 Qty : 10.0</small>
                                                                    </div>*/}
                                                                </div>
                                                            </div>
                                                            <div className="right-h text-right">
                                                                <h5 className="mb-0">
                                                                    <small
                                                                        className='list-date text-primary'>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</small><br/>
                                                                    <small
                                                                        className='list-date'>{formatDateForTradeList(item.executionDateTime)}</small>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Tab>

                                        <Tab eventKey={4} title="Cancelled">
                                            <div className='other-list list-new-main p-0'>
                                                {rejectedData.length == 0 ? <p>Not Found</p> : ''}
                                                {rejectedData.map((item, index) => (
                                                    <div className='p-list-items bdr-top' key={index}>
                                                        <div
                                                            className="list-inn-header d-flex justify-content-between align-items-center">
                                                            <div className="left-h d-flex align-items-center">
                                                                <div className='p-iconbox text-center'>
                                                                    <div
                                                                        className={"icon-circle-bdr " + ((item.tradeType === BUY) ? '' : 'text-danger')}>
                                                                        <i className='fa fa-arrow-right'></i>
                                                                    </div>
                                                                    <span
                                                                        className='number'>{item.isSymbolWise ? item.quantity : item.totalQuantity}</span>
                                                                </div>

                                                                <div className="user-h ml-2">
                                                                    <h6 className="mb-0">{item.symbolTitle} <small
                                                                        className='list-date ml-2'>{formatSymbolExpiryDate(item.expiryDate)}</small>
                                                                    </h6>
                                                                    <small
                                                                        className='list-date'><strong>{item.tradeType.toUpperCase()}</strong> &nbsp;
                                                                        <strong>
                                                                            <i className={"fa fa-arrow-circle-right " + ((item.tradeType === BUY) ? 'b-clr' : 'text-danger')}></i>
                                                                            {item.price}
                                                                        </strong>
                                                                    </small>
                                                                    {/*<div className='noti-tags'>
                                                                        <small className='list-date'>Successfully
                                                                            Removed
                                                                            Buy @ 7210 Qty : 10.0</small>
                                                                    </div>*/}
                                                                </div>
                                                            </div>
                                                            <div className="right-h text-right">
                                                                <h5 className="mb-0">
                                                                    <small
                                                                        className='list-date'>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</small><br/>
                                                                    <small
                                                                        className='list-date'>{formatDateForTradeList(item.executionDateTime)}</small>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Tab>

                                    </Tabs>

                                    {/* Popup rendering */}
                                    {activePopup !== null && (
                                        <div className="popup-overlay" onClick={() => setActivePopup(null)}>
                                            <div className="limit-popup" onClick={(e) => e.stopPropagation()}>

                                                <div className='pup-head d-flex v-center'>
                                                    <h5 className='b-clr mb-0'>Pending Limit</h5>

                                                    <div className='pp-right-btn d-flex gap-2 ml-auto'>
                                                        <button className='btn small'
                                                            onClick={() => setActivePopup(null)}>Back
                                                        </button>
                                                        <button className='btn small'
                                                            onClick={() => handleTradeCancel()}>Cancel Limit
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className='limit-bdr bg2 text-white'>
                                                    <Row className="limit-row">
                                                        <Col xs={6} md={6} lg={6} className='items'>
                                                            <span>SYMBOL</span>
                                                        </Col>
                                                        <Col xs={6} md={6} lg={6} className='items text-right'>
                                                            <strong>{activeOrderData.symbolTitle} {formatSymbolExpiryDate(activeOrderData.expiryDate)}</strong>
                                                        </Col>
                                                    </Row>

                                                    <Row className="limit-row">
                                                        <Col xs={6} md={6} lg={6} className='items'>
                                                            <span>Qty</span>
                                                        </Col>
                                                        <Col xs={6} md={6} lg={6} className='items text-right'>
                                                            <strong>{activeOrderData.isSymbolWise ? activeOrderData.quantity : activeOrderData.totalQuantity}</strong>
                                                        </Col>
                                                    </Row>

                                                    <Row className="limit-row">
                                                        <Col xs={6} md={6} lg={6} className='items'>
                                                            <span>Price</span>
                                                        </Col>
                                                        <Col xs={6} md={6} lg={6} className='items text-right'>
                                                            <strong>{activeOrderData.price}</strong>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='limit-bdr bg2 text-white'>
                                                    <Row className="limit-row text-center">
                                                        <Col xs={3} md={3} lg={3} className='items'>
                                                            <span>High</span>
                                                            <div>{activeOrderData.high}</div>
                                                        </Col>
                                                        <Col xs={3} md={3} lg={3} className='items'>
                                                            <span>Low</span>
                                                            <div>{activeOrderData.low}</div>
                                                        </Col>
                                                        <Col xs={3} md={3} lg={3} className='items'>
                                                            <span>BID</span>
                                                            <div>{activeOrderData.bid}</div>
                                                        </Col>
                                                        <Col xs={3} md={3} lg={3} className='items'>
                                                            <span>ASK</span>
                                                            <div>{activeOrderData.ask}</div>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='pup-head d-flex v-center'>
                                                    <h5 className='b-clr mb-0'>Modify Limit</h5>
                                                </div>

                                                <div className='acc-filter-block bg4 ptb-10'>
                                                    <Form className='upi-fields flt-form'>
                                                        <div className="qty-text-block">
                                                            <label className="stk-qty-field w-100 d-flex v-center">
                                                                INSERT
                                                                NEW Qty
                                                                <span className='right-text ml-auto'>
                                                                    <input className='right-text' type="number"
                                                                        onChange={handleInputChange}
                                                                        min={0}
                                                                        name="totalQuantity" id="totalQuantity"
                                                                           value={formData.totalQuantity}/>
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="qty-text-block">
                                                            <label className="stk-qty-field w-100 d-flex v-center">
                                                                INSERT NEW PRICE
                                                                <span className='right-text ml-auto'>
                                                                    <input className='right-text' type="number"
                                                                        id="price" name="price"
                                                                        min={0}
                                                                        value={formData.price}
                                                                           onChange={handleInputChange}/>
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="qty-text-block form-btnbox mt-2">
                                                            <button className="btn primary w-100" type="button"
                                                                onClick={() => handleTrade()}>Modify Limit
                                                            </button>
                                                        </div>
                                                    </Form>
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {isMobile &&
                <Footer/>
            }
        </>
    );
};

export default SLTP;