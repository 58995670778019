import React, {useState} from 'react';
import Footer from "../components/Footer";
import {useNavigate} from 'react-router-dom';
import LeftSidebar from '../components/LeftSidebar';
import {Row, Col, Form, Button} from 'react-bootstrap';
import {useMediaQuery} from "react-responsive";
import "react-toastify/dist/ReactToastify.css";
import {API_ENDPOINT, SUCCESS, ADD_WITHDRAWAL, DEBIT} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import axios from "axios";
import {getDeviceInfo} from "../utils/imData";
import {toastSuccess, toastError} from "../utils/toastMsg";

const Withdraw = () => {
    const jwt_token = localStorage.getItem("token");
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const navigate = useNavigate();
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    const initialFormData = {
        "userName": authenticated.userName,
        "amount": 0,
        "branch": "",
        "ifsc": "",
        "beneficiaryName": "",
        "bankName": "",
        "accountNumber": "",
        "reEnterAccountNumber": "",
        "upiId": "",
        "withdrawalType": "upi",
        "paymentRequestType": "debit"
    };

    const [formData, setFormData] = useState(initialFormData);
    const [selected, setSelected] = useState('yes');
    const [isValidIfsc, setIsValidIfsc] = useState(true);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        let val = value;
        if (name === 'ifsc') {
            val = value.toUpperCase()
        }
        setFormData({
            ...formData,
            [name]: val
        });
    };
    const handleRadioChange = (e) => {
        if (e.target.value === 'no') {
            setFormData({
                ...formData,
                ['withdrawalType']: 'bank'
            });
        }else{
            setFormData({
                ...formData,
                ['withdrawalType']: 'upi'
            });
        }
        setSelected(e.target.value);
    };
    const clickToBackReturn = () => {
        navigate('/profile', {replace: true}); // Navigate to the new page
    };
    const handleReset = () => {
        setFormData(initialFormData);
    };
    const handleIfscCheck = async (e) => {
        setFormData({
            ...formData,
            ['ifsc']: e.target.value.toUpperCase()
        });
        if (e.target.value) {
            const response = await fetch('https://ifsc.razorpay.com/' + e.target.value, {
                method: 'GET',
                // headers: {
                //   'Content-Type': 'application/pdf',
                // },
            });
            if (response.status === 404) {
                setIsValidIfsc(false)
                setFormData({
                    ...formData,
                    ['branch']: ''
                });
            } else {
                const responseData = await response.text();
                const responseN = JSON.parse(responseData);
                setIsValidIfsc(true)
                setFormData({
                    ...formData,
                    ['branch']: responseN.BRANCH
                });
            }
        } else {
            setIsValidIfsc(false)
            setFormData({
                ...formData,
                ['branch']: ''
            });
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (selected === 'yes') {
                if (!formData.upiId || !formData.amount || formData.amount == 0) {
                    toastError("Please fill in all fields")
                    return;
                }
            } else {
                if (!formData.beneficiaryName || !formData.bankName || !formData.ifsc || !formData.accountNumber || !formData.reEnterAccountNumber
                    || !formData.amount || formData.amount == 0) {
                    toastError("Please fill in all fields")
                    return;
                }
            }
            if (!isValidIfsc) {
                toastError("IFSC code not valid")
                return;
            }
            if (formData.accountNumber !== formData.reEnterAccountNumber) {
                toastError("Account number and re-enter account number does not match")
                return;
            }
            const {deviceType, browser, userAgent, newDeviceId, ip} = await getDeviceInfo();
            let data = encryptData({
                "userId": authenticated.userId,
                "parentId": authenticated.parentId,
                "amount": formData.amount,
                "transactionId": formData.transactionId,
                "paymentRequestType": formData.paymentRequestType,
                "withdrawalType": formData.withdrawalType,
                "bankName": formData.bankName,
                "beneficiaryName": formData.beneficiaryName,
                "accountNumber": formData.accountNumber,
                "ifsc": formData.ifsc,
                "upiId": formData.upiId,
                "browser": browser,
                "userAgent": userAgent,
                "deviceId": newDeviceId,
                "deviceType": deviceType,
                "ipAddress": ip,
            });
            data = JSON.stringify({data: data});
            axios
                .post(API_ENDPOINT + ADD_WITHDRAWAL, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        console.log(resData)
                        handleReset();
                        toastSuccess(response.data.meta.message)
                    } else {
                        toastError(response.data.message)
                        // console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                    // console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const clickToOpenViewRequestPage = () => {
        const valueToSend = {'pageType': DEBIT}; // Data to pass
        navigate('/view-request', {state: valueToSend}); // Navigate to the new page
    };

    return (
        <>

            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">

                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn" onClick={clickToBackReturn}>
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>

                    <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                        <h5 className="head-numbers mb-0">Withrawal Request</h5>
                    </div>

                    <div className="page-head-flt d-flex v-center">
                        <button className="btn white-bdr small" onClick={clickToOpenViewRequestPage}>View Request</button>
                    </div>

                </div>
            </div>

            {isDesktop &&
                <LeftSidebar/>
            }

            <div className="main-container trans">
                <div className="center-main-content">
                    <div className="center-container trans">
                        <div className="home-container">

                            <div className="withraw-page">

                                <Row className="withraw-btns-block row-space-10 ptb-10 pt-0 text-center">
                                    <Col xs={6} md={6} lg={6} className='items'>
                                        <div className='profile-card-inn text-center h-100'>
                                            <div className="radio-opt-btnbox w-100">
                                                <label className={selected === 'yes' ? 'active' : ''} htmlFor="upi">
                                                    <input id="upi" type="radio" name="mkt_opt" value="yes"
                                                           onChange={handleRadioChange}/>
                                                    <button className="btn white-bdr w-100">UPI</button>
                                                </label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={6} lg={6} className='items'>
                                        <div className='profile-card-inn text-center h-100'>
                                            <div className="radio-opt-btnbox w-100">
                                                <label className={selected === 'no' ? 'active' : ''} htmlFor="bank">
                                                    <input id="bank" type="radio" name="mkt_opt" value="no"
                                                           onChange={handleRadioChange}/>
                                                    <button className="btn white-bdr w-100">Bank Account</button>
                                                </label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='bdr-card'>
                                    {selected === 'yes' ? (
                                        <Form className='upi-fields ptb-10 pb-0'>
                                            <div className="qty-text-block">
                                                <label className="stk-qty-field w-100">
                                                    <span className="form-label">Upi ID</span>
                                                    <input className="form-control w-100" type="text"
                                                           placeholder="eg. abcz@okhdfcbank"
                                                           name="upiId"
                                                           id="upiId"
                                                           value={formData.upiId}
                                                           onChange={handleInputChange}/>
                                                </label>
                                            </div>

                                            <div className="qty-text-block">
                                                <label className="stk-qty-field w-100">
                                                    <span className="form-label">Enter Amount</span>
                                                    <input className="form-control w-100" type="number"
                                                           placeholder="eg. 20.00"
                                                           name="amount"
                                                           id="amount"
                                                           value={formData.amount}
                                                           onChange={handleInputChange}/>
                                                </label>
                                            </div>

                                            <div className="qty-text-block form-btnbox mt-2">
                                                <Button className='btn primary w-100' variant="primary"
                                                        onClick={handleSubmit}>Submit</Button>
                                            </div>
                                        </Form>
                                    ) : (
                                        <Form className='bank-fields ptb-10 pb-0'>
                                            <div className="qty-text-block">
                                                <label className="stk-qty-field w-100">
                                                    <span className="form-label">Beneficiary Name</span>
                                                    <input className="form-control w-100" type="text"
                                                           placeholder="eg. john snow"
                                                           name="beneficiaryName"
                                                           id="beneficiaryName"
                                                           value={formData.beneficiaryName}
                                                           onChange={handleInputChange}/>
                                                </label>
                                            </div>

                                            <div className="qty-text-block">
                                                <label className="stk-qty-field w-100">
                                                    <span className="form-label">BANK NAME</span>
                                                    <input className="form-control w-100" type="text"
                                                           placeholder="eg. HDFC BANK LTD"
                                                           name="bankName"
                                                           id="bankName"
                                                           value={formData.bankName}
                                                           onChange={handleInputChange}/>
                                                </label>
                                            </div>

                                            <div className="qty-text-block">
                                                <label className="stk-qty-field w-100">
                                                    <span className="form-label">IFSCCODE</span>
                                                    <input className="form-control w-100" type="text"
                                                           placeholder="eg. HDFC0000XXX"
                                                           name="ifsc"
                                                           id="ifsc" value={formData.ifsc}
                                                           onChange={handleInputChange}
                                                           onBlur={handleIfscCheck}
                                                    />
                                                </label>
                                                {!isValidIfsc ?
                                                    <span className="error-message">IFSC code not valid</span>
                                                    : ''}
                                                {formData.branch ?
                                                    <span>{formData.branch}</span>
                                                    : ''}
                                            </div>

                                            <div className="qty-text-block">
                                                <label className="stk-qty-field w-100">
                                                    <span className="form-label">Account Number</span>
                                                    <input className="form-control w-100" type="text"
                                                           placeholder="eg. 05xxx04034"
                                                           name="accountNumber"
                                                           id="accountNumber"
                                                           value={formData.accountNumber}
                                                           onChange={handleInputChange}/>
                                                </label>
                                            </div>

                                            <div className="qty-text-block">
                                                <label className="stk-qty-field w-100">
                                                    <span className="form-label">Re-Enter Account Number</span>
                                                    <input className="form-control w-100" type="text"
                                                           placeholder="eg. 05xxx04034"
                                                           name="reEnterAccountNumber"
                                                           id="reEnterAccountNumber"
                                                           value={formData.reEnterAccountNumber}
                                                           onChange={handleInputChange}/>
                                                </label>
                                            </div>

                                            <div className="qty-text-block">
                                                <label className="stk-qty-field w-100">
                                                    <span className="form-label">Enter Amount</span>
                                                    <input className="form-control w-100" type="number"
                                                           placeholder="eg. 20.00"
                                                           min={0}
                                                           name="amount"
                                                           id="amount"
                                                           value={formData.amount}
                                                           onChange={handleInputChange}/>
                                                </label>
                                            </div>

                                            <div className="qty-text-block form-btnbox mt-2">
                                                <Button className='btn primary w-100' variant="primary"
                                                        onClick={handleSubmit}>Submit</Button>
                                            </div>
                                        </Form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isMobile &&
                <Footer/>
            }
        </>
    );
};

export default Withdraw;