import React, {useState, useEffect} from 'react';
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import {Row, Col, Card, Form, Button} from 'react-bootstrap';
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import {
    API_ENDPOINT,
    SUCCESS,
    ANNOUNCEMENT_LIST
} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import {useLocation, useNavigate} from 'react-router-dom';

const TermsAndConditionsModal = ({clickHideNotificationPoup}) => {

return (
        <>
        <div className="notify_modal_screen">

            <div className="page-top-block fixed-top">
                <div className="page-head bg1 d-flex">
                    <div className="page-head-middle d-flex v-center">

                        <h5 className="head-numbers mb-0">TERMS AND CONDITIONS</h5>

                    </div>
                </div>
            </div>

            <div className="terms-page">
                <div className='terms-block'>

                    <div className="terms-items">
                        <span className='list-number mr-2'>1.</span>
                        MARKET TRADE TIMING OF NSE WILL BE FROM 09.17 AM & MCX FROM 09.02 AM.
                    </div>
                    
                    <div className="terms-items">
                        <span className='list-number mr-2'>2.</span>
                        PENDING/LIMIT WILL BE PASSED BY LTP PRICE.
                    </div>
                    
                    <div className="terms-items">
                        <span className='list-number mr-2'>3.</span>
                        THIS APPLICATION IS USED FOR EXCHANGING VIEWS ON MARKETS FOR INDIVIDUAL STUDENT FOR TRAINING PURPOSE ONLY.
                    </div>
                    
                    <div className="terms-items">
                        <span className='list-number mr-2'>4.</span>
                        NO REAL MONEY INVOLVED.THIS IS VIRTUAL TRADING APPLICATION WHICH HAS ALL THE FEATURES TO TRADE.
                    </div>
                    <div className="terms-items">
                        <span className='list-number mr-2'>5.</span>
                        DEPOSIT AND WITHDRAW TIMEIND FOR ALL SEGMENTS IS 8.45 AM TO 11.45 PM SUNDAY CLOSE 
                    </div>

                    <div className="terms-items">
                        <span className='list-number mr-2'>6.</span>
                        MARAKET TRADE TIMING OF GIFT NIFTY WILL BE 06:32 AM TO 2:45 AM ,US ININDEX 3.32 AM TO 2:30 AM ,US STOCKS 7:02 PM TO 2:30 AM ,COMEX , CRYPOT , CURRENCY TIMING 3.32 AM TO 2.30 AM
                    </div>

                    <div className="terms-items">
                        <span className='list-number mr-2'>7.</span>
                        DEPOSIT AND WITHDRAW TIMING FOR ALL SEGMENTS ARE  MONDAY TO SATURDAY  08:40 AM TO 11:40 PM 
                    </div>

                    <div className="terms-items">
                        <span className='list-number mr-2'>8.</span>
                        ❗️❗️Time Limit Notice❗️❗️ Time limit for holding profit in FOREX , CRYPTO , US.STOCKS IS 15 mins if any trade is cut before 15mins, the trade will be deleted as well as the profit gained in it⚠️
                    </div>

                    <div className="terms-items">
                        <span className='list-number mr-2'>9.</span>
                        BONUS , DIVIDEND , SPLIT AGAR KOI BHI SCRIPT ME HOGA TO AGLE DIN KE LAST QUOTATION SE CONTRACT SQUARE OFF HO JAYEGA.
                    </div>
                    
                    <div className="terms-items">
                        <span className='list-number mr-2'>10.</span>
                        PLEASE DEPOSIT IN THE BANK MENTION ON APPLICATION OR CALL HELPLINE FOR DEPOSIT .
                    </div>
                    
                    <div className="terms-items">
                        <span className='list-number mr-2'>11.</span>
                        TICK (CF) TO CARRY FORWARD YOUR POSITION TO THE NEXT DAY : OTHERWISE ,IT WILL SQUARED OFF AT THE QUOTATION PRICE . CF TIMING FOR NSE IS 3:15 PM AND FOR MCX IS 11:15 PM
                    </div>

                </div>
            </div>

            <div className="page-bottom-block fixed-bottom">
                <button onClick={clickHideNotificationPoup} className="btn primary w-100 terms-btn">I AGREE TERMS AND CONDITIONS</button>
            </div>

        </div>
        </>
    );
};

export default TermsAndConditionsModal;