import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./store";
import {Provider} from "react-redux";
import webSocketManager from './services/WebSocketManager';
import {WebSocketProvider} from './services/WebSocketContext';
import {ToastContainer} from 'react-toastify';
import {SocketProvider} from "./services/SocketContext";

webSocketManager.connect();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <React.StrictMode>
            <WebSocketProvider>
                <SocketProvider>
                    <ToastContainer/>
                    <App/>
                </SocketProvider>
            </WebSocketProvider>
        </React.StrictMode>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


