import React, {useRef, useState, useEffect} from 'react'
import {Row, Col, Form, Button} from 'react-bootstrap'
import {FaInfoCircle, FaWhatsapp} from "react-icons/fa";
/* import qr_image from '../assets/images/qrcode.jpg'; */
import {API_ENDPOINT, BANK_DETAILS_VIEW_FOR_USER, SUCCESS, ADD_DEPOSIT, CREDIT} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import axios from "axios";
import {QRCodeCanvas} from 'qrcode.react';
import "react-toastify/dist/ReactToastify.css";
import {getDeviceInfo} from "../utils/imData";
import {useNavigate} from 'react-router-dom';
import {toastSuccess, toastError} from "../utils/toastMsg";

const DepositRequest = () => {
    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const [userBankData, setUserBankData] = useState({});
    const navigate = useNavigate();
    const initialFormData = {
        "userName": authenticated.userName,
        "amount": 0,
        "transactionId": "",
        "paymentRequestType": "credit"
    };
    const [formData, setFormData] = useState(initialFormData);
    const fileInputRef = useRef(null); // Reference to the file input
    const [image, setImage] = useState(null);
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
            if (!validTypes.includes(selectedFile.type)) {
                setImage(null);
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; // Clear the input's value
                }
                toastError("Invalid file type. Only JPEG, PNG, and GIF are allowed.")
                return;
            }
            setImage(selectedFile);
        }
    };
    const handleGetBankData = async () => {
        let data = encryptData({
            "userId": authenticated.parentId
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + BANK_DETAILS_VIEW_FOR_USER, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        setUserBankData(resData)
                    } else {
                        // toastError(response.data.message)
                        console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!formData.userName || !formData.amount || formData.amount == 0 || !formData.transactionId) {
                toastError("Please fill in all fields")
                return;
            }
            const {deviceType, browser, userAgent, newDeviceId, ip} = await getDeviceInfo();
            const formDatan = new FormData();
            formDatan.append('image', image);
            let data = encryptData({
                "userId": authenticated.userId,
                "bankDetailsId": userBankData.bankDetailsId,
                "parentId": authenticated.parentId,
                "userName": formData.userName,
                "amount": formData.amount,
                "transactionId": formData.transactionId,
                "paymentRequestType": formData.paymentRequestType,
                "browser": browser,
                "userAgent": userAgent,
                "deviceId": newDeviceId,
                "deviceType": deviceType,
                "ipAddress": ip,
            });
            formDatan.append('data', data);
            axios
                .post(API_ENDPOINT + ADD_DEPOSIT, formDatan, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'multipart/form-data',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        console.log(resData)
                        handleReset();
                        toastSuccess(response.data.meta.message)
                    } else {
                        toastError(response.data.message)
                        // console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                    // console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const handleReset = () => {
        setFormData(initialFormData);
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Clear the input's value
        }
    };
    const clickToBackReturn = () => {
        navigate('/dashboard', {replace: true}); // Navigate to the new page
    };
    const clickToOpenViewRequestPage = () => {
        const valueToSend = {'pageType': CREDIT}; // Data to pass
        navigate('/view-request', {state: valueToSend}); // Navigate to the new page
    };
    useEffect(() => {
        handleGetBankData()
    }, []);
    return (
        <div className="deposite-wrapper d-flex v-center j-center">
            <div className='dbox-screen relative'>

                <div className="page-head deposite-page-head relative d-flex p-0 mb-2">
                    <div className="page-head-left d-flex v-center" onClick={clickToBackReturn}>
                        <button className="back-btn text-primary"><i className="fa fa-arrow-left"></i></button>
                    </div>

                    <div className="page-head-flt d-flex v-center">
                        <button className="btn small" onClick={clickToOpenViewRequestPage}>View Request</button>
                    </div>
                </div>

                <div className="deposite-form-block">
                    <Row className="inner-row">
                        {userBankData?.bankName ?
                            <Col xs={12} md={12} lg={4} className='dp-left-block'>
                                {/* <div className='block-title'>
                                    <h2 className='b-clr'>Trade 1</h2>
                                </div> */}

                                <div className='bank-info-main d-flex'>
                                    <div className='bank-qrcode-box bank-block-box text-center'>
                                        <i className='qrcode-imgbox d-flex'>
                                            {/*<img src={qr_image} alt=''/>*/}
                                            <QRCodeCanvas
                                                value={`upi://pay?pa=${userBankData.upiId}&pn=${userBankData.accountHolderName}`}/>
                                        </i>
                                        <small className='text-1'>Scan with any UPI App and Pay Amount</small>
                                        <div className='upi-text'>
                                            UPI ID : <strong>{userBankData.upiId}</strong>
                                        </div>
                                    </div>

                                    <div className='bank-detail-box bank-block-box'>
                                        <div className='upi-text'>Bank Name: <strong>{userBankData.bankName}</strong>
                                        </div>
                                        <div className='upi-text'>A/C
                                            Holder: <strong>{userBankData.accountHolderName}</strong>
                                        </div>
                                        <div className='upi-text'>A/C No: <strong>{userBankData.accountNumber}</strong>
                                        </div>
                                        <div className='upi-text'>IFSC: <strong>{userBankData.ifsc}</strong></div>
                                    </div>

                                    {/*<div className='bank-detail-box bank-block-box'>*/}
                                    {/*    <div className='upi-text'>Bank Name: <strong>YES BANK</strong></div>*/}
                                    {/*    <div className='upi-text'>A/C Holder: <strong>LOREM TRADERS</strong></div>*/}
                                    {/*    <div className='upi-text'>A/C No: <strong>012345600007890</strong></div>*/}
                                    {/*    <div className='upi-text'>IFSC: <strong>YESB0000123</strong></div>*/}
                                    {/*</div>*/}
                                </div>
                            </Col>
                            : ''}
                        <Col xs={12} md={12} lg={8} className='dp-right-block'>
                            <div className='block-title'>
                                <h2 className='b-clr'>Deposit Request</h2>
                            </div>

                            <div className="alert alert-primary border-0" role="alert">
                                <i className='info_icon mr-2'><FaInfoCircle/></i> Please use this form to enter your
                                Transaction ID after payment.
                            </div>

                            <Form className='form-inner form-style-secondary d-flex' onSubmit={handleSubmit}>
                                <Form.Group className='input-field full-width'>
                                    <label className='label text-primary'>Client Username <span
                                        className='text-danger'>*</span></label>
                                    <Form.Control type='text' name="userName" id="userName" placeholder=''
                                                  value={formData.userName} onChange={handleInputChange}/>
                                </Form.Group>

                                <Form.Group className='input-field full-width'>
                                    <label className='label text-primary'>Deposit Amount <span
                                        className='text-danger'>*</span></label>
                                    <Form.Control type='number' name="amount" id="amount"
                                                  placeholder='Enter Deposit Amount' value={formData.amount}
                                                  onChange={handleInputChange}/>
                                </Form.Group>

                                <Form.Group className='input-field full-width'>
                                    <label className='label text-primary'>Transaction ID</label>
                                    <Form.Control type='text' placeholder='Enter Transaction ID' name="transactionId"
                                                  id="transactionId" value={formData.transactionId}
                                                  onChange={handleInputChange}/>
                                </Form.Group>

                                <Form.Group className='input-field full-width'>
                                    <label className='label text-primary'>Upload Screen Shot</label>
                                    <div className='upload-file-field'>
                                        <Form.Control type='file' ref={fileInputRef} onChange={handleFileChange}/>
                                    </div>
                                </Form.Group>

                                <Form.Group className='input-field full-width form-btnbox mb-0'>
                                    <Button className='btn primary mw-142' variant="primary"
                                            type="submit">Submit</Button>
                                </Form.Group>
                            </Form>
                            {userBankData?.masterData?.phone ?
                                <div className='whatsapp-btnbox ptb-20'>
                                    <a className='btn btn-whatsapp' target="_blank"
                                       href={"https://api.whatsapp.com/send?phone="+userBankData.masterData.phone+"&text=&source=&data="}><FaWhatsapp/> Contact
                                        Us On WhatsApp</a>
                                </div>
                                : ''}
                        </Col>

                    </Row>
                </div>
            </div>
        </div>
    );
};

export default DepositRequest;