import React, {useState, useEffect} from 'react';
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import {Row, Col, Card, Form, Button} from 'react-bootstrap';
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import {
    API_ENDPOINT,
    SUCCESS,
    ANNOUNCEMENT_LIST
} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import {useLocation, useNavigate} from 'react-router-dom';

const Notification = () => {

    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    let authenticated = JSON.parse(
        localStorage.getItem("authenticated")
    );
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    const navigate = useNavigate();
    const location = useLocation();
    const passedData = location.state; // Get the passed data

    const [announcementData, setAnnouncementData] = useState([]);
    const announcementList = async () => {
        try {
            let data = encryptData({
                "page": 1,
                "limit": 1000,
            });
            data = JSON.stringify({data: data});
            axios
                .post(API_ENDPOINT + ANNOUNCEMENT_LIST, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        setAnnouncementData(resData)
                    } else {
                        // toastError(response.data.message)
                        console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const clickToBackReturn = () => {
        const valueToSend = {activeTabId: passedData.activeTabId}; // Data to pass
        navigate('/dashboard', {replace: true, state: valueToSend}); // Navigate to the new page
        // window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
    };
    useEffect(() => {
        announcementList()
        localStorage.setItem(
            "isNotificationRead",
            1
        );

    }, []);
    return (
        <>

            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">

                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn" onClick={clickToBackReturn}>
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>

                    <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                        <h5 className="head-numbers mb-0">News Bill</h5>
                    </div>

                </div>
            </div>

            {isDesktop &&
                <LeftSidebar/>
            }

            <div className="main-container trans">
                <div className="center-main-content">
                    <div className="center-container trans">
                        <div className="home-container">

                            <div className="notify-page">
                                <div className='notify-block'>


                                    {announcementData.map((item, index) => (
                                        <div className="notify-items">
                                            <strong className='notify-tl b-clr'>{item.title}</strong>
                                            <strong className='notify-text'>{item.announcement}</strong>
                                        </div>
                                    ))}

                                    {/*<div className="notify-items">
                                        <strong className='notify-tl b-clr'>** DOLLAR RATE **</strong>
                                        <strong className='notify-text'>USD 85 POINTS</strong>
                                    </div>*/}


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {isMobile &&
                <Footer/>
            }
        </>
    );
};

export default Notification;