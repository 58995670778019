import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import {Container, Tab, Tabs, Row, Col, Card, Form} from 'react-bootstrap';
import {FaCoins, FaHandPaper, FaFileDownload, FaWhatsapp, FaDribbble, FaBook, FaCalendarCheck} from "react-icons/fa";
import {RiLockPasswordFill, RiLogoutCircleRFill} from "react-icons/ri";
import {FaCircleArrowRight} from "react-icons/fa6";
import piechart from '../assets/images/pie-chart.png';
import acc_img from '../assets/images/sitelogo1.png';
import {useMediaQuery} from "react-responsive";
import {toast, Bounce} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
    API_ENDPOINT, CHANGE_PASSWORD, LOGOUT, SUCCESS, GET_PARENT_MOBILE
} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import {v4 as uuidv4} from 'uuid';
import {toastSuccess, toastError} from "../utils/toastMsg";

const Profile = () => {
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const navigate = useNavigate();
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    const [wpUrl, setWpUrl] = useState('');
    const clickToBackReturn = () => {
        navigate('/dashboard', {replace: true}); // Navigate to the new page
    };
    const clickToViewStatement = () => {
        navigate('/ledger-account-report', {replace: true}); // Navigate to the new page
    };
    const getDeviceInfo = async () => {
        const userAgent = navigator.userAgent;
        let newDeviceType = "desktop";
        let browser = "Unknown";

        // Check if the device is mobile
        if (/Mobi|Android/i.test(userAgent)) {
            newDeviceType = "mobile";
        }

        // Browser detection (simplified)
        if (userAgent.includes("Chrome")) {
            browser = "Chrome";
        } else if (userAgent.includes("Firefox")) {
            browser = "Firefox";
        } else if (userAgent.includes("Safari")) {
            browser = "Safari";
        } else if (userAgent.includes("Edge")) {
            browser = "Edge";
        }
        let newDeviceId = localStorage.getItem('deviceId');
        if (!newDeviceId) {
            newDeviceId = uuidv4();
            localStorage.setItem('deviceId', newDeviceId);
        }
        let deviceType = localStorage.getItem('deviceType');
        if (!deviceType) {
            deviceType = newDeviceType;
            localStorage.setItem('deviceType', deviceType);
        }
        let ip = '';
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            ip = response.data.ip;
            return {deviceType, browser, userAgent, newDeviceId, ip};
        } catch (error) {
            return {deviceType, browser, userAgent, newDeviceId, ip};
        }
    };

    const handleGetParentIdList = async (parentId) => {
        let data = encryptData({
            "userId": parentId
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + GET_PARENT_MOBILE, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        let url = "https://api.whatsapp.com/send?phone="+resData.phone+"&text=&source=&data=";
                        setWpUrl(url)
                    } else {
                        // toastError(response.data.message)
                        console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const handleLogout = async () => {
        try {
            const {deviceType, browser, userAgent, newDeviceId, ip} = await getDeviceInfo();
            let data = encryptData({
                "loginBy": "Web",
                "browser": browser,
                "userAgent": userAgent,
                "deviceId": newDeviceId,
                "deviceType": deviceType,
                "ipAddress": ip
            });
            data = JSON.stringify({data: data});
            axios
                .post(API_ENDPOINT + LOGOUT, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        localStorage.removeItem('authenticated');
                        localStorage.removeItem('token');
                        localStorage.setItem("isNotificationRead", 0);
                        localStorage.setItem("isTermsAndConditions", 0);
                        localStorage.setItem('authenticated', false);
                        navigate('/login'); // Navigate to the new page
                        toastSuccess(response.data.meta.message)
                    } else {
                        toastError(response.data.message)
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                });
        } catch (error) {
        }
    };

    useEffect(() => {
        handleGetParentIdList(authenticated.parentId)
    }, []);
    return (
        <>

            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">

                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn" onClick={clickToBackReturn}>
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>

                    <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                        <h5 className="head-numbers mb-0">Profile</h5>
                    </div>

                </div>
            </div>

            {isDesktop &&
                <LeftSidebar/>
            }

            <div className="main-container trans">
                <div className="center-main-content">
                    <div className="center-container trans">
                        <div className="home-container">

                            <div className="profile-page">

                                <div className='page-top-card bg1'>

                                    <div className='page-white-card bg-white'>
                                        <Row className="box-row-main bold-text-style row-space-10 v-center">

                                            <Col xs={6} md={6} lg={6} className='items'>
                                                <div className='abl-balance-block'>
                                                    <span className='text'>Available Balance</span>
                                                    <div className='balance-only mt-1'>
                                                        <h3 className='mb-0'><FaCoins/>
                                                            <strong>{(authenticated?.balance) ? authenticated.balance.toFixed(2) : 0}</strong></h3>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xs={6} md={6} lg={6} className='items d-flex'>
                                                <div className='trans-btnbox ml-auto'>
                                                    <button className='btn primary mw-142'
                                                            onClick={clickToViewStatement}>
                                                        All Transactions <i className="las la-angle-right mr-0 ml-1"></i>
                                                    </button>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>

                                    <div className='page-white-card bg-white'>
                                        <Row className="box-row-main bold-text-style row-space-10 text-center">

                                            <Col xs={4} md={4} lg={4} className='items'>
                                                <div className='box-card text-center h-100'>
                                                    <div className='acc-value'>
                                                <span className='view-text'>
                                                    <strong>Deposit</strong>
                                                </span>
                                                        <div className='pro-btnbox'>
                                                            <Link className='btn primary deposit-btn green-btn mw-142'
                                                                  to='/deposit-request'>
                                                                <i className="btn_iconbox"><i className="las la-wallet"></i></i>
                                                                <em>Deposit</em>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xs={4} md={4} lg={4} className='items'>
                                                <div className='box-card text-center h-100'>
                                                    <div className='acc-value'>
                                                <span className='view-text'>
                                                    <strong>Withdraw</strong>
                                                </span>
                                                        <div className='pro-btnbox'>
                                                            <Link className='btn white-btn withdraw-btn mw-142'
                                                                  to='/withdraw'>
                                                                <i className="btn_iconbox"><i
                                                                    className="las la-receipt"></i></i>
                                                                <em>Withdraw</em>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xs={4} md={4} lg={4} className='items'>
                                                <div className='box-card text-center h-100'>
                                                    <div className='acc-value'>
                                                <span className='view-text'>
                                                    <strong>{authenticated.userName}</strong>
                                                </span>
                                                        <div className='pro-btnbox'>
                                                            <button className='btn green-btn mw-142'
                                                                    onClick={clickToViewStatement}>
                                                                <i className="btn_iconbox"><img src={piechart} alt=''
                                                                                            width="24"/></i>
                                                                <em>View <br/>Statement</em>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>

                                </div>

                                <div className='acc-info-list'>

                                    <Link className='acc-info-items d-flex v-center' to='/account'>
                                        <div className='p-left d-flex v-center'>
                                            <div className='iconbox d-flex'><img src={acc_img} alt=''/></div>
                                            <strong className='list_text'>Account Details</strong>
                                        </div>
                                        <div className='p-right-arrow ml-auto'>
                                            <i className="las la-angle-right"></i>
                                        </div>
                                    </Link>

                                    <Link className='acc-info-items d-flex v-center' to='/rejection'>
                                        <div className='p-left d-flex v-center'>
                                            <div className='iconbox d-flex'><FaHandPaper/></div>
                                            <strong className='list_text'>Rejection Logs</strong>
                                        </div>
                                        <div className='p-right-arrow ml-auto'>
                                            <i className="las la-angle-right"></i>
                                        </div>
                                    </Link>

                                    <Link className='acc-info-items d-flex v-center' to='/download-bill'>
                                        <div className='p-left d-flex v-center'>
                                            <div className='iconbox d-flex'><FaFileDownload/></div>
                                            <strong className='list_text'>Bill Download</strong>
                                        </div>
                                        <div className='p-right-arrow ml-auto'>
                                            <i className="las la-angle-right"></i>
                                        </div>
                                    </Link>

                                    <Link className='acc-info-items d-flex v-center' to='/changepass'>
                                        <div className='p-left d-flex v-center'>
                                            <div className='iconbox d-flex'><RiLockPasswordFill/></div>
                                            <strong className='list_text'>Change Password</strong>
                                        </div>
                                        <div className='p-right-arrow ml-auto'>
                                            <i className="las la-angle-right"></i>
                                        </div>
                                    </Link>

                                    <Link className='acc-info-items d-flex v-center' to='/script-quantity'>
                                        <div className='p-left d-flex v-center'>
                                            <div className='iconbox d-flex'><FaDribbble/></div>
                                            <strong className='list_text'>Script Quantity</strong>
                                        </div>
                                        <div className='p-right-arrow ml-auto'>
                                            <i className="las la-angle-right"></i>
                                        </div>
                                    </Link>

                                    <Link className='acc-info-items d-flex v-center' to='/market-timing'>
                                        <div className='p-left d-flex v-center'>
                                            <div className='iconbox d-flex'><FaCalendarCheck/></div>
                                            <strong className='list_text'>Market Timing</strong>
                                        </div>
                                        <div className='p-right-arrow ml-auto'>
                                            <i className="las la-angle-right"></i>
                                        </div>
                                    </Link>

                                    <Link className='acc-info-items d-flex v-center' to='/rules-and-regulations'>
                                        <div className='p-left d-flex v-center'>
                                            <div className='iconbox d-flex'><FaBook/></div>
                                            <strong className='list_text'>Rules and Regulations</strong>
                                        </div>
                                        <div className='p-right-arrow ml-auto'>
                                            <i className="las la-angle-right"></i>
                                        </div>
                                    </Link>

                                    <Link className='acc-info-items d-flex v-center' target="_blank" to={wpUrl}>
                                        <div className='p-left d-flex v-center'>
                                            <div className='iconbox d-flex'><FaWhatsapp/></div>
                                            <strong className='list_text'>Contact Us On WhatsApp</strong>
                                        </div>
                                        <div className='p-right-arrow ml-auto'>
                                            <i className="las la-angle-right"></i>
                                        </div>
                                    </Link>

                                    <Link className='acc-info-items d-flex v-center' onClick={handleLogout}>
                                        <div className='p-left d-flex v-center'>
                                            <div className='iconbox d-flex'><RiLogoutCircleRFill/></div>
                                            <strong className='list_text'>Logout</strong>
                                        </div>
                                        <div className='p-right-arrow ml-auto'>
                                            <i className="las la-angle-right"></i>
                                        </div>
                                    </Link>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {isMobile &&
                <Footer/>
            }
        </>
    );
};

export default Profile;