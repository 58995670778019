import React, {useState, useEffect} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import {Container, Tab, Tabs, Row, Col, Card, Form} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import Select from "react-select";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import {
    API_ENDPOINT,
    EXCHANGE_TIME_SCHEDULE_LIST,
    SUCCESS,
    EXECUTED,
    BUY,
    LIMIT,
    STOPLOSS, EXCHANGE_LIST
} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import {formatSymbolExpiryDate, formatDate} from "../utils/dateUtils";
import {toastSuccess, toastError} from "../utils/toastMsg";

const MarketTiming = () => {
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    const [tableData, setTableData] = useState([]);
    const [exchangeData, setExchangeData] = useState([]);
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [isVisible, setIsVisible] = useState(false);
    const toggleBox = () => {
        setIsVisible(!isVisible);
    };

    const [events, setEvents] = useState([
        {id: "1", title: "Meeting", date: "2025-03-01"},
        {id: "2", title: "Conference", date: "2025-03-07"},
    ]);
    const fetchDataFromAPI = async () => {
        try {
            if (!formData.exchange) {
                toastError("Please select exchange")
                return
            }
            let data = encryptData({
                "search": "",
                "userId": authenticated.parentId,
                "symbolId": '',
                "exchangeId": formData.exchange.value,
            });
            data = JSON.stringify({data: data});
            axios
                .post(API_ENDPOINT + EXCHANGE_TIME_SCHEDULE_LIST, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const rdata = decryptData(response.data.data)
                        const entriesToLoad = [];
                        for (let i in rdata) {
                            var weekOff = rdata[i].weekOff.join();
                            const item = {
                                id: i,
                                title: rdata[i].startTime +' - '+rdata[i].endTime, // ✅ Display title
                                daysOfWeek: weekOff,
                            };
                            entriesToLoad.push(item);
                        }
                        setTableData(entriesToLoad);
                    } else {
                        toastError(response.data.message)
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleGetExchange = async () => {

        let data = encryptData({
            "page": 1,
            "limit": 100,
            "search": "",
            "sortKey": "createdAt",
            "sortBy": -1
        });
        data = JSON.stringify({data: data});
        axios
            .post(API_ENDPOINT + EXCHANGE_LIST, data, {
                headers: {
                    'Content-Type': 'application/json',
                    "deviceType": deviceType,
                    Authorization: jwt_token,
                },
            })
            .then((response) => {
                if (response.data.statusCode == SUCCESS) {
                    const rdata = decryptData(response.data.data)
                    let rRes = rdata.map((item) => ({
                        label: item.name,
                        value: item.exchangeId,
                    }));
                    setExchangeData(rRes)
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    const handleChangeValueOption = (selectedOptions, name) => {
        setFormData({
            ...formData,
            [name]: selectedOptions,
        });
    };
    useEffect(() => {
        handleGetExchange();
    }, []);
    const clickToBackReturn = () => {
        navigate('/profile', {replace: true}); // Navigate to the new page
    };
    return (
        <>

            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">

                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn" onClick={clickToBackReturn}>
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>

                    <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                        <h5 className="head-numbers mb-0">Market Timing</h5>
                    </div>

                    <div className="page-head-flt d-flex v-center">
                        <button className='btn white-bdr small' onClick={toggleBox}>FILTER</button>
                    </div>

                </div>
            </div>

            {isVisible && (
                <div className='acc-filter-block fixed-right-filter bg4 ptb-10'>
                    <Form className='upi-fields flt-form'>

                        <div className="qty-text-block">
                            <Select
                                id="searchable-select"
                                options={exchangeData}
                                value={formData.exchange}
                                onChange={(selectedOptions) => handleChangeValueOption(selectedOptions, 'exchange')}
                                isLoading={!exchangeData.length} // Show loading spinner while fetching options
                            />
                        </div>

                        <div className="qty-text-block form-btnbox mt-2">
                            <button type="button" className="btn primary w-100"
                                    onClick={(e) => fetchDataFromAPI()}>Submit
                            </button>
                        </div>

                    </Form>
                </div>
            )}

            {isDesktop &&
                <LeftSidebar/>
            }

            <div className="main-container trans">
                <div className="center-main-content">
                    <div className="center-container trans">
                        <div className="home-container">

                            <div className="trades-page">

                                <div className='bdr-card other-block p-0 mrkt-time-block'>
                                    <div className='other-list p-0'>
                                        <FullCalendar
                                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                            initialView="dayGridMonth"
                                            events={tableData} // ✅ Use background events for titles
                                            height="90vh"
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {isMobile &&
                <Footer/>
            }
        </>
    );
};

export default MarketTiming;