import React, {createContext, useContext, useState} from "react";
import {io} from "socket.io-client";

// Socket Context
const SocketContext = createContext();
const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_IO_SERVER_URL; // Replace with your server URL
const SOCKET_SERVER_TOKEN = process.env.REACT_APP_SOCKET_IO_TOKEN; // Replace with your server URL
const authenticated = JSON.parse(
    localStorage.getItem("authenticated")
);

// Socket Provider Component
export const SocketProvider = ({children}) => {
    const [channelData, setChannelData] = useState(null);

    // Initialize the socket connection
    // const socket = io("http://localhost:4000"); // Replace with your server URL
    const socket = io(SOCKET_SERVER_URL, {
        auth: {
            token: SOCKET_SERVER_TOKEN,
        },
    });

    // Subscribe to the "channelData" event (single-time listener)
    socket.once("trade", (data) => {
        // console.log("Received data on channel (once):", data);
        setChannelData(data);
    });
    if (authenticated?.userName) {
        socket.emit('subscribe', authenticated.userName);
    }

    // Disconnect socket when needed
    const disconnectSocket = () => {
        if (socket) socket.disconnect();
    };

    return (
        <SocketContext.Provider value={{socket, channelData, disconnectSocket}}>
            {children}
        </SocketContext.Provider>
    );
};

// Custom Hook to Use Socket
export const useSocket = () => useContext(SocketContext);
