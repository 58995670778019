import React, {useContext, useState, useEffect, useRef} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import {Container, Tab, Tabs, Row, Col, Card, Form} from 'react-bootstrap';
import {useLocation, useNavigate} from 'react-router-dom';
import {formatDate, formatDateForChart} from "../utils/dateUtils";
import {createChart} from 'lightweight-charts';
import {toast, Bounce} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {useMediaQuery} from "react-responsive";
import {WebSocketContext} from '../services/WebSocketContext';
import webSocketManager from '../services/WebSocketManager';
import {useSelector, useDispatch} from "react-redux";
import {addItem, removeItem} from "../services/arraySlice";
import {
    API_ENDPOINT,
    MCX,
    CE_PE,
    BUY,
    SELL,
    MARKET,
    LIMIT,
    PENDING,
    INTRADAY,
    TRADE_CREATE,
    SUCCESS,
    USER_WISE_EXCHANGE_LIST,
    SYMBOL_HISTORICAL_LIST, STOPLOSS,
    HISTORICAL_INTERVAL_ARRAY
} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import {v4 as uuidv4} from 'uuid';
import {toastSuccess, toastError} from "../utils/toastMsg";
// import socket from "../services/socket"; // Import the shared socket instance

const StockDetails = () => {

    const {tickData} = useContext(WebSocketContext);
    const navigate = useNavigate();
    const location = useLocation();
    const passedData = location.state; // Get the passed data
    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    const [symbolData, setSymbolData] = useState({});
    const [pageName, setPageName] = useState('');
    const [socketSymbolData, setSocketSymbolData] = useState({});
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    const array = useSelector((state) => state.array);
    const localArray = [...array]; // Make a local copy of the current state.
    const dispatch = useDispatch();
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const [tradeFormData, setTradeFormData] = useState({});
    const [exchangeData, setExchnageData] = useState({});
    const [storedDateTime, setStoredDateTime] = useState({});
    const [userTabData, setUserTabData] = useState([]);
    const [isDiffGreaterThan40Sec, setIsDiffGreaterThan40Sec] = useState(false);
    const chartContainerRef = useRef(null);
    const seriesRef = useRef(null); // Reference to candlestick series
    const currentMinuteRef = useRef(null); // Track the current minute
    const currentCandleRef = useRef(null); // Track the current candle data
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    // Default is 1 minute
    const [selectedInterval, setSelectedInterval] = useState("minute");
    const [autoCalQty, setAutoCalQty] = useState(0);

    // Function to handle selection change
    const handleChange = (event) => {
        // const newValue = Number(event.target.value);
        const newValue = event.target.value;
        setSelectedInterval(newValue);
    };

    const [selected, setSelected] = useState(MARKET);
    const [orderType, setOrderType] = useState();
    const addSymbolNameInArray = async (symbolName) => {
        if (!localArray.includes(symbolName)) {
            await dispatch(addItem(symbolName));
            localArray.push(symbolName); // Update the local copy manually.
        }
    };
    const handleRadioChange = (e) => {
        setSelected(e.target.value);
    };
    const handleOrderChange = (e) => {
        setOrderType(e.target.value);
    };
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setTradeFormData({
            ...tradeFormData,
            [name]: value
        });
        if(name === 'totalQuantity') {
            if (exchangeData.isTurnoverWise) {
                setAutoCalQty((value/passedData.symbolData.lotSize).toFixed(2))
            }
            if (exchangeData.isSymbolWise) {
                setAutoCalQty((value*passedData.symbolData.lotSize).toFixed(2))
            }
        }
    };
    const clickToBackReturn = () => {
        if (pageName === 'home') {
            const valueToSend = {activeTabId: passedData.activeTabId}; // Data to pass
            navigate('/dashboard', {replace: true, state: valueToSend}); // Navigate to the new page
            // window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
        } else {
            navigate('/position', {replace: true}); // Navigate to the new page
        }
    };
    const socketData = async (symbolData) => {
        await addSymbolNameInArray(symbolData.symbolName)
        webSocketManager.send(localArray);
    };
    const setExchangeData = async (eData) => {
        const exchangeIndex = await eData.findIndex((p) => p.exchangeId === passedData.symbolData.exchangeId);
        if (exchangeIndex > -1) {
            setExchnageData(eData[exchangeIndex])
        }
    };
    const getDeviceInfo = async () => {
        const userAgent = navigator.userAgent;
        let newDeviceType = "desktop";
        let browser = "Unknown";

        // Check if the device is mobile
        if (/Mobi|Android/i.test(userAgent)) {
            newDeviceType = "mobile";
        }

        // Browser detection (simplified)
        if (userAgent.includes("Chrome")) {
            browser = "Chrome";
        } else if (userAgent.includes("Firefox")) {
            browser = "Firefox";
        } else if (userAgent.includes("Safari")) {
            browser = "Safari";
        } else if (userAgent.includes("Edge")) {
            browser = "Edge";
        }
        let newDeviceId = localStorage.getItem('deviceId');
        if (!newDeviceId) {
            newDeviceId = uuidv4();
            localStorage.setItem('deviceId', newDeviceId);
        }
        let deviceType = localStorage.getItem('deviceType');
        if (!deviceType) {
            deviceType = newDeviceType;
            localStorage.setItem('deviceType', deviceType);
        }
        let ip = '';
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            ip = response.data.ip;
            return {deviceType, browser, userAgent, newDeviceId, ip};
        } catch (error) {
            return {deviceType, browser, userAgent, newDeviceId, ip};
        }
    };
    const handleTrade = async (tradeType) => {
        try {
            if (!tradeFormData.totalQuantity || tradeFormData.totalQuantity == 0) {
                toastError("Qty mandatory")
                return;
            }
            if (0 > tradeFormData.totalQuantity) {
                toastError("Qty Require Greater Then 0")
                return;
            }
            if (selected !== MARKET && selected !== PENDING) {
                toastError("Please fill type")
                return;
            }
            if (selected === PENDING) {
                if (orderType !== LIMIT && orderType !== STOPLOSS) {
                    toastError("Please fill type")
                    return;
                }
                if (orderType === LIMIT || orderType === STOPLOSS) {
                    if (!tradeFormData.price || tradeFormData.price == 0) {
                        toastError("Price mandatory")
                        return;
                    }
                    if (0 > tradeFormData.price) {
                        toastError("Price Require Greater Then 0")
                        return;
                    }
                }
            }
            let invalidServerTime = false;
            if (symbolData?.tradeSecond && symbolData.tradeSecond != 0) {
                const currentTime = new Date();
                if (storedDateTime?.dateTime) {
                    const differenceInSeconds =
                        (currentTime.getTime() - storedDateTime.dateTime.getTime()) / 1000;
                    if (differenceInSeconds >= symbolData.tradeSecond) {
                        // toastError("INVALID SERVER TIME")
                        // return;
                        invalidServerTime = true
                    }
                } else {
                    // toastError("INVALID SERVER TIME")
                    // return;
                    invalidServerTime = true
                }
            }
            let fData = {
                quantity: 0,
                totalQuantity: 0,
                price: 0,
                referencePrice: 0,
            }
            if (selected == PENDING) {
                if (orderType === LIMIT) {
                    if (tradeType === SELL) {
                        if (socketSymbolData.bid > tradeFormData.price) {
                            toastError('TRIGGER PRICE SHOULD BE GREATER THAN MARKET PRICE')
                            return;
                        }
                        fData.referencePrice = socketSymbolData.bid;
                    }
                    if (tradeType === BUY) {
                        if (socketSymbolData.ask < tradeFormData.price) {
                            toastError('TRIGGER PRICE SHOULD BE LESS THAN MARKET PRICE')
                            return;
                        }
                        fData.referencePrice = socketSymbolData.ask;
                    }
                }
                if (orderType === STOPLOSS) {
                    if (tradeType === SELL) {
                        if (socketSymbolData.bid < tradeFormData.price) {
                            toastError('TRIGGER PRICE SHOULD BE LESS THAN MARKET PRICE')
                            return;
                        }
                        fData.referencePrice = socketSymbolData.bid;
                    }
                    if (tradeType === BUY) {
                        if (socketSymbolData.ask > tradeFormData.price) {
                            toastError('TRIGGER PRICE SHOULD BE GREATER THAN MARKET PRICE')
                            return;
                        }
                        fData.referencePrice = socketSymbolData.ask;
                    }
                }
                fData.price = tradeFormData.price;
            } else {
                if (tradeType === SELL) {
                    fData.price = socketSymbolData.bid;
                    fData.referencePrice = socketSymbolData.bid;
                }
                if (tradeType === BUY) {
                    fData.price = socketSymbolData.ask;
                    fData.referencePrice = socketSymbolData.ask;
                }
            }
            // if (symbolData.exchangeName === MCX || symbolData.exchangeName === CE_PE) {
            if (exchangeData.isSymbolWise) {
                fData.totalQuantity = tradeFormData.totalQuantity * symbolData.lotSize;
                fData.quantity = fData.totalQuantity / symbolData.lotSize;
            } else {
                fData.quantity = tradeFormData.totalQuantity / symbolData.lotSize;
                fData.totalQuantity = tradeFormData.totalQuantity;
            }
            if (symbolData.oddLotTrade != 1) {
                if (!Number.isInteger(fData.quantity)) {
                    toastError("Invalid Qty")
                    return;
                }
            }
            const {deviceType, browser, userAgent, newDeviceId, ip} = await getDeviceInfo();
            let data = encryptData({
                "userId": authenticated.userId,
                "parentId": authenticated.parentId,
                "symbolId": symbolData.symbolId,
                "quantity": parseFloat(fData.quantity),
                "totalQuantity": parseFloat(fData.totalQuantity),
                "price": parseFloat(fData.price),
                "referencePrice": parseFloat(fData.referencePrice),
                "lotSize": parseFloat(symbolData.lotSize),
                "tradeType": tradeType,
                "exchangeId": symbolData.exchangeId,
                "orderType": (selected === MARKET) ? MARKET : orderType,
                "productType": INTRADAY,
                "ipAddress": ip,
                "deviceId": newDeviceId,
                "userAgent": userAgent,
                "browser": browser,
                "deviceType": deviceType,
                "invalidServerTime": invalidServerTime
            });
            data = JSON.stringify({data: data});
            axios
                .post(API_ENDPOINT + TRADE_CREATE, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        setTradeFormData({
                            ...tradeFormData,
                            ['totalQuantity']: '',
                            ['price']: ''
                        });
                        setSymbolData({
                            ...symbolData,
                            ['positionData']: resData.positionData
                        });
                        symbolData.positionData = resData.positionData;
                        navigate('.', {state: {symbolData: symbolData, activeTabId: passedData.activeTabId}});
                        toastSuccess(response.data.meta.message)
                    } else {
                        toastError(response.data.message)
                        // console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                    // console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const handleGetGroupList = async () => {
        let data = encryptData({
            "page": 1,
            "limit": 100,
            "search": "",
            "sortKey": "createdAt",
            "sortBy": -1,
            "groupId": "",
            "userId": authenticated.userId
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + USER_WISE_EXCHANGE_LIST, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                        if (response.data.statusCode == SUCCESS) {
                            const resData = decryptData(response.data.data)
                            setExchangeData(resData)
                        } else {
                            // toastError(response.data.message)
                            console.error("Login error:", response.data.message); // Handle error
                        }
                    }
                )
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch
            (error) {
        }
    };
    const updateCurrentCandle = (liveItem, selectedInterval) => {
        const selectedIntervalValue =
            HISTORICAL_INTERVAL_ARRAY.find((item) => item.id === selectedInterval)?.value || 1;
        // const time = new Date(liveItem.ltt).getTime() / 1000; // Convert to seconds
        let time;
        if (!isNaN(liveItem.ltt) && liveItem.ltt.length === 10) {
            // liveItem.ltt is already a Unix timestamp in seconds
            time = parseInt(liveItem.ltt, 10); // Convert the string to an integer if it's not already
            // const date = new Date(timea * 1000); // Convert from seconds to milliseconds
            // // console.log(date.toISOString())
            // time = formatDateForChart(date.toISOString());
            // time = new Date(date.toISOString()).getTime() / 1000;
        } else {
            // liveItem.ltt is a date string, convert to Unix timestamp in seconds
            time = new Date(liveItem.ltt).getTime() / 1000;
        }
        // const minute = Math.floor(time / 60); // Calculate the current minute
        const minute = Math.floor(time / (60 * selectedIntervalValue));
        // return
        if (currentMinuteRef.current !== minute) {
            // New minute: Create a new candle
            if (currentCandleRef.current) {
                seriesRef.current.update(currentCandleRef.current);
            }
            currentMinuteRef.current = minute;
            currentCandleRef.current = {
                time, // Start time of the new candle
                open: liveItem.ltp,
                high: liveItem.ltp,
                low: liveItem.ltp,
                close: liveItem.ltp,
            };
        } else {
            // Update the current candle
            currentCandleRef.current = {
                ...currentCandleRef.current,
                high: Math.max(currentCandleRef.current.high, liveItem.ltp),
                low: Math.min(currentCandleRef.current.low, liveItem.ltp),
                close: liveItem.ltp,
            };
            // Update the ongoing candle
            seriesRef.current.update(currentCandleRef.current);
        }
    };
    const handleGetSymbolHistoricalList = async () => {
        let data = encryptData({
            "symbolId": passedData.symbolData.symbolId,
            // "interval": "minute"
            interval: selectedInterval, // Use the selected interval
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + SYMBOL_HISTORICAL_LIST, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                        if (response.data.statusCode == '200') {
                            const resData = decryptData(response.data.data)
                            // console.log(resData)
                            // setExchangeData(resData)

                            const newDataForSymbol = resData.map(symbolItem => {
                                let time = new Date(symbolItem.Date).getTime() / 1000;
                                symbolItem.time = time
                                symbolItem.open = symbolItem.Open
                                symbolItem.high = symbolItem.High
                                symbolItem.low = symbolItem.Low
                                symbolItem.close = symbolItem.Close
                                return symbolItem;
                            });
                            setUserTabData(newDataForSymbol)
                            // console.log(newDataForSymbol)
                        } else {
                            // toastError(response.data.message)
                            console.error("Login error:", response.data.message); // Handle error
                        }
                    }
                )
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch
            (error) {
        }
    };

    useEffect(() => {
        handleGetSymbolHistoricalList();
    }, [selectedInterval]); // Fetch data when interval changes

    useEffect(() => {
        if (tickData?.data) {
            if (symbolData.symbolName === tickData.data.symbol) {
                updateCurrentCandle(tickData.data, selectedInterval)
                /*socketSymbolData.lask = socketSymbolData.ask
                socketSymbolData.lbid = socketSymbolData.bid
                socketSymbolData.lltp = socketSymbolData.ltp*/
                let askClass = (parseFloat(socketSymbolData.ask) > parseFloat(tickData.data.ask)) ? 'l-bg' : ''
                let bidClass = (parseFloat(socketSymbolData.bid) > parseFloat(tickData.data.bid)) ? 'l-bg' : ''
                /*socketSymbolData.askColorClass = (parseFloat(socketSymbolData.ask) < parseFloat(tickData.data.ask)) ? 'h-bg' : askClass
                socketSymbolData.bidColorClass = (parseFloat(socketSymbolData.bid) < parseFloat(tickData.data.bid)) ? 'h-bg' : bidClass
                socketSymbolData.ltp = tickData.data.ltp
                socketSymbolData.ask = tickData.data.ask
                socketSymbolData.bid = tickData.data.bid
                socketSymbolData.ch = tickData.data.ch
                socketSymbolData.chp = tickData.data.chp
                socketSymbolData.close = tickData.data.close
                socketSymbolData.high = tickData.data.high
                socketSymbolData.lc = tickData.data.lc
                socketSymbolData.uc = tickData.data.uc
                socketSymbolData.low = tickData.data.low
                socketSymbolData.ls = tickData.data.ls
                socketSymbolData.ltt = tickData.data.ltt
                socketSymbolData.open = tickData.data.open
                socketSymbolData.ts = tickData.data.ts
                socketSymbolData.tsq = tickData.data.tsq
                socketSymbolData.volume = tickData.data.volume*/
                setSocketSymbolData({
                    ...socketSymbolData,
                    ['lask']: socketSymbolData.ask,
                    ['lbid']: socketSymbolData.bid,
                    ['lltp']: socketSymbolData.ltp,
                    ['askColorClass']: (parseFloat(socketSymbolData.ask) < parseFloat(tickData.data.ask)) ? 'h-bg' : askClass,
                    ['bidColorClass']: (parseFloat(socketSymbolData.bid) < parseFloat(tickData.data.bid)) ? 'h-bg' : bidClass,
                    ['ltp']: tickData.data.ltp,
                    ['ask']: tickData.data.ask,
                    ['bid']: tickData.data.bid,
                    ['ch']: tickData.data.ch,
                    ['chp']: tickData.data.chp,
                    ['close']: tickData.data.close,
                    ['high']: tickData.data.high,
                    ['lc']: tickData.data.lc,
                    ['uc']: tickData.data.uc,
                    ['low']: tickData.data.low,
                    ['ls']: tickData.data.ls,
                    ['ltt']: tickData.data.ltt,
                    ['open']: tickData.data.open,
                    ['ts']: tickData.data.ts,
                    ['tsq']: tickData.data.tsq,
                    ['volume']: tickData.data.volume
                });
                const currentTime = new Date();
                if (storedDateTime) {
                    if (storedDateTime.symbol === tickData.data.symbol) {
                        if (storedDateTime.ltp != tickData.data.ltp) {
                            setStoredDateTime({
                                'symbol': tickData.data.symbol,
                                'ltp': tickData.data.ltp,
                                'dateTime': currentTime
                            })
                        }
                    } else {
                        setStoredDateTime({
                            'symbol': tickData.data.symbol,
                            'ltp': tickData.data.ltp,
                            'dateTime': currentTime
                        })
                    }
                } else {
                    setStoredDateTime({
                        'symbol': tickData.data.symbol,
                        'ltp': tickData.data.ltp,
                        'dateTime': currentTime
                    })
                }
                setSymbolData({
                    ...symbolData,
                    ['lask']: socketSymbolData.ask,
                    ['lbid']: socketSymbolData.bid,
                    ['lltp']: socketSymbolData.ltp,
                    ['askColorClass']: (parseFloat(socketSymbolData.ask) < parseFloat(tickData.data.ask)) ? 'h-bg' : askClass,
                    ['bidColorClass']: (parseFloat(socketSymbolData.bid) < parseFloat(tickData.data.bid)) ? 'h-bg' : bidClass,
                    ['ltp']: tickData.data.ltp,
                    ['ask']: tickData.data.ask,
                    ['bid']: tickData.data.bid,
                    ['ch']: tickData.data.ch,
                    ['chp']: tickData.data.chp,
                    ['close']: tickData.data.close,
                    ['high']: tickData.data.high,
                    ['lc']: tickData.data.lc,
                    ['uc']: tickData.data.uc,
                    ['low']: tickData.data.low,
                    ['ls']: tickData.data.ls,
                    ['ltt']: tickData.data.ltt,
                    ['open']: tickData.data.open,
                    ['ts']: tickData.data.ts,
                    ['tsq']: tickData.data.tsq,
                    ['volume']: tickData.data.volume
                });
                navigate('.', {state: {symbolData: symbolData, activeTabId: passedData.activeTabId}});
            }
            // setSocketSymbolData(symbolData)
        }
        // console.log(tickData?.data)
    }, [tickData, selectedInterval]);

    useEffect(() => {
        handleGetGroupList()
        if (passedData?.pageName && passedData?.symbolData) {
            setSymbolData(passedData.symbolData)
            setSocketSymbolData(passedData.symbolData)
            socketData(passedData.symbolData)
            setPageName(passedData.pageName)
            handleGetSymbolHistoricalList(passedData.symbolData)
            if (passedData.pageName === 'home' && !passedData.activeTabId) {
                navigate('/dashboard'); // Navigate to the new page
            }
        } else {
            navigate('/dashboard'); // Navigate to the new page
        }
    }, []);

    useEffect(() => {
        // Initialize the chart
        const chart = createChart(chartContainerRef.current, {
            layout: {
                textColor: 'black',
                background: {type: 'solid', color: 'white'},
                attributionLogo: false, // Disable the TradingView logo
            },
            height: 600,
            width: chartContainerRef.current.clientWidth,
            timeScale: {
                timeVisible: true,
                secondsVisible: false,
                tickMarkFormatter: (time) => {
                    const date = new Date(time * 1000);
                    const hours = date.getHours().toString().padStart(2, '0');
                    const minutes = date.getMinutes().toString().padStart(2, '0');
                    return `${hours}:${minutes}`;
                },
                ticksVisible: true
            },
            priceScale: {
                borderColor: 'black',
                autoScale: true,
            },
            watermark: {
                visible: true,            // Show watermark
                text: 'Chart',  // Watermark text
                fontSize: 48,            // Font size of the watermark
                color: 'rgba(0, 0, 0, 0.1)', // Watermark color (with opacity)
                opacity: 0.1,            // Transparency level
                position: 'bottom-right', // Position of the watermark (other options: 'top-left', 'top-right', 'bottom-left', etc.)
            },
        });

        // Add a candlestick series
        const series = chart.addCandlestickSeries({
            upColor: '#26a69a',
            downColor: '#ef5350',
            borderVisible: false,
            wickUpColor: '#26a69a',
            wickDownColor: '#ef5350',
        });
        seriesRef.current = series;

        // Optional: Set historical data if available
        const initialData = userTabData;
        series.setData(initialData);

        // Cleanup on unmount
        return () => {
            chart.remove();
        };
    }, [userTabData]);
    return (
        <>
            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">
                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn" onClick={clickToBackReturn}>
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>
                    <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                        <h5 className="head-numbers mb-0">Stock Details</h5>
                    </div>
                </div>
            </div>
            {isDesktop &&
                <LeftSidebar/>
            }
            <div className="main-container trans">
                <div className="center-main-content">
                    <div className="center-container trans">
                        <div className="home-container">
                            <div className="stock-details">
                                <div className="stock-header d-flex justify-content-between align-items-center">
                                    <div className="left-h d-flex align-items-center">
                                        <div className="icon-circle">
                                            <span>{(symbolData.symbolTitle) ? symbolData.symbolTitle.charAt(0) : ''}</span>
                                        </div>
                                        <div className="user-h ml-2">
                                            <h6 className="mb-0">
                                                {symbolData?.exchangeName?.toLowerCase() && symbolData.exchangeName.toLowerCase() === 'usstock' ? symbolData.symbolName : symbolData.symbolTitle}
                                            </h6>
                                            <small>{formatDate(symbolData.expiryDate)}</small>
                                        </div>
                                    </div>
                                    <div className="right-h text-right">
                                        <h6 className="mb-0 text-danger">{socketSymbolData.ltp}</h6>
                                        {socketSymbolData.ch < 0 ?
                                            <small className="text-danger"><i
                                                className='fa fa-arrow-circle-down'></i> {socketSymbolData.ch} ({socketSymbolData.chp}%)</small>
                                            : <small className="text-primary"><i
                                                className='fa fa-arrow-circle-up'></i> {socketSymbolData.ch} ({socketSymbolData.chp}%)</small>}
                                    </div>
                                </div>


                                <div className='profile-card pt-0 pb-0'>
                                    <Row className="value-stock-btns profile-box-main row-space-10 text-center">

                                        <Col xs={6} md={6} lg={6} className='items'>
                                            <div className='profile-card-inn text-center h-100'>
                                                <div className='value-stock'>
                                                    <strong className="stk-lab b-clr mb-1 text-white">LOW</strong>
                                                    <h5 className="text-white mb-0">{socketSymbolData.low}</h5>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={6} md={6} lg={6} className='items'>
                                            <div className='profile-card-inn text-center h-100'>
                                                <div className='value-stock'>
                                                    <strong className="stk-lab b-clr mb-1 text-white">HIGH</strong>
                                                    <h5 className="text-white mb-0">{socketSymbolData.high}</h5>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={6} md={6} lg={6} className='items'>
                                            <div className='profile-card-inn text-center h-100'>
                                                <div className="radio-opt-btnbox">
                                                    <label className={selected === MARKET ? 'active' : ''} htmlFor="market">
                                                        <input id="market" type="radio" name="mkt_opt" value={MARKET}
                                                               onChange={handleRadioChange}/>
                                                        <button className="btn white-bdr small">MARKET</button>
                                                    </label>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={6} md={6} lg={6} className='items'>
                                            <div className='profile-card-inn text-center h-100'>
                                                <div className="radio-opt-btnbox">
                                                    <label className={selected === PENDING ? 'active' : ''}
                                                           htmlFor="pending">
                                                        <input id="pending" type="radio" name="mkt_opt" value={PENDING}
                                                               onChange={handleRadioChange}/>
                                                        <button className="btn white-bdr small">PENDING</button>
                                                    </label>
                                                </div>
                                                {selected === PENDING ?
                                                    <>
                                                    <div className='pending_option_box d-flex ptb-10 pb-0'>
                                                        <label className='radiobox'>
                                                            <input
                                                                type="radio"
                                                                name="orderType"
                                                                value={LIMIT}
                                                                checked={orderType === LIMIT}
                                                                onChange={(e) => handleOrderChange(e)}
                                                            />
                                                            <span className='checkmark'></span>
                                                            <span className='text-white'>Limit</span>
                                                        </label>
                                                        <label className='radiobox'>
                                                            <input
                                                                type="radio"
                                                                name="orderType"
                                                                value={STOPLOSS}
                                                                checked={orderType === STOPLOSS}
                                                                onChange={(e) => handleOrderChange(e)}
                                                            />
                                                            <span className='checkmark'></span>
                                                            <span className='text-white'>StopLoss</span>
                                                        </label>
                                                    </div>
                                                    </>
                                                    : ''}
                                            </div>
                                        </Col>

                                    </Row>
                                </div>

                                <div className="bdr-card">
                                    <p className="form-label">{exchangeData.isSymbolWise ? "Qty" : "Lot"}: {autoCalQty ?? 0}</p>
                                    <div className="qty-text-block mt-2">
                                        <label className="stk-qty-field w-100" htmlFor="qty">
                                            <span
                                                className="form-label">{exchangeData.isSymbolWise ? "Lot" : "Qty"}</span>
                                            <input className="form-control w-100" id="totalQuantity"
                                                   name="totalQuantity"
                                                   type="number"
                                                   min={0}
                                                   value={tradeFormData.totalQuantity}
                                                   placeholder={exchangeData.isSymbolWise ? "Enter Lot" : "Enter Qty"}
                                                   onChange={handleInputChange}/>
                                        </label>
                                    </div>

                                    {selected === PENDING && (
                                        <div className="qty-text-block">
                                            <label className="stk-qty-field w-100" htmlFor="qty">
                                                <span className="form-label">At Price</span>
                                                <input className="form-control w-100" id="price" name="price"
                                                       value={tradeFormData.price} type="number"
                                                       min={0}
                                                       placeholder="Enter At Price" onChange={handleInputChange}/>
                                            </label>
                                        </div>
                                    )}
                                </div>

                                <div className="bdr-card">
                                    <Row className="stock-buysell-btns psp-10 text-center bdr-top mt-3">
                                        <Col xs={6} md={6} lg={6} className='items'>
                                            <button className="btn red-btn mw-142"
                                                    onClick={() => handleTrade(SELL)}> SELL <br/> {socketSymbolData.bid}
                                            </button>
                                        </Col>
                                        <Col xs={6} md={6} lg={6} className='items'>
                                            <button className="btn primary mw-142"
                                                    onClick={() => handleTrade(BUY)}> BUY <br/> {socketSymbolData.ask}
                                            </button>
                                        </Col>
                                    </Row>
                                    <Row className="stock-list psp-10 text-center bdr-top">
                                        <Col xs={4} md={4} lg={4} className='sub-items'>
                                            <p className="mb-1">LOT SIZE</p>
                                            <h6>{symbolData.lotSize}</h6>
                                        </Col>
                                        <Col xs={4} md={4} lg={4} className='sub-items'>
                                            <p className="mb-1">Qty/Odrer</p>
                                            <h6>{exchangeData.isSymbolWise ? symbolData.breakUpLot : symbolData.breakUpQuantity}</h6>
                                        </Col>
                                        <Col xs={4} md={4} lg={4} className='sub-items'>
                                            <p className="mb-1">TOTAL QTY</p>
                                            <h6>{exchangeData.isSymbolWise ? symbolData.lotMax : symbolData.quantityMax}</h6>
                                        </Col>
                                    </Row>
                                    <Row className="stock-list psp-10 text-center bdr-top">
                                        <Col xs={4} md={4} lg={4} className='sub-items'>
                                            <p className="mb-1">Exchange</p>
                                            <h6>{symbolData.exchangeName}</h6>
                                        </Col>
                                        <Col xs={4} md={4} lg={4} className='sub-items'>
                                            <p className="mb-1">Trade Attribute</p>
                                            <h6>{symbolData.tradeAttribute}</h6>
                                        </Col>
                                        <Col xs={4} md={4} lg={4} className='sub-items'>
                                            <p className="mb-1">Odd Lot Trade</p>
                                            <h6>{symbolData.oddLotTradeValue}</h6>
                                        </Col>
                                    </Row>
                                    <Row className="stock-list psp-10 text-center bdr-top">
                                        <Col xs={4} md={4} lg={4} className='sub-items'>
                                            <p className="mb-1">Open</p>
                                            <h6>{symbolData.open}</h6>
                                        </Col>
                                        <Col xs={4} md={4} lg={4} className='sub-items'>
                                            <p className="mb-1">Close</p>
                                            <h6>{symbolData.close}</h6>
                                        </Col>
                                    </Row>
                                    {symbolData?.positionData?.totalQuantity ?
                                        <Row className="stock-list psp-10 text-center bdr-top">
                                            <Col xs={12} md={12} lg={12} className='sub-items'>
                                                <p className="mb-1">Position</p>
                                                {symbolData.positionData.tradeType === BUY ?
                                                    <h6 className="text-primary">{exchangeData.isSymbolWise ? symbolData.positionData.quantity : symbolData.positionData.totalQuantity}</h6>
                                                    :
                                                    <h6 className="text-danger">-{exchangeData.isSymbolWise ? symbolData.positionData.quantity : symbolData.positionData.totalQuantity}</h6>}
                                            </Col>
                                        </Row>
                                        : ''
                                    }
                                </div>

                                <div className="bdr-card">

                                    <div className="interval-div">
                                        <label>Select Interval: </label>
                                        <select onChange={handleChange} value={selectedInterval}>
                                            {HISTORICAL_INTERVAL_ARRAY.map((interval) => (
                                                <option key={interval.id} value={interval.id}>
                                                    {interval.name}
                                                </option>
                                            ))}
                                        </select>

                                        <p className="selected-interval-div">Current Interval: {HISTORICAL_INTERVAL_ARRAY.find((item) => item.id === selectedInterval)?.name || "Minute"}</p>
                                    </div>

                                    <Row className="stock-buysell-btns psp-10 text-center bdr-top mt-3">
                                        <div style={{width: '100%', padding: '10px', boxSizing: 'border-box'}}>
                                            <div
                                                ref={chartContainerRef}
                                                style={{
                                                    height: '600px',
                                                    width: '100%',
                                                    border: '1px solid #ddd',
                                                    borderRadius: '8px',
                                                    backgroundColor: '#fff',
                                                }}
                                            />
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isMobile &&
                <Footer/>
            }
        </>
    );
};

export default StockDetails;