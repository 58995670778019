import React, { createContext, useEffect, useState } from 'react';
import webSocketManager from './WebSocketManager';

export const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [tickData, setTickData] = useState(null);

  useEffect(() => {
    const handleNewTick = (data) => {
      setTickData(data);
    };

    webSocketManager.subscribe(handleNewTick);

    return () => {
      webSocketManager.unsubscribe(handleNewTick);
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ tickData }}>
      {children}
    </WebSocketContext.Provider>
  );
};
