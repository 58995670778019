import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import axios from "axios";
import {
    API_ENDPOINT, SUCCESS, REJECT_TRADE_LIST, BUY
} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import {formatDateForTradeList} from "../utils/dateUtils";
import {useMediaQuery} from "react-responsive";

const Rejection = () => {
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    const [tradeData, setTradeData] = useState([]);
    const navigate = useNavigate();

    const handleGetRejectedTradeList = async () => {
        let data = encryptData({
            "search": "",
            "userId": "",
            "symbolId": "",
            "exchangeId": "",
            "status": "",
            "startDate": "",
            "endDate": "",
            "page": 1,
            "limit": 1000,
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + REJECT_TRADE_LIST, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                        if (response.data.statusCode == SUCCESS) {
                            const resData = decryptData(response.data.data)
                            setTradeData(resData)
                        } else {
                            // toastError(response.data.message)
                            console.error("Login error:", response.data.message); // Handle error
                        }
                    }
                )
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch
            (error) {
        }
    };
    const clickToBackReturn = () => {
        navigate('/profile', { replace: true }); // Navigate to the new page
    };
    useEffect(() => {
        handleGetRejectedTradeList()
    }, []);

    return (
        <>

            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">

                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn" onClick={clickToBackReturn}>
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>

                    <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                        <h5 className="head-numbers mb-0">Rejection Logs</h5>
                    </div>

                </div>
            </div>

            {isDesktop &&
                <LeftSidebar/>
            }

            <div className="main-container trans">
                <div className="center-main-content">
                    <div className="center-container trans">
                        <div className="home-container">

                            <div className="account-page p-0">
                                <div className='rejection-log-list bdr-card'>

                                    {tradeData.map((item, index) => (
                                        <div className='search-list-items bdr-top'>
                                            <div
                                                className="search-inn-header d-flex justify-content-between align-items-center">
                                                <div className="left-h d-flex align-items-center">
                                                    {item.tradeType === BUY ?
                                                        <div className="icon-circle-bdr text-primary">
                                                            <span>Buy</span>
                                                        </div>
                                                        : <div className="icon-circle-bdr text-danger">
                                                            <span>SELL</span>
                                                        </div>}
                                                    <div className="user-h ml-2">
                                                        <h6 className="mb-0">{item.symbolTitle}</h6>
                                                        <small className='list-date'>{item.comment}</small>
                                                    </div>
                                                </div>
                                                <div className="right-h text-right">
                                                    {item.tradeType === BUY ?
                                                        <h4 className="mb-0 text-primary">{item.price}</h4>
                                                        :
                                                        <h4 className="mb-0 text-danger">{item.price}</h4>}
                                                    <small
                                                        className='list-date'>{formatDateForTradeList(item.createdAt)}</small>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {isMobile &&
                <Footer/>
            }
        </>
    );
};

export default Rejection;