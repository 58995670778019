import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import AppMenu from '../components/AppMenu';
import {useLocation, useNavigate} from 'react-router-dom';

function LeftSidebar() {

    const [menuActive, setMenuActive] = useState(false);
    const [userName, setUserName] = useState('');
    const navigate = useNavigate();
    const toggleMenu = () => {
        setMenuActive(!menuActive);
        document.body.classList.toggle('menu-active');
    };
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));

    useEffect(() => {
        if (authenticated) {
            setUserName(authenticated.userName)
        } else {
            navigate('/login'); // Navigate to the new page
        }
    }, [authenticated]);
    return (
        <>

        <div className='head-sitename-block bg2 d-flex v-center fixed-top'>
            <div className="mobi-iconbox mobilemenuicon" onClick={toggleMenu}>
                <div className="menuicon d-flex">
                <i className="icon-bar top-icon-bar">
                <span className="line"></span> 
                </i>
                </div>
            </div>
            <h4 className="bold-tl">{userName}</h4>
        </div>

        <div className="sidebar-left bg-white trans">
            <div className="sidebar-menu menu-box">

                <AppMenu/>
                
            </div>
        </div>
        </>
    );
}

export default LeftSidebar;