import React, {useState, useContext, useEffect} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import {Container, Tab, Tabs, Row, Col, Card, Form} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import {
    API_ENDPOINT,
    POSITION_LIST,
    SUCCESS,
    EXECUTED,
    BUY,
    USER_WISE_EXCHANGE_LIST,
    SELL,
    TRADE_SQ_OFF_POSITION,
    TRADE_CARRYFORWARD_POSITION,
    LONGTERM,
    INTRADAY
} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import {formatCurrency} from "../utils/Utils";
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import {formatSymbolExpiryDate, formatDateForTradeList} from "../utils/dateUtils";
import {WebSocketContext} from '../services/WebSocketContext';
import webSocketManager from '../services/WebSocketManager';
import {useSelector, useDispatch} from "react-redux";
import {addItem, removeItem} from "../services/arraySlice";
import {v4 as uuidv4} from 'uuid';
// import {initiateSocketConnection, disconnectSocket, getSocket} from "../services/socket";
import {useSocket} from "../services/SocketContext";
import {toastSuccess, toastError} from "../utils/toastMsg";

const Position = () => {
    const {channelData, socket, disconnectSocket} = useSocket();
    const {tickData} = useContext(WebSocketContext);
    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    let authenticated = JSON.parse(
        localStorage.getItem("authenticated")
    );
    const [tradeMarginBalance, setTradeMarginBalance] = useState(authenticated.tradeMarginBalance);
    const array = useSelector((state) => state.array);
    const localArray = [...array]; // Make a local copy of the current state.
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 500; // Number of items to display per page
    const [netplTotal, setNetplTotal] = useState(0)
    const [carryflMarginTotal, setCarryflMarginTotal] = useState(0)
    const [exchangeData, setExchnageData] = useState([]);
    const [sqPostionId, setSqPostionId] = useState([]);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        'quantity': 0,
        'totalQuantity': 0
    });

    const [isVisible, setIsVisible] = useState(false);
    const toggleBox = () => {
        setIsVisible(!isVisible);
    };
    const addSymbolNameInArray = async (symbolName) => {
        if (!localArray.includes(symbolName)) {
            await dispatch(addItem(symbolName));
            localArray.push(symbolName); // Update the local copy manually.
        }
    };
    const socketData = async (symbolData) => {
        for (var i = 0; i < symbolData.length; i++) {
            await addSymbolNameInArray(symbolData[i].symbolName)
        }
        webSocketManager.send(localArray);
    };
    const updatePsdData = async (rdata) => {
        let netpl = 0;
        let carryflMargin = 0;
        const newData = rdata.map((userItem) => {
            /*const exchangeIndex = resData.findIndex((p) => p.exchangeId === userItem.exchangeId);
            if (exchangeIndex > -1) {
                userItem.isSymbolWise = resData[exchangeIndex].isSymbolWise
                userItem.isTurnoverWise = resData[exchangeIndex].isTurnoverWise
            }*/
            if (userItem.tradeType === SELL && userItem.totalQuantity > 0) {
                userItem.m2m = (userItem.ask - userItem.price.toFixed(2)) * userItem.totalQuantity
            } else {
                if (userItem.totalQuantity < 0) {
                    userItem.m2m = (userItem.ask - userItem.price.toFixed(2)) * userItem.totalQuantity
                } else {
                    userItem.m2m = (userItem.bid - userItem.price.toFixed(2)) * userItem.totalQuantity
                }
            }
            if (userItem.productType === LONGTERM) {
                carryflMargin = carryflMargin + userItem.tradeMarginTotal;
            }
            let m2mPln = userItem.m2m;
            netpl = netpl + m2mPln
            return {
                ...userItem,
                ['m2mTotal']: m2mPln.toFixed(2)
            };
            return userItem;
        });
        setCarryflMarginTotal(carryflMargin.toFixed(2));
        setNetplTotal(netpl.toFixed(2))
        setTableData(newData);
        socketData(newData);
    };
    const handleQROff = async () => {
        try {
            if (sqPostionId.length == 0) {
                toastError('Please Select Position')
                return true
            }
            if (window.confirm("ARE YOU SURE YOU WANT TO SQ OFF ALL POSITION?")) {
                if (tableData.length != 0) {
                    let symbolData = [];
                    /*if (tradeType === SELL) {
                        fData.price = socketSymbolData.bid;
                        fData.referencePrice = socketSymbolData.bid;
                    }
                    if (tradeType === BUY) {
                        fData.price = socketSymbolData.ask;
                        fData.referencePrice = socketSymbolData.ask;
                    }*/
                    for (var i = 0; i < tableData.length; i++) {
                        if (sqPostionId.includes(tableData[i].positionId)) {
                            if (tableData[i].ask === 0 || tableData[i].bid === 0) {
                                toastError("Position Price is 0")
                                return;
                            }
                            symbolData.push({
                                "symbolId": tableData[i].symbolId,
                                "exchangeId": tableData[i].exchangeId,
                                "price": (tableData[i].tradeType === BUY) ? tableData[i].ask : tableData[i].bid,
                            })
                        }
                    }
                    const {deviceType, browser, userAgent, newDeviceId, ip} = await getDeviceInfo();
                    let data = encryptData({
                        "userId": authenticated.userId,
                        "ipAddress": ip,
                        "deviceId": newDeviceId,
                        "userAgent": userAgent,
                        "browser": browser,
                        "deviceType": deviceType,
                        "symbolData": symbolData
                    });
                    data = JSON.stringify({data: data});
                    axios
                        .post(API_ENDPOINT + TRADE_SQ_OFF_POSITION, data, {
                            headers: {
                                'Authorization': jwt_token,
                                'Content-Type': 'application/json',
                                "deviceType": deviceType
                            },
                        })
                        .then((response) => {
                            if (response.data.statusCode == SUCCESS) {
                                const resData = decryptData(response.data.data)
                                fetchDataFromAPI()
                                toastSuccess(response.data.meta.message)
                            } else {
                                toastError(response.data.message)
                                // console.error("Login error:", response.data.message); // Handle error
                            }
                        })
                        .catch((error) => {
                            toastError(error.response.data.message)
                            // console.error("Login error:", error); // Handle error
                        });
                } else {
                    toastError("POSITION ARE NOT AVAILABLE")
                    return;
                }
            }
        } catch (error) {
        }
    };
    const fetchDataFromAPI = async () => {
        try {
            let data = encryptData({
                "search": "",
                "userId": '',
                "symbolId": '',
                "exchangeId": '',
                "status": "",
                "page": currentPage,
                "limit": itemsPerPage,
            });
            data = JSON.stringify({data: data});
            axios
                .post(API_ENDPOINT + POSITION_LIST, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const rdata = decryptData(response.data.data)
                        updatePsdData(rdata);
                        setTotalPages(response.data.meta.totalPage);
                    } else {
                        toastError(response.data.message)
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleCheckboxChange = async (posiData) => {
        try {
            let msg = (posiData.productType === LONGTERM) ? 'ARE YOU SURE YOU WANT TO CANCEL CARRYFORWARD?' : 'ARE YOU SURE YOU WANT TO CARRYFORWARD?';
            if (window.confirm(msg)) {
                if (tableData.length != 0) {
                    const {deviceType, browser, userAgent, newDeviceId, ip} = await getDeviceInfo();
                    let data = encryptData({
                        "userId": authenticated.userId,
                        "exchangeId": posiData.exchangeId,
                        "symbolId": posiData.symbolId,
                        "ipAddress": ip,
                        "deviceId": newDeviceId,
                        "userAgent": userAgent,
                        "browser": browser,
                        "deviceType": deviceType,
                    });
                    data = JSON.stringify({data: data});
                    axios
                        .post(API_ENDPOINT + TRADE_CARRYFORWARD_POSITION, data, {
                            headers: {
                                'Authorization': jwt_token,
                                'Content-Type': 'application/json',
                                "deviceType": deviceType
                            },
                        })
                        .then((response) => {
                            if (response.data.statusCode == SUCCESS) {
                                const resData = decryptData(response.data.data)
                                let carryflMargin = 0;
                                const newData = tableData.map((userItem) => {
                                    if (resData._id === userItem.positionId) {
                                        userItem.productType = resData.productType
                                        userItem.tradeMarginTotal = resData.tradeMarginTotal
                                    }
                                    if (userItem.productType === LONGTERM) {
                                        carryflMargin = carryflMargin + userItem.tradeMarginTotal
                                    }
                                    return userItem;
                                });
                                setCarryflMarginTotal(carryflMargin.toFixed(2));
                                setTableData(newData);
                                toastSuccess(response.data.meta.message)
                            } else {
                                toastError(response.data.message)
                                // console.error("Login error:", response.data.message); // Handle error
                            }
                        })
                        .catch((error) => {
                            toastError(error.response.data.message)
                            // console.error("Login error:", error); // Handle error
                        });
                } else {
                    toastError("POSITION ARE NOT AVAILABLE")
                    return;
                }
            }
        } catch (error) {
        }
    };
    const handleSQOffCheckboxChange = async (event) => {
        const {value, checked} = event.target;
        if (checked) {
            // Add the value to the array
            setSqPostionId((prev) => [...prev, value]);
        } else {
            // Remove the value from the array
            setSqPostionId((prev) => prev.filter((item) => item !== value));
        }

    };
    const handleGetGroupList = async () => {
        let data = encryptData({
            "page": 1,
            "limit": 100,
            "search": "",
            "sortKey": "createdAt",
            "sortBy": -1,
            "groupId": "",
            "userId": authenticated.userId
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + USER_WISE_EXCHANGE_LIST, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                        if (response.data.statusCode == SUCCESS) {
                            const resData = decryptData(response.data.data)
                            setExchnageData(resData)
                            fetchDataFromAPI();
                        } else {
                            // toastError(response.data.message)
                            // console.error("Login error:", response.data.message); // Handle error
                        }
                    }
                )
                .catch((error) => {
                    // toastError(error.response.data.message)
                    // console.error("Login error:", error); // Handle error
                });
        } catch
            (error) {
        }
    };
    const getDeviceInfo = async () => {
        const userAgent = navigator.userAgent;
        let newDeviceType = "desktop";
        let browser = "Unknown";

        // Check if the device is mobile
        if (/Mobi|Android/i.test(userAgent)) {
            newDeviceType = "mobile";
        }

        // Browser detection (simplified)
        if (userAgent.includes("Chrome")) {
            browser = "Chrome";
        } else if (userAgent.includes("Firefox")) {
            browser = "Firefox";
        } else if (userAgent.includes("Safari")) {
            browser = "Safari";
        } else if (userAgent.includes("Edge")) {
            browser = "Edge";
        }
        let newDeviceId = localStorage.getItem('deviceId');
        if (!newDeviceId) {
            newDeviceId = uuidv4();
            localStorage.setItem('deviceId', newDeviceId);
        }
        let deviceType = localStorage.getItem('deviceType');
        if (!deviceType) {
            deviceType = newDeviceType;
            localStorage.setItem('deviceType', deviceType);
        }
        let ip = '';
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            ip = response.data.ip;
            return {deviceType, browser, userAgent, newDeviceId, ip};
        } catch (error) {
            return {deviceType, browser, userAgent, newDeviceId, ip};
        }
    };
    const handleSymbolDetailView = async (item) => {
        item.positionData = (item?.positionData[0]) ? item.positionData[0] : item;
        const valueToSend = {symbolData: item, pageName: "position"}; // Data to pass
        navigate('/symbol-details', {state: valueToSend}); // Navigate to the new page
    };
    useEffect(() => {
        fetchDataFromAPI()
        handleGetGroupList()
        document.title = "Admin Panel | Transactions";
        return () => {
            document.title = "Admin Panel";
        };
    }, [currentPage]);
    useEffect(() => {
        if (tickData?.data) {
            let netpl = 0;
            const newData = tableData.map(userItem => {
                if (userItem.symbolName === tickData.data.symbol) {
                    if (userItem.tradeType === SELL && userItem.totalQuantity > 0) {
                        userItem.m2m = (tickData.data.ask - userItem.price.toFixed(2)) * userItem.totalQuantity
                    } else {
                        if (userItem.totalQuantity < 0) {
                            userItem.m2m = (tickData.data.ask - userItem.price.toFixed(2)) * userItem.totalQuantity
                        } else {
                            userItem.m2m = (tickData.data.bid - userItem.price.toFixed(2)) * userItem.totalQuantity
                        }
                    }
                    userItem.ltp = tickData.data.ltp
                    userItem.ask = tickData.data.ask
                    userItem.bid = tickData.data.bid
                    userItem.ch = tickData.data.ch
                    userItem.chp = tickData.data.chp
                    let m2mPln = userItem.m2m;
                    netpl = netpl + m2mPln
                    return {
                        ...userItem,
                        ['m2mTotal']: m2mPln.toFixed(2)
                    };
                } else {
                    netpl = netpl + parseFloat(userItem.m2mTotal)
                }
                return userItem;
            });
            setNetplTotal(netpl.toFixed(2))
            setTableData(newData);
        }
        // console.log(tickData?.data)
    }, [tickData]);
    const handleIO = (data) => {
        if (data?.position?.symbolId) {
            if (data?.position?.data) {
                const idExists = tableData.some(item => item.symbolId === data.position.symbolId);
                if (!idExists) {
                    let updatedDatas = [data.position.data, ...tableData];
                    // setTableData(updatedDatas);
                    updatePsdData(updatedDatas, exchangeData)
                } else {
                    const newData = tableData.map((item) => {
                        return item.symbolId === data.position.symbolId ? data.position.data : item;
                    });
                    // setTableData(newData)
                    updatePsdData(newData, exchangeData)
                }
            } else {
                const newData = tableData.filter((item) => item.symbolId !== data.position.symbolId)
                // setTableData(newData)
                updatePsdData(newData, exchangeData)
            }
        }
    };
    useEffect(() => {
        // const socket = initiateSocketConnection();
        // socket.on("trade", (data) => {
        if (channelData) {
            handleIO(channelData)
            if (channelData?.userData) {
                authenticated = channelData.userData
                localStorage.setItem(
                    "authenticated",
                    JSON.stringify(channelData.userData)
                );
                setTradeMarginBalance(channelData.userData.tradeMarginBalance)
            }
        }
        // });
        // socket.emit('subscribe', authenticated.userName);
        // return () => {
        //     disconnectSocket();
        // };
    }, [channelData]);
    const clickToBackReturn = () => {
        navigate('/dashboard', {replace: true}); // Navigate to the new page
    };
    return (
        <>
            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">

                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn" onClick={clickToBackReturn}>
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>

                    <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                        <h5 className="head-numbers mb-0">Position</h5>
                    </div>

                    {isDesktop &&
                        <div className="page-head-flt d-flex v-center">
                            <button className='btn white-bdr small' onClick={() => handleQROff()}>SQ-OFFALL</button>
                            <button className='btn white-btn small m-btn' onClick={toggleBox}>M</button>
                        </div>
                    }

                </div>
            </div>
            {isDesktop &&
                <LeftSidebar/>
            }
            <div className="main-container trans">
                <div className="center-main-content">
                    <div className="center-container trans">
                        <div className="home-container">
                            <div className="position-page">

                                <div className='page-top-card bg1'>

                                    {isMobile &&
                                        <div
                                            className="page-head-mobi-flt d-flex v-center ptb-10 pt-0 justify-content-end">
                                            <button className='btn white-bdr small'
                                                    onClick={() => handleQROff()}>SQ-OFFALL
                                            </button>
                                            <button className='btn white-btn small m-btn' onClick={toggleBox}>M</button>
                                        </div>
                                    }

                                    <div className='page-white-card bg-white'>
                                        <Row className="box-row-main row-space-10 text-center">

                                            <Col xs={3} md={3} lg={3} className='items'>
                                                <div className='box-card text-center h-100'>
                                                    <div className='acc-value'>
                                                        <span className='view-text'>
                                                            <strong>Booked</strong>
                                                        </span>
                                                        <span
                                                            className={'value-text ' + ((parseFloat(authenticated.profitLoss) - parseFloat(authenticated.brokerageTotal)) > 0 ? 'text-primary' : 'text-danger')}>
                                                            <strong>{(parseFloat(authenticated.profitLoss) - parseFloat(authenticated.brokerageTotal)).toFixed(2)}</strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xs={3} md={3} lg={3} className='items'>
                                                <div className='box-card text-center h-100'>
                                                    <div className='acc-value'>
                                                        <span className='view-text'>
                                                            <strong>Balance</strong>
                                                        </span>
                                                        <span className='value-text'>
                                                            <strong>{(parseFloat(netplTotal) + parseFloat(authenticated.balance)).toFixed(2)}</strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xs={3} md={3} lg={3} className='items'>
                                                <div className='box-card text-center h-100'>
                                                    <div className='acc-value'>
                                                        <span className='view-text'>
                                                            <strong>Live Profit Loss</strong>
                                                        </span>
                                                        <span
                                                            className={'value-text ' + (netplTotal > 0 ? 'text-primary' : 'text-danger')}>
                                                            <strong>{netplTotal}</strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xs={3} md={3} lg={3} className='items'>
                                                <div className='box-card text-center h-100'>
                                                    <div className='acc-value'>
                                                        <span className='view-text'>
                                                            <strong>Available Margin</strong>
                                                        </span>
                                                        <span
                                                            className={'value-text'}>
                                                            <strong>{parseFloat(authenticated.tradeMarginBalance.toFixed(2))}</strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>

                                    {isVisible && (
                                        <div className='profile-card-inn m-main pb-0'>
                                            {exchangeData.map((item, index) => (
                                                <div className='m-list' key={index}>
                                                    <div className='m-items d-flex v-center'>
                                                        <strong className='m-labal'>{item.exchangeName}</strong>
                                                        <span className='m-line'></span>
                                                        <span
                                                            className='m-value'>{formatCurrency((parseFloat(item.leverage) * ((parseFloat(netplTotal) + parseFloat(tradeMarginBalance)))).toFixed(2))}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>


                                <div className='other-block ptb-10'>
                                    {carryflMarginTotal && carryflMarginTotal != 0 ?
                                        <div className='block-title box-title text-center'>
                                            <h5 className='b-clr'>Required Margin To CF : {carryflMarginTotal}</h5>
                                        </div>
                                        : ''}
                                    <div className='block-main'>
                                        {tableData.map((item, index) => (
                                            <div className='gray-list-items' key={index}>
                                                <div
                                                    className="list-inn-header d-flex justify-content-between align-items-center">
                                                    <div className="left-h d-flex align-items-center">
                                                        <div className='confirm-checkbox'>
                                                            <label className='checkbox'>
                                                                <input type='checkbox' value={item.positionId}
                                                                       onChange={(e) => handleSQOffCheckboxChange(e)}/>
                                                                <span className='checkmark'></span>
                                                            </label>
                                                            <div className='cf'>SQ</div>
                                                        </div>
                                                        <div className='confirm-checkbox'>
                                                            <label className='checkbox'>
                                                                <input type='checkbox' value={item.productType}
                                                                       checked={(item.productType === LONGTERM)}
                                                                       onChange={() => handleCheckboxChange(item)}/>
                                                                <span className='checkmark'></span>
                                                            </label>
                                                            <div className='cf'>CF</div>
                                                        </div>
                                                        <div className='p-iconbox text-center'
                                                             onClick={(k) => handleSymbolDetailView(item)}>
                                                            <div
                                                                className={"icon-circle-bdr " + ((item.tradeType === BUY) ? '' : 'text-danger')}>
                                                                <i className="las la-arrow-right"></i>
                                                            </div>
                                                            <span
                                                                className='number'>{item.isSymbolWise ? item.quantity : item.totalQuantity}</span>
                                                        </div>
                                                        <div className="user-h ml-2"
                                                             onClick={(k) => handleSymbolDetailView(item)}>
                                                            <h6 className="mb-0">
                                                                {item.symbolTitle}
                                                                <small
                                                                    className='list-date ml-2'>{formatSymbolExpiryDate(item.expiryDate)}</small>
                                                            </h6>
                                                            <small
                                                                className='list-date'><strong>B:{item.price.toFixed(2)}</strong> &nbsp;
                                                                <strong><i
                                                                    className={"c_arrow las la-arrow-right " + ((item.tradeType === BUY) ? 'b-clr' : 'text-danger')}></i> C:{(item.tradeType === BUY) ? item.bid : item.ask}
                                                                </strong>
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <div className="right-h text-right"
                                                         onClick={(k) => handleSymbolDetailView(item)}>
                                                        <h5 className="mb-0">
                                                            <span
                                                                className={(item.m2mTotal > 0 ? 'b-clr' : 'text-danger')}>{item.m2mTotal}</span><br/>
                                                            <small
                                                                className='list-date'>{formatDateForTradeList(item.createdAt)}</small><br/>
                                                            <small
                                                                className='list-date'>PUM : {item.tradeMarginTotal.toFixed(2)}</small>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {isMobile &&
                <Footer/>
            }
        </>
    );
};

export default Position;