import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import sitelogo from '../assets/images/sitelogo.png';
import rupee from '../assets/images/rupee.png';
import download from '../assets/images/download.png';
import user from '../assets/images/user.png';
import bank from '../assets/images/bank.png';
import money from '../assets/images/money.png';
import candlestick from '../assets/images/candlestick.png';
import stock_market from '../assets/images/stock_market.png';
import gold_ingot from '../assets/images/gold_ingot.png';
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import {
    API_ENDPOINT, SUCCESS, USER_WISE_EXCHANGE_LIST
} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import {formatDate} from "../utils/dateUtils";

const Account = () => {
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    const [exchangeData, setExchnageData] = useState([]);
    const navigate = useNavigate();

    const handleGetGroupList = async () => {
        let data = encryptData({
            "page": 1,
            "limit": 100,
            "search": "",
            "sortKey": "createdAt",
            "sortBy": -1,
            "groupId": "",
            "userId": authenticated.userId
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + USER_WISE_EXCHANGE_LIST, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                        if (response.data.statusCode == SUCCESS) {
                            const resData = decryptData(response.data.data)
                            console.log(resData)

                            const newData = resData.map((item, index) => {
                                if (index % 3 === 0) {
                                    item.image = candlestick
                                } else if (index % 2 === 0) {
                                    item.image = gold_ingot
                                } else {
                                    item.image = stock_market
                                }
                                return item;
                            });
                            //gold_ingot
                            //candlestick
                            //stock_market
                            setExchnageData(newData)
                        } else {
                            // toastError(response.data.message)
                            console.error("Login error:", response.data.message); // Handle error
                        }
                    }
                )
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch
            (error) {
        }
    };
    const clickToBackReturn = () => {
        navigate('/profile', { replace: true }); // Navigate to the new page
    };
    useEffect(() => {
        handleGetGroupList()
    }, []);
    return (
        <>

            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">

                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn" onClick={clickToBackReturn}>
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>

                    <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                        <h5 className="head-numbers mb-0">Account Details</h5>
                    </div>

                </div>
            </div>

            {isDesktop &&
                <LeftSidebar/>
            }

            <div className="main-container trans">
                <div className="center-main-content">
                    <div className="center-container trans">
                        <div className="home-container">

                            <div className="account-page">

                                {/* <div className='account-logo-block d-flex'>
                                    <img src={sitelogo} alt=''/>
                                </div> */}

                                <div className='acc-dtl-list'>

                                    <p className="detail_title mt-0">Summary</p>
                                    <div className='detail-sub-list d-flex v-center'>
                                        <div className='p-left d-flex v-center'>
                                            <div className='iconbox d-flex'><img src={rupee} alt=''/></div>
                                            <strong className='list_text'>BALANCE</strong>
                                        </div>
                                        <div className='p-right-arrow ml-auto'>
                                            <p><strong>{authenticated.balance.toFixed(2)}</strong></p>
                                        </div>
                                    </div>

                                    <div className='detail-sub-list d-flex v-center'>
                                        <div className='p-left d-flex v-center'>
                                            <div className='iconbox d-flex'><img src={download} alt=''/></div>
                                            <strong className='list_text'>SERVER</strong>
                                        </div>
                                        <div className='p-right-arrow ml-auto'>
                                            <p><strong>{authenticated.parentUser}</strong></p>
                                        </div>
                                    </div>

                                    

                                    <p className="detail_title">Account Details</p>
                                    <div className='detail-sub-list d-flex v-center'>
                                        <div className='p-left d-flex v-center'>
                                            <div className='iconbox d-flex'><img src={user} alt=''/></div>
                                            <strong className='list_text'>A/C DATE</strong>
                                        </div>
                                        <div className='p-right-arrow ml-auto'>
                                            <p><strong>{formatDate(authenticated.createdAt)}</strong></p>
                                        </div>
                                    </div>

                                    <div className='detail-sub-list d-flex v-center'>
                                        <div className='p-left d-flex v-center'>
                                            <div className='iconbox d-flex'><img src={bank} alt=''/></div>
                                            <strong className='list_text'>TOTAL LIMIT</strong>
                                        </div>
                                        <div className='p-right-arrow ml-auto'>
                                            <p><strong>{authenticated.credit}</strong></p>
                                        </div>
                                    </div>

                                    <div className='detail-sub-list d-flex v-center'>
                                        <div className='p-left d-flex v-center'>
                                            <div className='iconbox d-flex'><img src={money} alt=''/></div>
                                            <strong className='list_text'>MARGIN AMOUNT</strong>
                                        </div>
                                        <div className='p-right-arrow ml-auto'>
                                            <p><strong>{authenticated.credit}</strong></p>
                                        </div>
                                    </div>

                                    

                                    <p className="detail_title">Brokerage Details</p>
                                    {exchangeData.map((item, index) => (
                                        <div className='detail-sub-list d-flex v-center' key={index}>
                                            <div className='p-left d-flex v-center'>
                                                <div className='iconbox d-flex'><img src={item.image} alt=''/></div>
                                                <strong className='list_text'>{item.exchangeName}</strong>
                                            </div>
                                            <div className='p-right-arrow ml-auto'>
                                                <p><strong>{item.isTurnoverWise ? item.brokerageTurnoverwise + '/CR' : item.brokerageSymbolwiseAmount + '/LOT'}</strong></p>
                                            </div>
                                        </div>
                                    ))}

                                    

                                    <p className="detail_title">Leverage Details</p>
                                    {exchangeData.map((item, index) => (
                                        <div className='detail-sub-list d-flex v-center' key={index}>
                                            <div className='p-left d-flex v-center'>
                                                <div className='iconbox d-flex'><img src={item.image} alt=''/></div>
                                                <strong className='list_text'>{item.exchangeName}</strong>
                                            </div>
                                            <div className='p-right-arrow ml-auto'>
                                                <p><strong>X{item.leverage}</strong></p>
                                            </div>
                                        </div>
                                    ))}

                                    

                                    <p className="detail_title">Carry Forward Margin</p>
                                    {exchangeData.map((item, index) => (
                                        <div className='detail-sub-list d-flex v-center' key={index}>
                                            <div className='p-left d-flex v-center'>
                                                <div className='iconbox d-flex'><img src={item.image} alt=''/></div>
                                                <strong className='list_text'>{item.exchangeName}</strong>
                                            </div>
                                            <div className='p-right-arrow ml-auto'>
                                                <p><strong>X{item.carryForwardMarginAmount}</strong></p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {isMobile &&
                <Footer/>
            }
        </>
    );
};

export default Account;