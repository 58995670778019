import React, {useContext, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Header from "../components/Header";
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import TermsAndConditionsModal from '../components/TermsAndConditionsModal';
import {Row, Col, Card, Form, Button, Tab, Tabs} from 'react-bootstrap';
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import {
    API_ENDPOINT,
    GET_USER_TAB_LIST_WITH_SYMBOL,
    GET_USER_TAB_WISE_SYMBOLS_LIST,
    SUCCESS,
    ANNOUNCEMENT_LIST, SETTINGS
} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import {formatSymbolExpiryDate} from "../utils/dateUtils";
import {useSelector, useDispatch} from "react-redux";
import {addItem, removeItem} from "../services/arraySlice";
import {WebSocketContext} from '../services/WebSocketContext';
import webSocketManager from '../services/WebSocketManager';
import {useLocation, useNavigate} from 'react-router-dom';

function Home() {
    const {tickData} = useContext(WebSocketContext);
    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    const isNotificationRead = localStorage.getItem("isNotificationRead");
    const isTermsAndConditions = localStorage.getItem("isTermsAndConditions");
    let authenticated = JSON.parse(
        localStorage.getItem("authenticated")
    );
    const [termsAndConditions, setTermsAndConditions] = useState(isTermsAndConditions);
    const [userTabData, setUserTabData] = useState([]);
    const [announcementData, setAnnouncementData] = useState([]);
    const [tabWiseSymbolData, setTabWiseSymbolData] = useState([]);
    const location = useLocation();
    const passedData = location.state; // Get the passed data
    const [activeTab, setActiveTab] = useState(null); // Track the selected tab
    const [inputData, setInputData] = useState(''); // Track the selected tab
    const [settings, setSettings] = useState([]);
    const array = useSelector((state) => state.array);
    const localArray = [...array]; // Make a local copy of the current state.
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
    const savedTabId = localStorage.getItem('activeTabId'); // Retrieve previously active tab ID
    const addSymbolNameInArray = async (symbolName) => {
        /*if (!array.includes(symbolName)) {
            await dispatch(addItem(symbolName));
        }*/
        if (!localArray.includes(symbolName)) {
            await dispatch(addItem(symbolName));
            localArray.push(symbolName); // Update the local copy manually.
        }
    };
    const clickHideNotificationPoup = () => {
        setTermsAndConditions(1)
        localStorage.setItem("isTermsAndConditions", 1);
    };
    const clickToOpenSearchPage = () => {
        const valueToSend = {activeTabId: activeTab}; // Data to pass
        navigate('/search', {state: valueToSend}); // Navigate to the new page
        // window.location.href = `${process.env.PUBLIC_URL}/search`;
    };
    const clickToOpenRearrangePage = () => {
        const valueToSend = {activeTabId: activeTab}; // Data to pass
        navigate('/rearrange-scripts', {state: valueToSend}); // Navigate to the new page
        // window.location.href = `${process.env.PUBLIC_URL}/search`;
    };
    const clickToOpenNotificationPage = () => {
        const valueToSend = {activeTabId: activeTab}; // Data to pass
        navigate('/notification', {state: valueToSend}); // Navigate to the new page
        // window.location.href = `${process.env.PUBLIC_URL}/search`;
    };
    const handleSymbolDetailView = async (item) => {
        const valueToSend = {symbolData: item, activeTabId: activeTab, pageName: "home"}; // Data to pass
        navigate('/symbol-details', {state: valueToSend}); // Navigate to the new page
    };
    const handleGetTabList = async () => {
        try {
            let userTabId = '';
            if (passedData?.activeTabId) {
                userTabId = passedData.activeTabId
            } else {
                userTabId = savedTabId
            }
            let data = encryptData({
                "userTabId": userTabId
            });
            data = JSON.stringify({data: data});
            axios
                .post(API_ENDPOINT + GET_USER_TAB_LIST_WITH_SYMBOL, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        setUserTabData(resData.userTabList)
                        if (passedData?.activeTabId) {
                            setActiveTab(passedData.activeTabId);
                            localStorage.setItem('activeTabId', userTabId); // Save the clicked tab's ID
                            // handleGetTabWiseSymbolList(passedData.activeTabId);
                        } else {
                            localStorage.setItem('activeTabId', savedTabId || (resData.userTabList.length > 0 && resData.userTabList[0]?.userTabId)); // Save the clicked tab's ID
                            setActiveTab(savedTabId || resData.userTabList[0]?.userTabId);
                            // handleGetTabWiseSymbolList(resData[0]?.userTabId);
                        }
                        setTabWiseSymbolData(resData.userTabSymbolList)
                        socketData(resData.userTabSymbolList)
                    } else {
                        // toastError(response.data.message)
                        console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const handleGetTabWiseSymbolList = async (userTabId) => {
        setTabWiseSymbolData([])
        setActiveTab(userTabId);
        localStorage.setItem('activeTabId', userTabId); // Save the clicked tab's ID
        let data = encryptData({
            "userTabId": userTabId
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + GET_USER_TAB_WISE_SYMBOLS_LIST, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        setTabWiseSymbolData(resData)
                        socketData(resData)
                    } else {
                        // toastError(response.data.message)
                        console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const announcementList = async () => {
        try {
            let data = encryptData({
                "page": 1,
                "limit": 1000,
            });
            data = JSON.stringify({data: data});
            axios
                .post(API_ENDPOINT + ANNOUNCEMENT_LIST, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        setAnnouncementData(resData)
                    } else {
                        // toastError(response.data.message)
                        console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const socketData = async (symbolData) => {
        for (var i = 0; i < symbolData.length; i++) {
            await addSymbolNameInArray(symbolData[i].symbolName)
        }
        webSocketManager.send(localArray);
    };

    const getSettings = async () => {
        try {
            let data = encryptData({
                "page": 1,
                "limit": 1000,
            });
            data = JSON.stringify({ data: data });
            axios
                .get(API_ENDPOINT + SETTINGS, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        setSettings(resData)
                    } else {
                        console.error("Settings error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    console.error("Settings error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    useEffect(() => {
        if (tickData?.data) {
            const newDataForSymbol = tabWiseSymbolData.map(symbolItem => {
                if (symbolItem.symbolName === tickData.data.symbol) {
                    symbolItem.lask = symbolItem.ask
                    symbolItem.lbid = symbolItem.bid
                    symbolItem.lltp = symbolItem.ltp
                    let askClass = (parseFloat(symbolItem.ask) > parseFloat(tickData.data.ask)) ? 'l-bg' : ''
                    let bidClass = (parseFloat(symbolItem.bid) > parseFloat(tickData.data.bid)) ? 'l-bg' : ''
                    symbolItem.askColorClass = (parseFloat(symbolItem.ask) < parseFloat(tickData.data.ask)) ? 'h-bg' : askClass
                    symbolItem.bidColorClass = (parseFloat(symbolItem.bid) < parseFloat(tickData.data.bid)) ? 'h-bg' : bidClass
                    symbolItem.ltp = tickData.data.ltp
                    symbolItem.ask = tickData.data.ask
                    symbolItem.bid = tickData.data.bid
                    symbolItem.ch = tickData.data.ch
                    symbolItem.chp = tickData.data.chp
                    symbolItem.close = tickData.data.close
                    symbolItem.high = tickData.data.high
                    symbolItem.lc = tickData.data.lc
                    symbolItem.uc = tickData.data.uc
                    symbolItem.low = tickData.data.low
                    symbolItem.ls = tickData.data.ls
                    symbolItem.ltt = tickData.data.ltt
                    symbolItem.open = tickData.data.open
                    symbolItem.ts = tickData.data.ts
                    symbolItem.tsq = tickData.data.tsq
                    symbolItem.volume = tickData.data.volume
                }
                return symbolItem;
            });
            setTabWiseSymbolData(newDataForSymbol)
        }
        // console.log(tickData?.data)
    }, [tickData]);

    useEffect(() => {
        handleGetTabList()
        announcementList()
        getSettings()
        if (passedData?.activeTabId) {
            navigate(location.pathname, {replace: true});
        }
    }, []);

    //TV Button Toggle
    const [isWatching, setIsWatching] = useState(false);
    const handleToggle = () => {
        setIsWatching(!isWatching);
    };

    return (
        <>
            <Header/>
            {isDesktop &&
                <LeftSidebar/>
            }
            <div className="main-container trans">
                <div className="center-main-content pt-0">
                    <div className="center-container trans">
                        <div className="home-container">
                            {settings && settings.settingData?.banMessage && (
                                <>
                                    <div className="position-relative w-100 overflow-hidden">
                                        <div className="ban-message" style={{ whiteSpace: "nowrap", animation: "marquee 30s linear infinite" }}>
                                            {settings.settingData.banMessage}
                                        </div>
                                    </div>
                                    <style>
                                        {`
                                        @keyframes marquee {
                                            from {transform: translateX(100%); }
                                            to {transform: translateX(-100%); }
                                        }
                                        `}
                                    </style>
                                </>
                            )}
                            <div className="page-search-block mrkt-search-block d-flex v-center sticky-search">
                                <div className="search-box" onClick={clickToOpenSearchPage}>
                                    <input type="text" placeholder="Search Script Here" value={inputData} onChange={(e) => setInputData(e.target.value)} />
                                    <i className="search-btn fa-solid fa-magnifying-glass"></i>
                                </div>
                                {isMobile &&
                                    <div className='head_user_name'>
                                        <h4>{authenticated.userName}</h4>
                                    </div>
                                }
                                <div className="pg-head-right d-flex ml-auto">
                                    <button className="notify-btn" onClick={clickToOpenNotificationPage}>
                                        <i className="fa-regular fa-bell"></i>
                                        <span
                                            className="notify-number">{(isNotificationRead != 1) ? announcementData.length : 0}</span>
                                    </button>
                                    <button className="fltr-btn" onClick={clickToOpenRearrangePage}><i
                                        className="fa-solid fa-up-down"></i></button>
                                    
                                    <button className="fltr-btn" onClick={handleToggle}>
                                        <i className="fa fa-television"></i>
                                    </button>
                                </div>
                            </div>

                            <div className="page-content">

                                {isWatching && (
                                <div className="watch_iframe_box d-flex">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/4QvoTo1CFPs?si=bSRYXL9Gx9m93yxX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                                )}

                                <Tabs id="uncontrolled-tab-example" className="tab-style top-fixed-tab"
                                      activeKey={activeTab}
                                      onSelect={(k) => handleGetTabWiseSymbolList(k)}>
                                    {userTabData.map((item, index) => (
                                        <Tab title={item.title} eventKey={item.userTabId} key={index}>
                                            <table className="watchlist-table">
                                                <thead>
                                                <tr>
                                                    <th>Script</th>
                                                    <th className="text-center" width="110">
                                                        <Row className="td-width">
                                                            <Col xs={6} md={6} lg={6} className='sub-items'>BID</Col>
                                                            <Col xs={6} md={6} lg={6} className='sub-items'>ASK</Col>
                                                        </Row>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {tabWiseSymbolData.map((item, index) => (
                                                    <tr onClick={(k) => handleSymbolDetailView(item)} key={index}>
                                                        <td>
                                                            <h5 className="list-tl">
                                                                {/* <i class={"trade_icon_arrow las " + (0 > item.ch ? "text_red la-angle-double-down" : "text_primary la-angle-double-up")}></i> */}
                                                                <i className={`trade_icon_arrow las ${item.ch < 0 ? "text_red la-angle-double-down" : "text_primary la-angle-double-up"}`}></i>
                                                                {item.exchangeName.toLowerCase() === 'usstock' ? item.symbolName : item.masterName} <span
                                                                className="list-date">{formatSymbolExpiryDate(item.expiryDate)}</span>
                                                            </h5>
                                                            <div
                                                                className={"ltp-box d-flex v-center text_primary " + (0 > item.ch ? "text_red" : "text_primary")}>
                                                                <div className="ltp-text">LTP : {item.ltp}</div>
                                                                <div className="chang-text ml-2">N.CHANGE
                                                                    : {item.ch}</div>
                                                            </div>
                                                        </td>
                                                        <td className="text-center trad_td_last">
                                                            <div className='trade_number_main d-flex v-center'>
                                                                <div
                                                                    className={"trade_number_box " + (item?.bidColorClass ? item.bidColorClass : '')}>
                                                                    <button className='btn-text'>{item.bid}</button>
                                                                    <div className="h-text">H : {item.high}</div>
                                                                </div>

                                                                <div
                                                                    className={"trade_number_box " + (item?.askColorClass ? item.askColorClass : '')}>
                                                                    <button className="btn-text">{item.ask}</button>
                                                                    <div className="l-text">L : {item.low}</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </Tab>
                                    ))}
                                </Tabs>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            {termsAndConditions && termsAndConditions != 1 ?
                <TermsAndConditionsModal clickHideNotificationPoup={clickHideNotificationPoup}/>
                : ''}

            {isMobile &&
                <Footer/>
            }

        </>
    );
}

export default Home;