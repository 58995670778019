import React, {useState, useEffect} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import {Container, Tab, Tabs, Row, Col, Card, Form} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import { API_ENDPOINT, TRADE_LIST, SUCCESS, EXECUTED, BUY, LIMIT, STOPLOSS} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import {toast, Bounce} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {formatSymbolExpiryDate, formatDateForTradeList} from "../utils/dateUtils";
import {useSocket} from "../services/SocketContext";
import {toastSuccess, toastError} from "../utils/toastMsg";

const Trades = () => {

    const {channelData, socket, disconnectSocket} = useSocket();
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [commission, setCommission] = useState(0);
    const [booked, setBooked] = useState(0);
    const [currentDate, setCurrentDate] = useState();
    const [currentWeekDate, setCurrentWeekDate] = useState();
    const itemsPerPage = -1; // Number of items to display per page
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        'startDate': '',
        'endDate': ''
    });

    const [isVisible, setIsVisible] = useState(false);
    const toggleBox = () => {
        setIsVisible(!isVisible);
    };
    const fetchDataFromAPI = async (startDate, endDate) => {
        try {
            let data = encryptData({
                "search": "",
                "userId": '',
                "symbolId": '',
                "exchangeId": '',
                "status": EXECUTED,
                "startDate": (startDate) ? startDate : '',
                "endDate": (endDate) ? endDate : '',
                "page": currentPage,
                "limit": itemsPerPage,
            });
            data = JSON.stringify({data: data});
            axios
                .post(API_ENDPOINT + TRADE_LIST, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const rdata = decryptData(response.data.data)
                        setTableData(rdata);
                        let bookT = 0;
                        let coomiT = 0;
                        rdata.map(item => {
                            bookT = (bookT + parseFloat(item.profitLoss)) - parseFloat(item.brokerageAmount)
                            coomiT = coomiT + parseFloat(item.brokerageAmount)
                        });
                        setCommission(coomiT)
                        setBooked(bookT)
                    } else {
                        toastError(response.data.message)
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handelCallAPI = (sta) => {
        if (sta == 1) {
            setFormData({
                ...formData,
                ['startDate']: currentDate,
                ['endDate']: currentDate,
            });
            fetchDataFromAPI(currentDate, currentDate)
        }
        if (sta == 2) {
            setFormData({
                ...formData,
                ['startDate']: currentWeekDate,
                ['endDate']: currentDate,
            });
            fetchDataFromAPI(currentWeekDate, currentDate)
        }
        if (sta == 3) {
            fetchDataFromAPI(formData.startDate, formData.endDate)
        }
        setIsVisible(false)
    };

    const handleSetSocketData = (data) => {
        if (data.trade.status === EXECUTED) {
            if (data?.trade) {
                const idExists = tableData.some(item => item.tradeId === data.trade.tradeId);
                if (!idExists) {
                    setTableData([data.trade, ...tableData]); // Use spread operator to prepend new data
                }
            }
        }
        if (data?.userData) {
            localStorage.setItem(
                "authenticated",
                JSON.stringify(data.userData)
            );
        }
    };
    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0]; // YYYY-MM-DD format
        setCurrentDate(formattedDate);
        const date = new Date();
        date.setDate(date.getDate() - 7);
        const eddD = date.toISOString().split('T')[0];
        setCurrentWeekDate(eddD); // Format as YYYY-MM
        setFormData({
            ...formData,
            ['startDate']: eddD,
            ['endDate']: formattedDate,
        });
        fetchDataFromAPI(eddD, formattedDate);
    }, []);
    useEffect(() => {
        // const socket = initiateSocketConnection();
        // socket.on("trade", (data) => {
        if (channelData) {
            handleSetSocketData(channelData)
        }
        // });
        // socket.emit('subscribe', authenticated.userName);
        // return () => {
        //     disconnectSocket();
        // };
    }, [channelData]);
    const clickToBackReturn = () => {
        navigate('/dashboard', { replace: true }); // Navigate to the new page
    };
    return (
        <>

            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">

                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn" onClick={clickToBackReturn}>
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>

                    <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                        <h5 className="head-numbers mb-0">Trades</h5>
                    </div>

                    <div className="page-head-flt d-flex v-center">
                        <button className='btn white-bdr small' onClick={toggleBox}>FILTER</button>
                    </div>

                </div>
            </div>

            {isVisible && (
                <div className='acc-filter-block fixed-right-filter bg4 ptb-10'>
                    <Form className='upi-fields flt-form'>

                        <div className="qty-text-block pt-0">
                            <div className='title_text'>
                                <strong>Select Date Range</strong>
                            </div>
                        </div>

                        <div className="qty-text-block" onClick={(e) => handelCallAPI(1)}>
                            <label className="stk-qty-field w-100 d-flex v-center">
                                Today <span className='right-text ml-auto'>{currentDate}</span>
                            </label>
                        </div>

                        <div className="qty-text-block" onClick={(e) => handelCallAPI(2)}>
                            <label className="stk-qty-field w-100 d-flex v-center">
                                This Week <span
                                className='right-text ml-auto'>{currentWeekDate} to {currentDate}</span>
                            </label>
                        </div>

                        <div className="qty-text-block">
                            <div className='title_text'>
                                <strong>Filter By custom Date</strong>
                            </div>
                        </div>

                        <div className="qty-text-block">
                            <label className="stk-qty-field w-100 d-flex v-center">
                                From Date
                                <span className='right-text ml-auto'>
                                <input type="date" id="startDate" name="startDate" min="2023-01-01"
                                       onChange={(e) => handleChange(e, 'startDate')}
                                       max={currentDate} value={formData.startDate}/>
                            </span>
                            </label>
                        </div>

                        <div className="qty-text-block">
                            <label className="stk-qty-field w-100 d-flex v-center">
                                To Date
                                <span className='right-text ml-auto'>
                                <input type="date" id="endDate" name="endDate" min="2023-01-01"
                                       onChange={(e) => handleChange(e, 'endDate')}
                                       max={currentDate} value={formData.endDate}/>
                            </span>
                            </label>
                        </div>

                        <div className="qty-text-block form-btnbox mt-2">
                            <button type="button" className="btn primary w-100"
                                    onClick={(e) => handelCallAPI(3)}>Submit
                            </button>
                        </div>

                    </Form>
                </div>
            )}

            {isDesktop &&
                <LeftSidebar/>
            }

            <div className="main-container trans pb-0">
                <div className="center-main-content">
                    <div className="center-container trans">
                        <div className="home-container">

                            <div className="trades-page">

                                <div className='profile-card pb-0'>
                                    <Row className="profile-box-main row-space-10 text-center">

                                        <Col xs={6} md={6} lg={6} className='items'>
                                            <div className='profile-card-inn text-center h-100'>
                                                <div className='acc-value'>
                                                    <span className='view-text'>
                                                        <strong>commission</strong>
                                                    </span>
                                                    <span className='value-text text-danger'>
                                                        <strong>{commission.toFixed(2)}</strong>
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={6} md={6} lg={6} className='items'>
                                            <div className='profile-card-inn text-center h-100'>
                                                <div className='acc-value'>
                                                    {/*<span className='view-text text-danger'>
                                                        <strong>Demo Account</strong>
                                                    </span>*/}
                                                    <span className='view-text'>
                                                        <strong>Booked P/L</strong>
                                                    </span>
                                                    <span className='value-text text-danger'>
                                                        <strong>{booked.toFixed(2)}</strong>
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                </div>

                                <div className='bdr-card other-block p-0 overflow-box'>
                                    <div className='other-list p-0'>
                                        {tableData.map((item, index) => (
                                            <div className='p-list-items bdr-top' key={index}>
                                                <div
                                                    className="list-inn-header d-flex justify-content-between align-items-center">
                                                    <div className="left-h d-flex align-items-center">
                                                        <div className='p-iconbox text-center'>
                                                            <div
                                                                className={"icon-circle-bdr " + ((item.tradeType === BUY) ? '' : 'text-danger')}>
                                                                <i className='fa fa-arrow-right'></i>
                                                            </div>
                                                            <span className='number'>{item.totalQuantity}</span>
                                                        </div>

                                                        <div className="user-h ml-2">
                                                            <h6 className="mb-0">{item.symbolTitle} <small
                                                                className='list-date ml-2'>{formatSymbolExpiryDate(item.expiryDate)}</small>
                                                            </h6>
                                                            <small
                                                                className='list-date'><strong>{item.tradeType.toUpperCase()}{(item.orderType === LIMIT) ? '-L' : (item.orderType === STOPLOSS) ? '-SL' : ''}</strong> &nbsp;
                                                                <strong><i
                                                                    className={"fa fa-arrow-circle-right " + ((item.tradeType === BUY) ? 'b-clr' : 'text-danger')}></i> {item.price}
                                                                </strong></small><br/>
                                                            <small
                                                                className='list-date'>{formatDateForTradeList(item.executionDateTime)}</small>
                                                        </div>
                                                    </div>
                                                    <div className="right-h text-right">
                                                        <h5 className="mb-0">
                                                            <span
                                                                className={((parseFloat(item.profitLoss) - parseFloat(item.brokerageAmount)) > 0) ? 'b-clr' : 'text-danger'}>{(parseFloat(item.profitLoss) - parseFloat(item.brokerageAmount)).toFixed(2)}</span><br/>
                                                            <small
                                                                className='list-date'>BRK.{item.brokerageAmount.toFixed(2)}</small><br/>
                                                            <small className='list-date'>{item.comment}</small>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {isMobile &&
                <Footer/>
            }
        </>
    );
};

export default Trades;