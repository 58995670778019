import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useLocation, useNavigate} from 'react-router-dom';
// import {initiateSocketConnection, disconnectSocket, getSocket} from "../services/socket";
import {useSocket} from "../services/SocketContext";

const Header = () => {
    const {channelData, socket, disconnectSocket} = useSocket();
    let authenticated = JSON.parse(
        localStorage.getItem("authenticated")
    );
    const navigate = useNavigate();
    const [balance, setBalance] = useState(0);
    const clickToOpenDepositPage = () => {
        navigate('/deposit-request'); // Navigate to the new page
        // window.location.href = `${process.env.PUBLIC_URL}/search`;
    };
    useEffect(() => {
        // const socket = initiateSocketConnection();
        // socket.on("trade", (data) => {
        if (channelData) {
            if (channelData?.userData) {
                authenticated = channelData.userData
                localStorage.setItem(
                    "authenticated",
                    JSON.stringify(channelData.userData)
                );
                setBalance(channelData.userData.balance)
            }
        }
        // });
        // socket.emit('subscribe', authenticated.userName);
        // return () => {
        //     disconnectSocket();
        // };
    }, [channelData]);
    useEffect(() => {
        if (authenticated) {
            setBalance(authenticated.balance)
        } else {
            navigate('/login'); // Navigate to the new page
        }
    }, [authenticated]);
    return (
        <>
            <header id="Header" className="page-top-block bg2 d-flex v-center head-left-space fixed-top">


                <div className="page-head relative d-flex v-center w-100">

                    <div className="page-head-left d-flex v-center">
                        <i className="coin_icon fa-solid fa-coins"></i>
                        <strong className="head-numbers">{balance.toFixed(2)}</strong>
                    </div>

                    <div className="page-head-right d-flex ml-auto">
                        <button className="btn small white-bdr" onClick={clickToOpenDepositPage}>
                            <i className="fa-solid fa-plus"></i> <span>Add Funds</span>
                        </button>
                    </div>

                </div>
            </header>
        </>
    );
};
export default Header;