import {toast, Bounce} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toastSuccess = (message) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 800,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
};
export const toastError = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 800,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
};