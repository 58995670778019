import React, { useState } from 'react';
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import {Row, Col, Card, Form, Button} from 'react-bootstrap';
import {useMediaQuery} from "react-responsive";

const ReArrangeScriptsOld = () => {

    const isMobile = useMediaQuery({ query: "(max-width: 991px)" });
    const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

return (
    <>

    <div className="page-top-block fixed-top head-left-space">
        <div className="page-head bg1 d-flex">

            <div className="page-head-left d-flex v-center">
                <button className="back-btn">
                    <i className="coin_icon fa fa-arrow-left"></i>
                </button>
            </div>

            <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                <h5 className="head-numbers mb-0">ReArrenge Scripts</h5>
            </div>

            {isDesktop &&
            <div className="page-head-flt d-flex v-center">
                <button className="btn white-bdr small" type='button'>A-Z</button>
                <button className="btn white-bdr small" type='button'>Save</button>
            </div>
            }

        </div>
    </div>

    {isDesktop &&
        <LeftSidebar/>
    }

    <div className="main-container trans">
        <div className="center-main-content">
            <div className="center-container trans">
                <div className="home-container">

                    <div className="rearrenge-scripts-page">
                        <div className='rearrenge-scripts-block'>

                            {isMobile &&
                            <div className="page-head-mobi-flt d-flex v-center justify-content-end mb-2">
                                <button className="btn small" type='button'>A-Z</button>
                                <button className="btn small ml-2" type='button'>Save</button>
                            </div>
                            }

                            <div className="rearrenge-items">
                                <p>AD Mar</p>
                            </div>
                            <div className="rearrenge-items">
                                <p>BitcoinSpot</p>
                            </div>
                            <div className="rearrenge-items">
                                <p>APPLE</p>
                            </div>                            
                            <div className="rearrenge-items">
                                <p>CX NJ JAN</p>
                            </div>
                            <div className="rearrenge-items">
                                <p>GIFTNIFTY Dec</p>
                            </div>
                            <div className="rearrenge-items">
                                <p>DOW JONES Mar</p>
                            </div>
                            <div className="rearrenge-items">
                                <p>BANKNIFTY48400CE</p>
                            </div>                            
                            <div className="rearrenge-items">
                                <p>BANKNIFTY</p>
                            </div>
                            <div className="rearrenge-items">
                                <p>NIFTY</p>
                            </div>
                            <div className="rearrenge-items">
                                <p>GOLD</p>
                            </div>
                            <div className="rearrenge-items">
                                <p>SILVER</p>
                            </div>
                            <div className="rearrenge-items">
                                <p>FINNIFTY</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    {isMobile &&
        <Footer/>
    }
    </>
);
};

export default ReArrangeScriptsOld;