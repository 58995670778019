import React, {useState, useEffect} from 'react';
import {useMediaQuery} from "react-responsive";
import {Link} from 'react-router-dom';
import { useLocation } from "react-router-dom";
import menu_icon1 from '../assets/images/tab1.png';
import menu_icon_w1 from '../assets/images/tab_w1.png';
import menu_icon2 from '../assets/images/tab1.png';
import menu_icon_w2 from '../assets/images/tab_w1.png';
import menu_icon3 from '../assets/images/tab3.png';
import menu_icon_w3 from '../assets/images/tab_w3.png';
import menu_icon4 from '../assets/images/tab4.png';
import menu_icon_w4 from '../assets/images/tab_w4.png';
import menu_icon5 from '../assets/images/tab5.png';
import menu_icon_w5 from '../assets/images/tab_w5.png';

function AppMenu() {

    //assigning location variable
    const location = useLocation();
    //destructuring pathname from location
    const { pathname } = location;
    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    return (
        <>
        <div className="menu-bar d-flex">
            <div className="m-items">
                <div className={splitLocation[1] === "sltp" ? "active" : ""}>
                    <Link className="m-links" to="/sltp">
                        <i className="nav_iconbox">
                            {isDesktop && <img src={menu_icon1} alt='' width="24" /> }
                            {isMobile && <img className='visible' src={menu_icon1} alt='' width="24" />}
                            {isMobile && <img className='hide' src={menu_icon_w1} alt='' width="24" />}
                        </i>
                        <h6>Orders</h6>
                    </Link>
                </div>
            </div>

            <div className="m-items">
                <div className={splitLocation[1] === "trades" ? "active" : ""}>
                    <Link className="m-links" to="/trades">
                        <i className="nav_iconbox">
                            {isDesktop && <img src={menu_icon2} alt='' width="24" /> }
                            {isMobile && <img className='visible' src={menu_icon2} alt='' width="24" />}
                            {isMobile && <img className='hide' src={menu_icon_w2} alt='' width="24" />}
                        </i>
                        <h6>Trades</h6>
                    </Link>
                </div>
            </div>

            <div className="m-items">
                <div className={splitLocation[1] === "dashboard" ? "active" : ""}>
                    <Link className="m-links" to="/dashboard">
                        <i className="nav_iconbox">
                            {isDesktop && <img src={menu_icon3} alt='' width="24" /> }
                            {isMobile && <img className='visible' src={menu_icon3} alt='' width="24" />}
                            {isMobile && <img className='hide' src={menu_icon_w3} alt='' width="24" />}
                        </i>
                        <h6>Watchlist</h6>
                    </Link>
                </div>
            </div>

            <div className="m-items">
                <div className={splitLocation[1] === "position" ? "active" : ""}>
                    <Link className="m-links" to="/position">
                        <i className="nav_iconbox">
                            {isDesktop && <img src={menu_icon4} alt='' width="24" /> }
                            {isMobile && <img className='visible' src={menu_icon4} alt='' width="24" />}
                            {isMobile && <img className='hide' src={menu_icon_w4} alt='' width="24" />}
                        </i>
                        <h6>Position</h6>
                    </Link>
                </div>
            </div>

            <div className="m-items">
                <div className={splitLocation[1] === "profile" ? "active" : ""}>
                    <Link className="m-links" to="/profile">
                        <i className="nav_iconbox">
                            {isDesktop && <img src={menu_icon5} alt='' width="24" /> }
                            {isMobile && <img className='visible' src={menu_icon5} alt='' width="24" />}
                            {isMobile && <img className='hide' src={menu_icon_w5} alt='' width="24" />}
                        </i>
                        <h6>Profile</h6>
                    </Link>
                </div>
            </div>
        </div>
        </>
    );
}

export default AppMenu;