// WebSocketManager.js
import ReconnectingWebSocket from 'reconnecting-websocket';

// import WebSocket from 'ws'; // Import WebSocket library for Node.js

class WebSocketManager {
    constructor() {
        this.socket = null;
        this.symbolNames = [];
        this.listeners = new Set();
    }

    connect() {
        try {
            const url = process.env.REACT_APP_SOCKET_LIVE_URL + "data";
            var token = process.env.REACT_APP_SOCKET_TOKEN;
            const headers = {
                "Authorization": "Bearer " + token
            };
            const options = {
                WebSocket: window.WebSocket, // Use the ws library
                connectionTimeout: 8000, // Connection timeout in milliseconds
                maxRetries: 10, // Allow unlimited retries
                // maxRetries: Infinity, // Allow unlimited retries
                debug: false, // Enable debugging (optional)
                startClosed: false, // Start in closed state (optional)
            };
            this.socket = new ReconnectingWebSocket(url, [], {
                ...options,
                connectionOptions: {headers}
            });

            this.socket.onopen = () => {
                console.log('connected');
                if (this.symbolNames.length != 0) {
                    this.socket.send(JSON.stringify({"symbols": this.symbolNames}));
                }
            };

            this.socket.onmessage = (message) => {
                const data = JSON.parse(message.data);
                this.notifyListeners(data);
            };

            this.socket.onerror = (error) => {
                console.error('error:', error);
            };

            this.socket.onclose = () => {
                // console.log('WebSocket disconnected, reconnecting...');
                // setTimeout(() => this.connect(), 1000); // Reconnect on close
            };
        } catch (error) {
        }
    }

    notifyListeners(data) {
        this.listeners.forEach((callback) => callback(data));
    }

    subscribe(callback) {
        this.listeners.add(callback);
    }

    unsubscribe(callback) {
        this.listeners.delete(callback);
    }

    send(data) {
        this.symbolNames = data;
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify({"symbols": data}));
        } else {
            // console.error('WebSocket is not open');
        }
    }
}

const webSocketManager = new WebSocketManager();
export default webSocketManager;
