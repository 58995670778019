import React, {useState, useEffect} from 'react';
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import Accordion from 'react-bootstrap/Accordion';
import {useMediaQuery} from "react-responsive";
import {useLocation, useNavigate} from 'react-router-dom';
import {formatDate} from "../utils/dateUtils";
import axios from "axios";
import {
    API_ENDPOINT,
    DELETE_USER_TAB_SYMBOL,
    GET_USER_TAB_WISE_SYMBOLS_LIST,
    SUCCESS,
    SYMBOL_SEARCH_LIST,
    POST_USER_TAB_WISE_SYMBOL
} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import {toastSuccess, toastError} from "../utils/toastMsg";

const StockSearch = () => {
    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    const [tabWiseSymbolData, setTabWiseSymbolData] = useState([]);
    const [symbolSearchData, setSymbolSearchData] = useState([]);
    const [symbolSearch, setSymbolSearch] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const passedData = location.state; // Get the passed data
    const [activeKey, setActiveKey] = useState(null); // Manage the active tab key
    const [isBox1Visible, setIsBox1Visible] = useState(true);
    const [isSearchVisible, setIsSearchVisible] = useState(true);
    const toggleBoxes = () => {
        setIsBox1Visible((prev) => !prev);
        if (!symbolSearch) {
            setIsSearchVisible((prev) => !prev);
        }
        if (isBox1Visible) {
            handleSearchSymbol()
        } else {
            if (symbolSearch) {
                handleSearchSymbol(symbolSearch)
            }
        }
    };
    const clickToBackReturn = () => {
        const valueToSend = {activeTabId: passedData.activeTabId}; // Data to pass
        navigate('/dashboard', {replace: true, state: valueToSend}); // Navigate to the new page
        // window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
    };
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setSymbolSearch(value);
        if (2 < value.length) {
            handleSearchSymbol(value)
        } else {
            setIsSearchVisible(true)
        }
    };
    const handleGetTabWiseSymbolList = async (userTabId) => {
        let data = encryptData({
            "userTabId": userTabId
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + GET_USER_TAB_WISE_SYMBOLS_LIST, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        setTabWiseSymbolData(resData)
                    } else {
                        // toastError(response.data.message)
                        console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const handleDeleteUserTabSymbol = async (symbolId) => {
        let data = encryptData({
            "userTabId": passedData.activeTabId,
            "symbolId": symbolId
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + DELETE_USER_TAB_SYMBOL, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        handleGetTabWiseSymbolList(passedData.activeTabId)
                        handleSearchSymbol(symbolSearch)
                        //setActiveKey(activeKey); // Set active tab key
                    } else {
                        // toastError(response.data.message)
                        console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const handlePostUserTabSymbol = async (symbolId) => {
        let data = encryptData({
            "userTabId": passedData.activeTabId,
            "symbolId": symbolId
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + POST_USER_TAB_WISE_SYMBOL, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        handleGetTabWiseSymbolList(passedData.activeTabId)
                        handleSearchSymbol(symbolSearch)
                        //setActiveKey(activeKey); // Set active tab key
                    } else {
                        toastError(response.data.message)
                        console.error("Loginsdfvdvdcv error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const handleSearchSymbol = (value = '') => {
        let data = encryptData({
            "page": 1,
            "limit": 1000,
            "search": value,
            "exchangeId": "",
            "sortKey": "createdAt",
            "sortBy": -1
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + SYMBOL_SEARCH_LIST, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        setSymbolSearchData(resData)
                        if (symbolSearch) {
                            setIsSearchVisible(false)
                        }
                    } else {
                        // toastError(response.data.message)
                        setIsSearchVisible(true)
                        console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    setIsSearchVisible(true)
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const handleCheckSymbolInWatchList = (symbolId) => {
        const matchedItems = tabWiseSymbolData.filter((item) => item.symbolId === symbolId);
        if (matchedItems.length != 0) {
            return (
                <h4 className="mb-0 text-danger"
                    onClick={() => handleDeleteUserTabSymbol(symbolId)}>
                    <i className='fa fa-minus-circle'></i></h4>
            );
        } else {
            return (
                <h4 className="mb-0 text-primary"
                    onClick={() => handlePostUserTabSymbol(symbolId)}><i className='fa fa-plus-circle'></i></h4>
            );
        }
    };
    // Handle accordion state change
    const handleToggle = (key) => {
        setActiveKey((prevKey) => (prevKey === key ? null : key)); // Toggle the tab
    };
    useEffect(() => {
        if (passedData?.activeTabId) {
            document.getElementById("myInput").focus();
            handleGetTabWiseSymbolList(passedData.activeTabId)
        } else {
            navigate('/dashboard'); // Navigate to the new page
        }
    }, []);
    return (
        <>
            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">
                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn" onClick={clickToBackReturn}>
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>
                </div>
            </div>
            {isDesktop &&
                <LeftSidebar/>
            }
            <div className="main-container trans">
                <div className="center-main-content pt-0">
                    <div className="center-container trans">
                        <div className="home-container">

                            <div className="page-search-block d-flex v-center">
                                <div className="search-box">
                                    <input type="text" id="myInput" placeholder="SEARCH SYMBOL" value={symbolSearch}
                                           onChange={(e) => handleInputChange(e)}/>
                                    <i className="search-btn fa-solid fa-magnifying-glass"></i>
                                </div>
                                <div className="pg-head-right d-flex ml-auto">
                                    <button className="search-script-btn" onClick={toggleBoxes}>
                                        {isBox1Visible ? "All Scripts" : "Watchlist"}
                                    </button>
                                </div>
                            </div>

                            <div className="bdr-card search-page-main mt-2">
                                {isSearchVisible && (
                                    <div className='search-list-only'>
                                        {tabWiseSymbolData.map((item, index) => (
                                            <div className='search-list-items bdr-top' key={index}>
                                                <div
                                                    className="search-inn-header d-flex justify-content-between align-items-center">
                                                    <div className="left-h d-flex align-items-center">
                                                        <div className="icon-circle-bdr">
                                                            <span>{item.symbolTitle.charAt(0)}</span>
                                                        </div>
                                                        <div className="user-h ml-2">
                                                            <h6 className="mb-0">{item.symbolTitle}</h6>
                                                            <small
                                                                className='list-date'>{formatDate(item.expiryDate)}</small>
                                                        </div>
                                                    </div>
                                                    <div className="right-h text-right"
                                                         onClick={() => handleDeleteUserTabSymbol(item.symbolId)}>
                                                        <h4 className="mb-0 text-danger">
                                                            <i className='fa fa-minus-circle'></i>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        {/*<div className='search-list-items bdr-top'>
                                            <div
                                                className="search-inn-header d-flex justify-content-between align-items-center">
                                                <div className="left-h d-flex align-items-center">
                                                    <div className="icon-circle-bdr">
                                                        <span>B</span>
                                                    </div>
                                                    <div className="user-h ml-2">
                                                        <h6 className="mb-0">BANKNIFTY</h6>
                                                        <small className='list-date'>24-Dec-2024</small>
                                                    </div>
                                                </div>

                                                <div className="right-h text-right">
                                                    <h4 className="mb-0 text-danger"><i
                                                        className='fa fa-minus-circle'></i></h4>
                                                </div>
                                            </div>
                                        </div>*/}
                                    </div>
                                )}
                                {!isSearchVisible && (
                                    <div className='search-group-only'>
                                        <Accordion className='search-toggle-list' activeKey={activeKey}>
                                            {symbolSearchData.map((item, index) => (
                                                <Accordion.Item key={item.exchangeId} eventKey={item.exchangeId}>
                                                    <Accordion.Header onClick={() => handleToggle(item.exchangeId)}>
                                                        <h4 className='folder-tl b-clr d-flex v-center mb-0'>
                                                            <i className='fa fa-folder-open mr-2'></i>
                                                            <span>{item.exchangeName} ({item.symbolData.length})</span>
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='fixed-h-scroll'>
                                                            <div className='list-folder-inn'>
                                                                {item.symbolData.map((symItem, symIndex) => (
                                                                    <div className='search-list-items bdr-top'
                                                                         key={symIndex}>
                                                                        <div
                                                                            className="search-inn-header d-flex justify-content-between align-items-center">
                                                                            <div
                                                                                className="left-h d-flex align-items-center">
                                                                                <div className="icon-circle-bdr">
                                                                                    <span>{symItem.title.charAt(0)}</span>
                                                                                </div>
                                                                                <div className="user-h ml-2">
                                                                                    <h6 className="mb-0">{symItem.title}</h6>
                                                                                    <small
                                                                                        className='list-date'>{formatDate(symItem.expiryDate)}</small>
                                                                                </div>
                                                                            </div>

                                                                            <div className="right-h text-right">
                                                                                {handleCheckSymbolInWatchList(symItem._id)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                        </Accordion>
                                    </div>
                                )}

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {isMobile &&
                <Footer/>
            }
        </>
    );
};

export default StockSearch;