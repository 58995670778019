// utils/dateUtils.js
import moment from 'moment';
export const formatDate = (date, dateFormat = 'DD/MM/YYYY') => {
  if (!date) return '';
  try {
    const formattedDate = moment(date).format(dateFormat); // Example: '18/09/2024'
    return formattedDate;
  } catch (error) {
    console.error('Invalid Date:', date);
    return '';
  }
};
export const formatSymbolExpiryDate = (date, dateFormat = 'DD-MMM') => {
  if (!date) return '';
  try {
    const formattedDate = moment(date).format(dateFormat); // Example: '18/09/2024'
    return formattedDate;
  } catch (error) {
    console.error('Invalid Date:', date);
    return '';
  }
};
export const formatDateForTradeList = (date, dateFormat = 'D-MMM h:mm:ss a') => {
  if (!date) return '';
  try {
    const formattedDate = moment(date).format(dateFormat); // Example: '18/09/2024'
    return formattedDate;
  } catch (error) {
    console.error('Invalid Date:', date);
    return '';
  }
};
export const formatFullDate = (date, dateFormat = 'DD-MM-Y h:mm:ss a') => {
  if (!date) return '';
  try {
    const formattedDate = moment(date).format(dateFormat); // Example: '18/09/2024'
    return formattedDate;
  } catch (error) {
    console.error('Invalid Date:', date);
    return '';
  }
};
export const formatDateForChart = (date, dateFormat = "YYYY-MM-DDTHH:mm:ssZ") => {
  if (!date) return '';
  try {
    const formattedDate = moment(date).format(dateFormat); // Example: '18/09/2024'
    return formattedDate;
  } catch (error) {
    console.error('Invalid Date:', date);
    return '';
  }
};