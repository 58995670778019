import React, {useState, useEffect} from 'react';
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import {Container, Tab, Tabs, Row, Col, Card, Form} from 'react-bootstrap';
import {useLocation, Link, useNavigate} from 'react-router-dom';
import {useMediaQuery} from "react-responsive";
import "react-toastify/dist/ReactToastify.css";
import {API_ENDPOINT, CREDIT, DEBIT, INACTIVE, PAYMENT_REQUEST_MK, SUCCESS} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import axios from "axios";
import * as XLSX from "xlsx";
import {formatFullDate} from "../utils/dateUtils";

const LedgerAccountReport = () => {
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const [tableData, setTableData] = useState([]);
    const [formData, setFormData] = useState({});
    const location = useLocation();
    const passedData = location.state; // Get the passed data
    const navigate = useNavigate();
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const exportToExcel = async () => {
        let tableaData = [];
        await tableData.map((item, index) => {
            tableaData.push({
                'Transaction Id': item.transactionId,
                'Amount': item.amount,
                'Payment Request Type': item.paymentRequestType,
                'Bank Name': item.bankName,
                'Beneficiary Name': item.beneficiaryName,
                'Account Number': item.accountNumber,
                'IFSC': item.ifsc,
                'UPI Id': item.upiId,
                'Status': 'Success',
                'Date/Time': formatFullDate(item.createdAt),
                'Remark': item.comment,
            })
        });
        // Convert JSON data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(tableaData);

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Export the workbook as an Excel file
        XLSX.writeFile(workbook, "table_data.xlsx");
    };
    const handleRequestData = async () => {
        let data = encryptData({
            "userId": authenticated.userId,
            "paymentRequestType": formData.paymentRequestType,
            "startDate": formData.startDate,
            "endDate": formData.endDate,
            "status": [1]
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + PAYMENT_REQUEST_MK, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        setTableData(resData)
                    } else {
                        // toastError(response.data.message)
                        console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };

    useEffect(() => {
        handleRequestData()
    }, []);
    return (
        <>
            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">
                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn">
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>
                    <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                        <h5 className="head-numbers mb-0">Ledger Account Report</h5>
                    </div>
                    <div className="page-head-flt d-flex v-center hide-mobi">
                        <button className='btn white-bdr small' onClick={exportToExcel}><i
                            className="las la-download"></i> Export
                        </button>
                    </div>
                </div>
            </div>
            {isDesktop &&
                <LeftSidebar/>
            }
            <div className="main-container trans">
                <div className="center-main-content">
                    <div className="center-container trans">
                        <div className="home-container">
                            <div className="ledger-page">
                                
                                <div className="page-head-top text-right hide">
                                    <button className='btn primary small' onClick={exportToExcel}><i
                                        className="las la-download"></i> Export
                                    </button>
                                </div>

                                <div className='box-filter ptb-10 pt-0'>
                                    <Form className='table-flt-form d-flex'>
                                        <div className='flt-input-field'>
                                            <label className='label'>Type</label>
                                            <div className='selectbox'>
                                                <select name="paymentRequestType" id="paymentRequestType"
                                                        value={formData.paymentRequestType}
                                                        onChange={handleInputChange}>
                                                    <option value="">--Select--</option>
                                                    <option value={CREDIT}>{CREDIT}</option>
                                                    <option value={DEBIT}>{DEBIT}</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/*<div className='flt-input-field'>
                                            <label className='label'>Status</label>
                                            <div className='selectbox'>
                                                <select>
                                                    <option value="">--Select--</option>
                                                    <option value="option1">Status 1</option>
                                                    <option value="option2">Status 2</option>
                                                    <option value="option3">Status 3</option>
                                                </select>
                                            </div>
                                        </div>*/}
                                        <div className='flt-input-field'>
                                            <label className='label'>From Date</label>
                                            <div className='datebox'>
                                                <input type="date" name="startDate" onChange={handleInputChange}/>
                                            </div>
                                        </div>
                                        <div className='flt-input-field'>
                                            <label className='label'>To Date</label>
                                            <div className='datebox'>
                                                <input type="date" name="endDate" onChange={handleInputChange}/>
                                            </div>
                                        </div>
                                        <div className="flt-input-field form-btnbox">
                                            <label className='label hide-mobi-label_1'>&nbsp;</label>
                                            <div className='formbtn'>
                                                <button className="btn primary" onClick={handleRequestData}
                                                        type="button">Submit
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                <div className='bdr-card other-block p-0'>
                                    <div className='table-block'>
                                        <table className="report-table table table-striped mb-0">
                                            <thead>
                                            <tr>
                                                <th>Transaction Id</th>
                                                <th>Amount</th>
                                                <th>Payment Request Type</th>
                                                <th>Bank Name</th>
                                                <th>Beneficiary Name</th>
                                                <th>Account Number</th>
                                                <th>IFSC</th>
                                                <th>UPI Id</th>
                                                <th>Status</th>
                                                <th>Date/Time</th>
                                                <th>Remark</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {tableData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.transactionId}</td>
                                                    <td>{item.amount}</td>
                                                    <td>{item.paymentRequestType === DEBIT ?
                                                        <strong className='text-danger'>Debit</strong> :
                                                        <strong className='b-clr'>Credit</strong>}</td>
                                                    <td>{item.bankName}</td>
                                                    <td>{item.beneficiaryName}</td>
                                                    <td>{item.accountNumber}</td>
                                                    <td>{item.ifsc}</td>
                                                    <td>{item.upiId}</td>
                                                    <td><strong className='text-success d-flex v-center'><i
                                                        className="fa fa-check-circle mr-2"></i> Success</strong>
                                                    </td>
                                                    <td>{formatFullDate(item.createdAt)}</td>
                                                    <td>{item.comment}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isMobile &&
                <Footer/>
            }
        </>
    );
};
export default LedgerAccountReport;