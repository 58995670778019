import React, {useState, useEffect} from 'react'
import {Container, Row, Col, Form, Button} from 'react-bootstrap'
import {Link, useParams} from 'react-router-dom';
import axios from "axios";
import {useLocation, useNavigate} from 'react-router-dom';
import {toast, Bounce} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {v4 as uuidv4} from 'uuid';
import {
    API_ENDPOINT, SUCCESS, CHECKU, CREATE_NEW, CLIENT
} from "../constant/";
import {encryptData, decryptData, decryptUrlData} from "../utils/crypto";
import {toastSuccess, toastError} from "../utils/toastMsg";

function Register() {
    const {userName, userId} = useParams();
    const navigate = useNavigate();
    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    const initialFormData = {
        name: "",
        phone: "",
        userName: "",
        role: CLIENT,
        password: "",
        retype_password: "",
        allowedDevices: 1,
    };
    const [formData, setFormData] = useState(initialFormData);
    const [userData, setUserData] = useState({});

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const clickUserData = async () => {
        // let userNamez = decryptUrlData(atob(userName))
        // let userIdz = decryptUrlData(atob(userId))
        let userNamez = atob(userName)
        let userIdz = atob(userId)
        if (!userNamez || !userIdz) {
            navigate('/404');
            return;
        }
        let domain = window.location.hostname;
        const {deviceType, browser, userAgent, newDeviceId, ip} = await getDeviceInfo();
        let data = encryptData({
            "userName": userNamez,
            "domain": domain,
        });
        data = JSON.stringify({data: data});
        axios
            .post(API_ENDPOINT + CHECKU, data, {
                headers: {
                    'Content-Type': 'application/json',
                    "deviceType": deviceType
                },
            })
            .then((response) => {
                if (response.data.statusCode == SUCCESS) {
                    const rdata = decryptData(response.data.data)
                    setUserData(rdata)
                } else {
                    toastError(response.data.message)
                    navigate('/404');
                }
            })
            .catch((error) => {
                toastError(error.response.data.message)
                navigate('/404');
                // console.error("Error fetching data:", error);
            });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Perform validation here, for example:
        if (
            !formData.name ||
            !formData.role ||
            !formData.userName ||
            !formData.password ||
            !formData.retype_password
        ) {
            toastError("Please fill in all fields")
            return;
        }
        if (/\s/.test(formData.userName)) {
            toastError("Username cannot contain spaces")
            return;
        }
        if (formData.password.length < 8) {
            toastError("Password must be greater than 8 characters.")
            return;
        }
        if (formData.password !== formData.retype_password) {
            toastError("Password and Retype Password doesn't match")
            return;
        }
        try {
            const {deviceType, browser, userAgent, newDeviceId, ip} = await getDeviceInfo();
            let formdat = {
                "parentId": userData._id,
                "role": formData.role,
                "userName": formData.userName,
                "name": formData.name,
                "phone": formData.phone,
                "password": formData.password,
                "allowedDevices": formData.allowedDevices,
                "loginBy": "Web",
                "browser": browser,
                "userAgent": userAgent,
                "deviceId": newDeviceId,
                "deviceType": deviceType,
                "ipAddress": ip,
            }
            let formDataParam = encryptData(formdat);
            formDataParam = JSON.stringify({data: formDataParam});
            axios
                .post(API_ENDPOINT + CREATE_NEW, formDataParam, {
                    headers: {
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const rdata = decryptData(response.data.data)
                        handleReset();
                        toastSuccess(response.data.message);
                        navigate('/');
                    } else {
                        toastError(response.data.message)
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };
    const handleReset = () => {
        setFormData(initialFormData);
    };
    const getDeviceInfo = async () => {
        const userAgent = navigator.userAgent;
        let newDeviceType = "desktop";
        let browser = "Unknown";

        // Check if the device is mobile
        if (/Mobi|Android/i.test(userAgent)) {
            newDeviceType = "mobile";
        }

        // Browser detection (simplified)
        if (userAgent.includes("Chrome")) {
            browser = "Chrome";
        } else if (userAgent.includes("Firefox")) {
            browser = "Firefox";
        } else if (userAgent.includes("Safari")) {
            browser = "Safari";
        } else if (userAgent.includes("Edge")) {
            browser = "Edge";
        }
        let newDeviceId = localStorage.getItem('deviceId');
        if (!newDeviceId) {
            newDeviceId = uuidv4();
            localStorage.setItem('deviceId', newDeviceId);
        }
        let deviceType = localStorage.getItem('deviceType');
        if (!deviceType) {
            deviceType = newDeviceType;
            localStorage.setItem('deviceType', deviceType);
        }
        let ip = '';
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            ip = response.data.ip;
            return {deviceType, browser, userAgent, newDeviceId, ip};
        } catch (error) {
            return {deviceType, browser, userAgent, newDeviceId, ip};
        }
    };
    useEffect(() => {
        if (!userName || !userId) {
            navigate('/404');
        }
        clickUserData()
    }, []);
    return (
        <div className="login-wrapper">
            <div className='box-screen'>
                <div className="login-block d-flex v-center j-center">
                    <div className='form-wrapper ml-auto mr-auto'>

                        <h2 className='tl'>Create a new Account</h2>

                        <Form className='form-inner d-flex'>
                            <Form.Group className='input-field full-width'>
                                <label className='label'>Username<em className='required'>*</em></label>
                                <Form.Control type='text' id="userName" name="userName" value={formData.userName}
                                              placeholder="Enter Username" onChange={handleChange}/>
                            </Form.Group>

                            <Form.Group className='input-field full-width'>
                                <label className='label'>Name<em className='required'>*</em></label>
                                <Form.Control type='text' value={formData.name} placeholder="Enter Name"
                                              id="name" name="name" onChange={handleChange}/>
                            </Form.Group>

                            <Form.Group className='input-field full-width'>
                                <label className='label'>Mobile Number</label>
                                <Form.Control type='tel' id="phone" name="phone" value={formData.phone}
                                              placeholder="Enter Mobile Number" onChange={handleChange} maxLength="10"/>
                            </Form.Group>

                            <Form.Group className='input-field full-width'>
                                <label className='label'>Password<em className='required'>*</em></label>
                                <Form.Control type='password' id="password" name="password" value={formData.password}
                                              placeholder="Enter Password" onChange={handleChange}/>
                            </Form.Group>

                            <Form.Group className='input-field full-width'>
                                <label className='label'>Retype Password<em className='required'>*</em></label>
                                <Form.Control type='password' id="retype_password" name="retype_password"
                                              value={formData.retype_password}
                                              placeholder="Enter Retype Password" onChange={handleChange}/>
                            </Form.Group>

                            {/*<Form.Group className='input-field full-width'>
                                <label className='label'>Ref Code (Optional)</label>
                                <Form.Control type='text' placeholder='eg. ABC123'/>
                            </Form.Group>*/}

                            {/*<Form.Group className='input-field full-width'>
                                <div className='term-agree-block d-flex v-center'>
                                    <label className='checkbox'><Form.Control type='checkbox' required value="1"/><span
                                        className='checkmark'></span> I agree with all </label>
                                    <span className='forgot-link ml-2'><u>terms & conditions</u></span>
                                </div>
                            </Form.Group>*/}

                            <Form.Group className='input-field full-width form-btnbox mb-0'>
                                <Button className='btn primary w-100' variant="primary" type="submit"
                                        onClick={handleSubmit}>Register</Button>
                            </Form.Group>

                        </Form>

                        <div className='or-devider'><span>OR</span></div>

                        <div className='link-box'>
                            <p>You have an account? <Link to='/login'>Login to your account</Link></p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register