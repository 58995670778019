import React, {useState, useEffect} from 'react';
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import {Container, Tab, Tabs, Row, Col, Card, Form} from 'react-bootstrap';
import {useLocation, Link, useNavigate} from 'react-router-dom';
import {useMediaQuery} from "react-responsive";
import "react-toastify/dist/ReactToastify.css";
import {API_ENDPOINT, DEBIT, INACTIVE, PAYMENT_REQUEST_MK, SUCCESS} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import {formatFullDate} from "../utils/dateUtils";
import axios from "axios";

const LedgerAccountReport = () => {
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});

    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const [tableData, setTableData] = useState([]);
    const location = useLocation();
    const passedData = location.state; // Get the passed data
    const navigate = useNavigate();
    const handleRequestData = async () => {
        let data = encryptData({
            "userId": authenticated.userId,
            "paymentRequestType": passedData.pageType,
            "status": [2, 3]
        });
        data = JSON.stringify({data: data});
        try {
            axios
                .post(API_ENDPOINT + PAYMENT_REQUEST_MK, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const resData = decryptData(response.data.data)
                        setTableData(resData)
                    } else {
                        // toastError(response.data.message)
                        console.error("Login error:", response.data.message); // Handle error
                    }
                })
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
        }
    };
    const clickToBackReturn = () => {
        if (passedData.pageType === DEBIT) {
            navigate('/withdraw', {replace: true}); // Navigate to the new page
        } else {
            navigate('/deposit-request', {replace: true}); // Navigate to the new page
        }
    };
    useEffect(() => {
        if (passedData?.pageType) {
            handleRequestData()
        } else {
            navigate('/profile'); // Navigate to the new page
        }
    }, []);
    return (
        <>
            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">
                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn" onClick={clickToBackReturn}>
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>
                    <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                        <h5 className="head-numbers mb-0">View Request</h5>
                    </div>
                </div>
            </div>
            {isDesktop &&
                <LeftSidebar/>
            }
            <div className="main-container trans">
                <div className="center-main-content">
                    <div className="center-container trans">
                        <div className="home-container">
                            <div className="ledger-page">
                                <div className='box-filter ptb-10 pt-0'>
                                </div>
                                <div className='bdr-card other-block p-0'>
                                    <div className='table-block'>
                                        <table className="report-table table table-striped mb-0">
                                            <thead>
                                            <tr>
                                                <th>Transaction Id</th>
                                                <th>Amount</th>
                                                <th>Payment Request Type</th>
                                                <th>Bank Name</th>
                                                <th>Beneficiary Name</th>
                                                <th>Account Number</th>
                                                <th>IFSC</th>
                                                <th>UPI Id</th>
                                                <th>Status</th>
                                                <th>Date/Time</th>
                                                <th>Remark</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {tableData.map((item, index) => (
                                                <tr>
                                                    <td>{item.transactionId}</td>
                                                    <td>{item.amount}</td>
                                                    <td>{item.paymentRequestType === DEBIT ?
                                                        <strong className='text-danger'>Withdraw</strong> :
                                                        <strong className='b-clr'>Deposit</strong>}</td>
                                                    <td>{item.bankName}</td>
                                                    <td>{item.beneficiaryName}</td>
                                                    <td>{item.accountNumber}</td>
                                                    <td>{item.ifsc}</td>
                                                    <td>{item.upiId}</td>
                                                    <td>{item.status === INACTIVE ?
                                                        <strong className='text-info d-flex v-center'><i
                                                            className="fa fa-check-circle mr-2"></i> Pending</strong>
                                                        : <strong className='text-danger d-flex v-center'><i
                                                            className="fa fa-check-circle mr-2"></i> Dis-Approved</strong>}
                                                    </td>
                                                    <td>{formatFullDate(item.createdAt)}</td>
                                                    <td>{item.comment}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isMobile &&
                <Footer/>
            }
        </>
    );
};
export default LedgerAccountReport;