import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Footer from "../components/Footer";
import LeftSidebar from '../components/LeftSidebar';
import {Row, Col, Card, Form, Button} from 'react-bootstrap';
import {useMediaQuery} from "react-responsive";
import {toast, Bounce} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
    API_ENDPOINT, CHANGE_PASSWORD, SUCCESS
} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import {toastSuccess, toastError} from "../utils/toastMsg";

const ChangePass = () => {
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const navigate = useNavigate();
    const isMobile = useMediaQuery({query: "(max-width: 991px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 992px)"});
    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");

    const [selected, setSelected] = useState('yes');
    const handleRadioChange = (e) => {
        setSelected(e.target.value);
    };
    const clickToBackReturn = () => {
        navigate('/profile', {replace: true}); // Navigate to the new page
    };
    const initialFormData = {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    };
    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleChangePassword = async () => {
        try {
            if (
                !formData.currentPassword ||
                !formData.newPassword ||
                !formData.confirmNewPassword
            ) {
                toastError("Please fill in all fields")
                return;
            }
            if (formData.newPassword.length < 8) {
                toastError("New Password must be greater than 8 characters.")
                return;
            }
            if (formData.newPassword !== formData.confirmNewPassword) {
                toastError("New Password and Confirm New Password doesn't match")
                return;
            }
            let data = encryptData({
                "currentPassword": formData.currentPassword,
                "newPassword": formData.newPassword
            });
            data = JSON.stringify({data: data});
            axios
                .post(API_ENDPOINT + CHANGE_PASSWORD, data, {
                    headers: {
                        'Authorization': jwt_token,
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        console.log(response)
                        localStorage.removeItem('authenticated');
                        localStorage.removeItem('token');
                        localStorage.setItem("isNotificationRead", 0);
                        localStorage.setItem("isTermsAndConditions", 0);
                        localStorage.setItem('authenticated', false);
                        navigate('/login'); // Navigate to the new page
                        toastSuccess(response.data.meta.message)
                    } else {
                        toastError(response.data.message)
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                });
        } catch (error) {
        }
    };
    return (
        <>

            <div className="page-top-block fixed-top head-left-space">
                <div className="page-head bg1 d-flex">

                    <div className="page-head-left d-flex v-center">
                        <button className="back-btn" onClick={clickToBackReturn}>
                            <i className="coin_icon fa fa-arrow-left"></i>
                        </button>
                    </div>

                    <div className="page-head-middle d-flex v-center ml-auto mr-auto">
                        <h5 className="head-numbers mb-0">Ledger Account</h5>
                    </div>

                </div>
            </div>

            {isDesktop &&
                <LeftSidebar/>
            }

            <div className="main-container trans">
                <div className="center-main-content">
                    <div className="center-container trans">
                        <div className="home-container">

                            <div className="bdr-card change-pass-page">

                                <Form className='upi-fields change-password-form ptb-10 pb-0'
                                      onSubmit={handleChangePassword}>
                                    <div className="qty-text-block">
                                        <label className="stk-qty-field w-100">
                                            <span className="form-label">Current PassWord</span>
                                            <input className="form-control w-100"
                                                   id="currentPassword"
                                                   name="currentPassword"
                                                   type="password"
                                                   value={formData.currentPassword}
                                                   placeholder="Enter Password"
                                                   onChange={handleChange}/>
                                        </label>
                                    </div>

                                    <div className="qty-text-block">
                                        <label className="stk-qty-field w-100">
                                            <span className="form-label">New PassWord</span>
                                            <input className="form-control w-100"
                                                   id="newPassword"
                                                   name="newPassword"
                                                   type="password"
                                                   value={formData.newPassword}
                                                   placeholder="Enter Password"
                                                   onChange={handleChange}/>
                                        </label>
                                    </div>

                                    <div className="qty-text-block">
                                        <label className="stk-qty-field w-100">
                                            <span className="form-label">Confirm New Password</span>
                                            <input className="form-control w-100"
                                                   id="confirmNewPassword"
                                                   name="confirmNewPassword"
                                                   type="password"
                                                   value={formData.confirmNewPassword}
                                                   placeholder="Enter Password"
                                                   onChange={handleChange}/>
                                        </label>
                                    </div>

                                    <div className="qty-text-block form-btnbox pt-0 mb-0">
                                        <Button className='btn primary w-100' variant="primary"
                                                onClick={handleChangePassword}>Change
                                            Password</Button>
                                    </div>
                                </Form>


                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {isMobile &&
                <Footer/>
            }
        </>
    );
};

export default ChangePass;