
export const formatCurrency = (amount) => {
  if(amount <= 0) {
    return 0;
  }
  if (amount >= 1000000000000) {
    return `${(amount / 1000000000000).toFixed(2)} TN`; // Trillions
  } else if (amount >= 10000000000) {
    return `${(amount / 10000000000).toFixed(2)} BN`; // Billions
  } else if (amount >= 10000000) {
    return `${(amount / 10000000).toFixed(2)} Cr`; // Crores
  } else if (amount >= 100000) {
    return `${(amount / 100000).toFixed(2)} Lac`; // Lakhs
  } else {
    return `${amount.toLocaleString('en-IN')} ₹`; // Rupees
  }
};