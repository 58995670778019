import React, {useState, useEffect} from 'react'
import {Container, Row, Col, Form, Button} from 'react-bootstrap'
import {Link} from 'react-router-dom';

function ForgotPassword() {
    return (
        <div className="login-wrapper d-flex v-center j-center">
            <div className='box-screen ml-auto mr-auto'>
                <div className="login-block">
                    <div className='form-wrapper ml-auto mr-auto'>

                        <h2 className='tl'>Find Your Account</h2>

                        <Form className='form-inner d-flex'>
                            {/*<Form.Group className='input-field full-width'>
                      <label className='label'>Please enter your email address for your account</label>
                      <Form.Control type='email' placeholder='Enter your email' />
                    </Form.Group>*/}
                            <Form.Group className='input-field full-width'>
                                <label className='label'>Please enter your phone number</label>
                                <Form.Control type='tel' placeholder='Enter phone number'/>
                            </Form.Group>
                            <Form.Group className='input-field full-width'>
                                <label className='label'>Please enter your one time OTP</label>
                                <Form.Control type='tel' placeholder='Enter OTP'/>
                            </Form.Group>

                            <div className='link-box'>
                                <p><u>Resend</u></p>
                            </div>
                            <Form.Group className='input-field full-width form-btnbox mb-0'>
                                <Button className='btn primary w-100' variant="primary" type="submit">Submit</Button>
                            </Form.Group>
                        </Form>

                        {/*<div className='link-box ptb-20'>
                  <p><u>Please check your email after click on submit button.</u></p>
                </div>*/}

                        {/*<div className='or-devider'><span>OR</span></div>

                <div className='link-box'>
                  <p>Doesn't have an account yet? <Link to='/register'>Create account</Link></p>
                </div>*/}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword