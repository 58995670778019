import {v4 as uuidv4} from 'uuid';
import axios from "axios";
export const getDeviceInfo = async () => {
    const userAgent = navigator.userAgent;
    let newDeviceType = "desktop";
    let browser = "Unknown";

    // Check if the device is mobile
    if (/Mobi|Android/i.test(userAgent)) {
        newDeviceType = "mobile";
    }

    // Browser detection (simplified)
    if (userAgent.includes("Chrome")) {
        browser = "Chrome";
    } else if (userAgent.includes("Firefox")) {
        browser = "Firefox";
    } else if (userAgent.includes("Safari")) {
        browser = "Safari";
    } else if (userAgent.includes("Edge")) {
        browser = "Edge";
    }
    let newDeviceId = localStorage.getItem('deviceId');
    if (!newDeviceId) {
        newDeviceId = uuidv4();
        localStorage.setItem('deviceId', newDeviceId);
    }
    let deviceType = localStorage.getItem('deviceType');
    if (!deviceType) {
        deviceType = newDeviceType;
        localStorage.setItem('deviceType', deviceType);
    }
    let ip = '';
    try {
        const response = await axios.get('https://api.ipify.org?format=json');
        ip = response.data.ip;
        return {deviceType, browser, userAgent, newDeviceId, ip};
    } catch (error) {
        return {deviceType, browser, userAgent, newDeviceId, ip};
    }
};