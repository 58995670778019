// arraySlice.js
import {createSlice} from "@reduxjs/toolkit";

const arraySlice = createSlice({
    name: "array",
    initialState: [],
    reducers: {
        addItem: (state, action) => {
            if (!state.includes(action.payload)) {
                state.push(action.payload);
            }
        },
        removeItem: (state, action) => {
            return state.filter((_, index) => index !== action.payload);
        },
    },
});

export const {addItem, removeItem} = arraySlice.actions;
export default arraySlice.reducer;
